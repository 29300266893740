export const LOGIN = "/login";
export const DASHBOARD = "/dashboard";
export const CLIENT_REGISTER = "/client_register";
export const MANAGER_DASHBOARD = "/manager";
export const AGENT_CAMPAIGN_DASHBOARD = "/agent/campaign";
export const DEBTORS = "/debtors";
export const DEBTOR = "/debtor";
export const AGENT_MANAGER = "/agent";
export const CALENDAR = "/calendar";
export const EMAILS = "/emails";
export const VIEW_EMAILS = "/emails/view";
export const PHONE = "/phone";
export const ADMINISTRATION = "/administration";
export const RESET_PASSWORD = "/reset-password";
export const DEBTOR_DASHBOARD = "/debtor-dashboard";
export const DEBTOR_EDIT = "/edit";
