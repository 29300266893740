import { Grid } from '@mui/material';
import React, { useState } from 'react'
import styled from 'styled-components'
import { ArrowDown } from '../common/Layouts';
import { colors } from '../constants/styles';

const WrapperSelector=styled.div`
  height: 25px;
  width: 100%; 
  position: relative;
  display: flex;
  justify-content: ${props => props.position};
`;

const Selector=styled.div`
  position: absolute;
  width: ${props => props.width ? props.width : '110px'};
  height: 25px;
  box-sizing: border-box;
  //padding: 5px 10px;
  padding-left: 10px;
  padding-bottom: 5px; 
  padding-top: 5px;
  left: 10px;
  top: 0;
  border: 1px solid #002B64;
  border-radius: 13px;
  overflow: hidden;
  z-index: 100;
  background-color: #ffffff;
  white-space: nowrap;
  transition: all 0.5s;
  cursor: pointer;
  @media (min-width:1920px) {
    padding-left: 10px;
    left: 10px;
    padding-bottom: 0;
    padding-top: 2px;
  }
  &.active {
    width: 140px;
    height: 100px;
    left: 10px;
    transition: all 0.5s;
    @media (min-width:1920px) {
      width: 180px;
      height: 120px;
      left: 10px;
    }
  }
  &.textWrapper {
    width: 70px;
    @media (min-width:1920px) {
    margin-right: 100px;
    }
  }
`; 

const Text=styled.div`
  color: ${props=> props.color};
  font-family: 'Avenir Next Medium';
  text-align: left;
  white-space: nowrap;
  overflow: hidden;
  margin-right: 15px;
  text-overflow: ellipsis;
  @media (min-width:1700px) {
    margin-right: 25px;
    }
  @media (min-width:1920px) {
    margin-right: 45px;
    }
  &.isSelect {
    color: ${colors.blue};
  }
`;

const List=styled.div`
  text-align: left;
  padding-top: 5px;
  .item {
    :hover{
      background-color: ${colors.blue};
      color: ${colors.white};
    }
  }
`;

function SelectComponent({
  list, 
  handlerSelect, 
  title="Select", 
  width=null,
  position="center",
  color='#AAAAAA',
  selected,
}) {
 const [open, setOpen]=useState(false);
 const [titleField, setTitle]=useState(title); 
 const selectItem = (item) => {
   handlerSelect(item);
   setTitle(item.name ? item.name : item); 
   setOpen(false); 
 }
  return (
    <WrapperSelector position={position}>
      <Selector 
        className={open ? 'active' : ''}
        width={width}
        onMouseLeave={() => setOpen(false)}
        onMouseEnter={() => setOpen(true)}>
        <Grid container alignItems="center" className="textWrapper">
            <Text color={color} className={titleField !== title ? 'isSelect' : '' }>{titleField}</Text> 
            {titleField === title && <ArrowDown base={6} side={4}/>}
        </Grid>
        <List>
          {list.map(item => <div
            className={'item'} 
            onClick={() => selectItem(item)} key={item}>
            {selected?.name ? selected.name : selected ? selected : item.name ? item.name : item}
          </div>)}
        </List>
      </Selector>
    
    </WrapperSelector>
  )
}

export default SelectComponent
