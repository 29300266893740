import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3';
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider, ThemeProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';

import "date-fns";
import { BrowserRouter, Redirect, Route, Switch } from "react-router-dom";
import "./App.css";
import PrivateRoute from "./common/privateRoute";
import { APIErrorNotification } from "./components/APIErrorNotification";
import GlobalLoader from "./components/loader/GlobalLoader";
import ModalContainer from "./components/modal/ModalContainer";
import BasicToast from "./components/toast/BasicToast";
import { DASHBOARD, LOGIN, RESET_PASSWORD } from "./constants/pathNames";
import ResetPassword from "./pages/Auth/ResetPassword";
import Dashboard from "./pages/Dashboard/Dashboard";
import Login from "./pages/Login/Login";
import { APIErrorProvider } from "./providers/apiErrorProvider";
import { AuthProvider } from "./providers/authProvider";
import "./styles/styles.css";
import CollectIQBasic from "./theme/CollectIQBasic.theme";

function App() {
	return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={CollectIQBasic}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <CssBaseline />
                    <BrowserRouter>
                        {/* <AuthProvider> */}
                            <APIErrorProvider>
                                <div className='app'>
                                    <Switch>
                                        <Route exact path={LOGIN}>
                                            <Login />
                                        </Route>
                                        <PrivateRoute
                                            path={DASHBOARD}
                                            redirectTo={LOGIN}
                                        >
                                            <Dashboard />
                                        </PrivateRoute>
                                        <Route path={RESET_PASSWORD}>
                                            <ResetPassword />
                                        </Route>
                                        <Redirect to={LOGIN} />
                                    </Switch>
                                </div>

                                <APIErrorNotification />
                            </APIErrorProvider>
                        {/* </AuthProvider> */}
                        <GlobalLoader />
                        <BasicToast />
                        <ModalContainer />
                    </BrowserRouter>
                </LocalizationProvider>
            </ThemeProvider>
        </StyledEngineProvider>
    );
}

export default App;
