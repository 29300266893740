import { Box, CircularProgress } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import TextArea from "components/inputs/TextArea";
import TextInput from "components/inputs/TextInput";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import { LSSM } from "api/config/endpoints";
import LssmService from "api/services/lssmService";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const AdminSupport = ({ account_no, unsetModal }) => {
    const [subject, setSubject] = useState("");
    const [text, setText] = useState("");
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const handleSendAdmin = () => {
        setLoading(true);

        LssmService.adminSupport({ account_no, subject, text }).then((response) => {
            const { data, message, status } = response;

            switch (status) {
                case "success":
                    setToast(message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    if (data.error?.subject) {
                        setToast(data.error?.subject, "error");
                        setLoading(false);
                    } else if (data.error?.text) {
                        setToast(data.error?.text, "error");
                        setLoading(false);
                    } else {
                        setToast(message, "error");
                        setLoading(false);
                        unsetModal();
                    }
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Admin Support Request</Title>
            </Box>
            <Box mb={2} width="clamp(15em, 50%, 40em)">
                <TextInput value={subject} label="Subject" onChange={(e) => setSubject(e.target.value)} />
            </Box>
            <Box mb={2} width="clamp(15em, 50%, 40em)">
                <TextArea
                    placeholder="Please enter message here"
                    label="Message"
                    value={text}
                    onChange={(e) => setText(e.target.value)}
                />
            </Box>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <BluePillButton variant="contained" onClick={handleSendAdmin}>
                        Send Request
                    </BluePillButton>
                )}
            </Box>
        </Box>
    );
};

export default AdminSupport;
