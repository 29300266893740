import React from "react";
import makeStyles from '@mui/styles/makeStyles';
import Modal from "@mui/material/Modal";
import { colors } from "../constants/styles";
import { ButtonBack } from "../common/Layouts";
import { Grid } from "@mui/material";
import Fade from "@mui/material/Fade";
import close_icon from "../assets/icons/Group 414.png";
import { useHistory } from "react-router-dom";
import { LOGIN } from "../constants/pathNames";
import { useAuthDispatch } from "../providers/authProvider";
import { setModal } from "../redux/actions/navActions";
import { useModal } from "../providers/hooks/useModal";
import ActiveBreak from "./modal/ActiveBreak";
import { AuthService } from "../api/services";
import { useVoip } from "providers/hooks/useVoip";
import { useAuth } from "providers/hooks/useAuth";

function rand() {
    return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
    const top = 50 + rand();
    const left = 50 + rand();

    return {
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles((theme) => ({
    paper: {
        height: "auto",
        backgroundColor: "#ffffff",
        borderRadius: "102px",
        outline: "none",
        border: "none",
        boxSizing: "border-box",
        fontFamily: "Avenir Next",
        padding: "10px",
        "@media (min-width:1920px)": {
            width: 587,
        },
    },

    wrap_content: {
        position: "relative",
        paddingLeft: 50,
        paddingTop: 25,
        boxSizing: "border-box",
        "@media (min-width:1920px)": {
            paddingLeft: 70,
        },
    },
    text: {
        fontSize: "30px",
        fontWeight: "bold",
        textAlign: "center",
        padding: "15px 45px",
        width: 300,
        margin: "0px auto",
        fontFamily: "Avenir Next Bold",
        "@media (min-width:1920px)": {
            fontSize: "40px",
            padding: "25px 80px",
        },
    },
    text_assign: {
        fontSize: "30px",
        fontWeight: "bold",
        fontFamily: "Avenir Next Bold",

        "@media (min-width:1920px)": {
            fontSize: "40px",
        },
    },
    text_subtitle: {
        fontFamily: "Avenir Next Demi Bold",
        fontSize: "15px",
        textAlign: "left",
        padding: "15px 0",
        "@media (min-width:1920px)": {
            fontSize: "20px",
            padding: "21px 0",
        },
    },
    text_row: {
        fontFamily: "Avenir Next Medium",
        fontSize: "15px",
        textAlign: "left",
        padding: "10px 0",
        "@media (min-width:1920px)": {
            fontSize: "20px",
        },
    },
    wrap_btn: {
        marginBottom: 30,
        "@media (min-width:1920px)": {
            marginBottom: 60,
        },
    },
    wrap_btn_assign: {
        marginBottom: 14,
        "@media (min-width:1920px)": {
            marginBottom: 25,
        },
    },
    wrap_close: {
        position: "absolute",
        top: "25px",
        right: "25px",
        cursor: "pointer",
        zIndex: 10,
    },
}));

export default function ModalComponent({ open, handleClose, handleOpen, logoutOptions }) {
    const classes = useStyles();
    const { updateWorkerAvailability } = useVoip();
    const [modalStyle] = React.useState(getModalStyle);
    const history = useHistory();
    const { setModal, unsetModal } = useModal();
    const { user_type_id } = useAuth();

    const listButtons = [
        "Lunch Break",
        "Meeting",
        "Consultation",
        "Tea Break",
        "Comfort Break",
        "Smoke Break",
        "Idle User",
    ];

    // const { onLogout } = useAuthDispatch();

    const onLogout = async () => {
        const resp = await AuthService.logout();
        if (resp.status === "success") {
            updateWorkerAvailability("Offline");
            sessionStorage.clear();
            history.push("/login");
            unsetModal();
        }
    };

    const openActiveBreakModal = (type) => {
        setModal({
            Component: <ActiveBreak breakType={type} />,
            size: "sml",
            canClickAway: false,
        });
    };

    const body = (
        <div className={`${classes.paper} `} style={{ height: "600px", paddingBottom: 10 }}>
            <div onClick={unsetModal} className={classes.wrap_close}>
                <img width={20} src={close_icon} alt={""} />
            </div>
            <div className={classes.text}>{user_type_id !== 5 ? "Log Out / Take a Break" : "Log Out"}</div>

            {logoutOptions.map((option) => (
                <Grid container key={option.name} justifyContent="center" className={classes.wrap_btn_assign}>
                    <ButtonBack
                        onClick={() => openActiveBreakModal(option)}
                        fontSize={40}
                        small_size={20}
                        customStyle={{
                            backgroundColor: colors.white,
                            color: colors.blue,
                            border: "1px solid #002B64",
                            fontFamily: "Avenir Next",
                        }}
                        height={window.innerWidth < 1440 ? 40 : 61}
                        width={window.innerWidth < 1440 ? 400 : 461}
                    >
                        {option.name}
                    </ButtonBack>
                </Grid>
            ))}

            <Grid container justifyContent="center" className={classes.wrap_btn} style={{ paddingBottom: 10 }}>
                <ButtonBack
                    onClick={onLogout}
                    fontSize={40}
                    small_size={20}
                    customStyle={{
                        backgroundColor: colors.orange,
                        color: colors.white,
                        fontFamily: "Avenir Next",
                    }}
                    height={window.innerWidth < 1440 ? 40 : 61}
                    width={window.innerWidth < 1440 ? 400 : 461}
                >
                    Logout
                </ButtonBack>
            </Grid>
        </div>
    );

    return <div>{body}</div>;
}
