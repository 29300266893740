import React, { useEffect } from "react";
import { Section, TitleSection } from "../../common/Layouts";
import { useHistory, useLocation } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import { colors } from "../../constants/styles";
import { UserService } from "../../api/services";
import TableUI from "../../components/TableUI";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import { dateFormat, timeFormat } from "../../utils/dateFormat";
import edit_icon from "../../assets/icons/FontAwsome (edit).png";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { useAuth } from "../../providers/hooks/useAuth";
import ButtonComponent from "../../components/ButtonComponent";
import { useModal } from "../../providers/hooks/useModal";
import ConfirmAction from "../../components/modal/ConfirmAction";
import { Paper } from "@mui/material";
import { Edit, Delete } from "@mui/icons-material";

const mockData = {
  user_type: "Manager",
  agents_name: "Dumasani Xulu",
  manager: "Richard Andrews",
  contact_number: "000 000 0000",
  "": " ",
};

function AdministrationUser() {
  const { hasPermission } = useAuth();
  const { setModal, unsetModal } = useModal();
  let listDetail = new Array(8).fill(1).map((item) => mockData);

  const { remoteDataSource, setParams, tableRef } = useMaterialTable({
    endpoint: (params) =>
      new Promise((resolve, reject) => {
        UserService.getUserList(params).then((response) => {
          resolve({
            status: response.status,
            data: response.data.clients,
            count: response.data.count,
            pagination: response.pagination,
          });
        });
      }),
    initParams: {},
  });

  const history = useHistory();

  const { pathname } = useLocation();

  const addNewUser = () => {
    history.push(`${pathname}/new_user`);
  };

  const editUser = (id, row) => {
    history.push(`${pathname}/edit_user/${id}`);
  };

  const deleteUser = async (id) => {
    unsetModal();
    const resp = await UserService.deleteUser({ user_id: id });
    if (resp.status === "success") {
      setParams({});
    }
  };

  const openConfirmDeleteModal = (id, name) => {
    setModal({
      Component: (
        <ConfirmAction
          onConfirm={() => deleteUser(id)}
          message={`Are you sure you want to delete user ${name}`}
        />
      ),
      size: "sml",
    });
  };

  return (
    <>
      <TitleSection>User Manager</TitleSection>
      <div style={{ height: "100%" }}>
        <div
          className={"p-2"}
          style={{ backgroundColor: "#fff", borderRadius: 20 }}
        >
          <CustomMaterialTable
            title={null}
            tableRef={tableRef}
            columns={[
              { title: "Name", field: "name" },
              { title: "Email Address", field: "email" },
              { title: "Telephone Number", field: "telephone_number" },
              { title: "User Type", field: "user_type.name" },
            ]}
            data={remoteDataSource}
            actions={[
              {
                icon: () => <Edit style={{color: '#ec7346'}} />,
                onClick: (e, row) => editUser(row.id, row),
                render: hasPermission("update-user"),
              },
              {
                icon: () => <Delete style={{color: '#ec7346'}} />,
                onClick: (e, row) => openConfirmDeleteModal(row.id, row.name),
                render: hasPermission("delete-user"),
              },
              {
                icon: (props) => (
                  <ButtonComponent title={"+ Add User"}></ButtonComponent>
                ),
                position: "toolbar",
                onClick: addNewUser,
                render: hasPermission("create-user"),
              },
            ]}
            options={{
              actionsColumnIndex: 4,
              pageSize: 10,
              search: true,
            }}
            components={{
              Container: (props) => <Paper {...props} elevation={0} />,
            }}
          />
        </div>
        {/* <TableComponent
          isAdministration
          title_btn={"ADD"}
          isCustom
          isFilterOne
          isClientManager
          handlerSelectRow={()=>{}}
          handlerClick={addNewUser}
          color_btn={colors.blue}
          excludeTableCols={['id']}
          service={UserService.getUserList().data}
        />
        <TableUI
          isAdministration
          title_btn={"ADD"}
          isCustom
          isFilterOne
          isClientManager
          handlerSelectRow={()=>{}}
          handlerClick={addNewUser}
          color_btn={colors.blue}
          data={listDetail}
          excludeTableCols={['id']}
        /> */}
      </div>
    </>
  );
}

export default AdministrationUser;
