import React from "react";
import { Menu, MenuItem, Toolbar } from "@mui/material";
import { useModal } from "providers/hooks/useModal";
import ReportsDownload from "./modal/content/ReportsDownload";
import BluePillButton from "./buttons/BluePillButton";
import { ArrowDropDown } from "@mui/icons-material";
import BulkEmailTemplate from "./modal/BulkEmailTemplate";
import BulkSms from "./modal/BulkSms";
import BulkDebtorPortal from "./modal/BulkDebtorPortal";

function DebtorRegisterActionMenu({ client_id, accounts }) {
    const { setModal, unsetModal, setToast } = useModal();
    const [menuAnchor, setMenuAnchor] = React.useState();

    const handleModalEvent = ({ type, payload = undefined }) => {
        console.log("handleModalEvent", type);
        switch (type) {
            case "preparing_download":
                unsetModal();
                setToast("Preparing download...", "info");
                break;
            case "download_error":
                unsetModal();
                setToast("We ran into an error fetching this report.", "error");
                break;
            case "download":
                setToast("Downloading...", "info");
                break;
            default:
                unsetModal();
        }
    };
    const handleNewPtp = (e) => {
        setMenuAnchor(e.target);
    };
    const handleReportTypeSelect = (e) => {
        setMenuAnchor(false);
        switch (e.target.id) {
            case "assign-debtors":
                setModal({
                    Component: <ReportsDownload.CovidCampaign client_id={client_id} dispatch={handleModalEvent} />,
                    size: "sml",
                });
                break;
            case "bulk-email":
                setModal({
                    Component: <BulkEmailTemplate accounts={accounts} />,
                });
                break;
            case "bulk-sms":
                setModal({
                    Component: <BulkSms accounts={accounts} />,
                });
                break;
            case "bulk-debtor-portal":
                setModal({
                    Component: <BulkDebtorPortal accounts={accounts} client_id={client_id} />,
                    size: "sml",
                });
                break;
            case "other":
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Toolbar>
                <BluePillButton variant="contained" onClick={handleNewPtp}>
                    Select Option <ArrowDropDown />
                </BluePillButton>
                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    keepMounted
                    onClose={() => setMenuAnchor(undefined)}
                >
                    <MenuItem id="assign-debtors" onClick={handleReportTypeSelect}>
                        Assign Debtors
                    </MenuItem>
                    <MenuItem id="bulk-email" onClick={handleReportTypeSelect}>
                        Bulk Email
                    </MenuItem>
                    <MenuItem id="bulk-sms" onClick={handleReportTypeSelect}>
                        Bulk SMS
                    </MenuItem>
                    <MenuItem id="bulk-debtor-portal" onClick={handleReportTypeSelect}>
                        Bulk Debtor Portal
                    </MenuItem>
                </Menu>
            </Toolbar>
        </div>
    );
}

export default DebtorRegisterActionMenu;
