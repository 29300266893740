import { Box, CircularProgress, FormGroup, FormControlLabel, Switch, IconButton, Tooltip, TextField } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import TextArea from "components/inputs/TextArea";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@mui/icons-material";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    comment: Yup.string().required("Comments is a required field"),
    marital_docs: Yup.string().required("marital docs is a required field"),
    marital_type: Yup.string().required("marital type is a required field"),
});


const SendMaritalType = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [checked, setChecked] = useState(false);
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        comment: "",
        marital_docs: "N",
        marital_type: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };
    
    const handleSendMaritalType = () => {
        setLoading(true);

        LssmService.sendMaritalType({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const handleChecked = (e) => {
        setChecked(e.target.checked);
        !checked
          ? setData({ ...data, marital_docs: "Y"})
          : setData({ ...data, marital_docs: "N" });
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Debtors marital type as cause of non-payment">
                    <Title>
                        Marital Type
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendMaritalType}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                   
                            <Box mb={2}  display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Marital Status</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.instruction_type ? true : false}
                                                id="marital_type"
                                                name="marital_type"
                                                helperText={errors.marital_type}
                                                className={"select-input-e4"}
                                                value={data.marital_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Marital Status</option>
                                                <option value="1">ANC</option>
                                                <option value="2">COP</option>
                                                <option value="3">Divorced</option>
                                                <option value="4">Foreign marriage</option>
                                                <option value="5">Customary</option>
                                                <option value="6">Civil union</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>
                            <Box mb={2} paddingX={15}>
                                <FormGroup>
                                    <div className={styles.title}>Comment</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="comment"
                                        name="comment"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.comment}
                                        error={errors.comment ? true : false}
                                        helperText={errors.comment}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>
                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={checked} color="primary" onChange={handleChecked} />}
                                        label="Supporting documents available?"
                                    />
                                </FormGroup>
                            </Box>
 
                            <Box mb={2} display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton  variant="contained" type="submit">
                                        Send Marital Type
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendMaritalType;
