import "date-fns";
import React from "react";
// import Grid from "@mui/material/Grid";
// import DateFnsUtils from "@date-io/date-fns";

import { DatePicker as LocalDatePicker} from '@mui/x-date-pickers';
// import { LocalizationProvider } from '@mui/x-date-pickers';
import makeStyles from '@mui/styles/makeStyles';
import calendarTop from "../../../assets/icons/Group 407.png";
import styles from "./styles.module.css";

const useStyles = makeStyles((theme, props) => ({
  eventStyle: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', 
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px', 
    },
    '& .MuiTextField-root': {
      width: '100%',
      border: 'none',
    },
  },
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', 
    },
    '& .MuiOutlinedInput-input': {
      padding: '5px', 
    },
  },
  titleCalendar: {
    fontSize: '1.2vw',
    marginRight: '-10px',
    paddingTop: '5px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Avenir Next',
    '@media (min-width:1920px)': {
      fontSize: '20px',
    },
  },
  icon_calendar: {
    width: (props) => `${props.size}px`, // Use props within the styles
  },
}));

export default function DatePicker({
  size = 22,
  isFee = false,
  updateTitle,
  label,
  value = null,
  style = {style: false}
}) {
  const classes = useStyles({ size });

  const [selectedDate, setSelectedDate] = React.useState(value ? new Date(value) : new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (updateTitle) {
      updateTitle(date);
    }
  };

  return (
    <div className={`${style.style ? classes.eventStyle : classes.root}`}>
      <div className={styles.datePickerTitle}>{label}</div>
      <div className={styles.datePicker}>
        <LocalDatePicker
          disableToolbar
          format="MM/dd/yyyy"
          margin="none"
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          sx={{  '& .MuiTextField-root': {
            width: '100% !important',
            border: 'none',
          },}}
          KeyboardButtonProps={{
            'aria-label': 'change date',  
            width: 300,
          }}
          InnerProps={{
            sx: style
          }}
          keyboardIcon={<img className={classes.icon_calendar} src={calendarTop} alt=""/>}
        />
      </div>
    </div>
  );
}
