import { Box, CircularProgress, Typography } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import { UserService } from "api/services";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const CreateDebtorPortalAccount = ({ account_no, debtor_name, debtor_id, debtor_email, accountDetailsList }) => {
    const { setToast, unsetModal } = useModal();
    const [loading, setLoading] = useState(false);

    const handleCreateDebtorPortalAccount = () => {
        setLoading(true);

        // if (!accountDetailsList?.phone_mobile) {
        //     setToast("Can't create Debtor Portal without phone number", "error");
        //     setLoading(false);
        //     unsetModal();
        //     return;
        // }

        if (!accountDetailsList?.name) {
            setToast("Can't create Debtor Portal without name", "error");
            setLoading(false);
            unsetModal();
            return;
        }

        const debtorInfo = new FormData();
        const obj = {
            email: debtor_email,
            debtor_id,
            name: debtor_name,
            user_type: 5,
            telephone_number: accountDetailsList.phone_mobile,
        };

        Object.keys(obj).forEach((key) => {
            debtorInfo.append(key, obj[key]);
        });

        UserService.createUser(debtorInfo)
            .then((response) => {
                const resp = { response: response };

                if (response.status === "success") {
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                } else {
                    setToast(response.message, "error");
                    setLoading(false);
                    unsetModal();
                }

                if (resp?.response.response.data) {
                    switch (resp.response.response.data.status) {
                        case "success":
                            setToast(resp.response.response.data.message, "success");
                            setLoading(false);
                            unsetModal();
                            break;
                        case "error":
                            if (resp.response.response.data.data.error.email) {
                                setToast(resp.response.response.data.data.error.email, "error");
                                setLoading(false);
                                unsetModal();
                            } else if (resp.response.response.data.data.error.telephone_number) {
                                setToast(resp.response.response.data.data.error.telephone_number, "error");
                                setLoading(false);
                                unsetModal();
                            } else {
                                setToast(resp.response.response.data.message, "error");
                                setLoading(false);
                                unsetModal();
                            }

                        default:
                            break;
                    }
                }
            })
            .catch((error) => console.error("Error creating account:", error));
    };

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Create Debtor Portal Account</Title>
            </Box>

            <Box mb={2}>
                <Typography variant="body">
                    Click button to create Debtor Portal account for:{" "}
                    <span style={{ fontWeight: "bold", color: "midnightblue" }}> {account_no} </span>
                </Typography>
            </Box>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <BluePillButton variant="contained" onClick={handleCreateDebtorPortalAccount}>
                        Create Account
                    </BluePillButton>
                )}
            </Box>
        </Box>
    );
};

export default CreateDebtorPortalAccount;
