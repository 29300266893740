import { Box, CircularProgress, FormControl } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import { ArrowDown } from "common/Layouts";
import style from "components/inputs/styles.module.css";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const ReturnMatterToSBSA = ({ account_no, unsetModal, matter_returned }) => {
    const [reasonCode, setReasonCode] = useState();
    const [options, setOptions] = useState([]);
    const [btnDisable, setBtnDisable] = useState(true);
    const [displayOption, setDispalyOptions] = useState(false);
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const handleSendReturnMatter = () => {
        if (matter_returned > 0) {
            setToast("This matter has been sent already", "warning");
        }

        setLoading(true);

        LssmService.returnMatterToSBSA({ account_no, reason_code: reasonCode }).then((response) => {
            const { data, message, status } = response;

            switch (status) {
                case "success":
                    setToast(message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(message, "error");
                    setLoading(false);
                    unsetModal();

                    break;
                default:
                    break;
            }
        });
    };

    const handleSelect = (e) => {
        setBtnDisable(false);
        setReasonCode(e.target.value);
    };

    useEffect(() => {
        LssmService.recallReasons().then((data) => setOptions(data));
    }, []);

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box m={2}>
                <Title>Return Matter To SBSA</Title>
            </Box>
            <Box mb={2}>
                <FormControl>
                    <select
                        typeof="text"
                        className="select-input"
                        value={reasonCode}
                        onChange={handleSelect}
                        onClick={() => setDispalyOptions(true)}
                    >
                        {!displayOption && <option>Please Select A Reason</option>}
                        {displayOption &&
                            options?.map((option) => <option value={option.code}>{option.description}</option>)}
                    </select>
                    <span className={style.select_field}>
                        <ArrowDown base={13} side={9} color={"#959595"} />
                    </span>
                </FormControl>
            </Box>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <BluePillButton variant="contained" onClick={handleSendReturnMatter} disabled={btnDisable}>
                        Return Matter to SBSA
                    </BluePillButton>
                )}
            </Box>
        </Box>
    );
};

export default ReturnMatterToSBSA;
