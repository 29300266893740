import { Menu, MenuItem, Toolbar } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useEffect, useState } from "react";
import { useModal } from "providers/hooks/useModal";

import PtpSubmit from "components/modal/content/PtpSubmit";
import { useMaterialTable } from "providers/hooks/useMaterialTable";
import DebtorCasePtpService from "api/services/debtorCasePtpService";

const PaymentCommitment = ({ account_status, account_no, account_settlement, ptp_minimum }) => {
    const [menuAnchor, setMenuAnchor] = React.useState();
    const [frequencies, setFrequencies] = React.useState([]);
    const [periods, setPeriods] = React.useState([]);
    const { setModal, unsetModal } = useModal();

    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                DebtorCasePtpService.listPtps(params).then((response) => {
                    if (response.data?.frequencies != null) {
                        setFrequencies([...response.data?.frequencies]);
                    }
                    if (response.data.periods != null) {
                        setPeriods([...response.data.periods]);
                    }
                    // resolve promise with table data
                    resolve({
                        status: response.status,
                        data: response.data.ptps,
                        count: response.data.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: {
            account_no,
        },
    });

    const getList = (account_no) => {
        new Promise((resolve, reject) => {
            DebtorCasePtpService.listPtps({ account_no }).then((response) => {
                if (response.data.frequencies != null) {
                    setFrequencies([...response.data.frequencies]);
                }
                if (response.data.periods != null) {
                    setPeriods([...response.data.periods]);
                }
            });
        });
    };

    const handleModalEvent = ({ type, payload = undefined }) => {
        console.log("handleModalEvent", type);
        switch (type) {
            case "close":
                unsetModal();
                break;
            case "save":
                console.log(tableRef);
                if (tableRef.current != null) {
                    tableRef.current.onQueryChange();
                }
                setParams({ account_no });
                unsetModal();
                break;
            default:
                unsetModal();
        }
    };

    const handlePaymentCommitment = (e) => {
        setMenuAnchor(e.target);
    };

    const handleActionTypeSelect = (e) => {
        setMenuAnchor(false);
        switch (e.target.id) {
            case "once-off":
                setModal({
                    Component: (
                        <PtpSubmit.OnceOff
                            account_no={account_no}
                            account_settlement={account_settlement}
                            ptp_minimum={ptp_minimum}
                            dispatch={handleModalEvent}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "installment-plan":
                setModal({
                    Component: (
                        <PtpSubmit.InstalmentPlan
                            account_no={account_no}
                            account_settlement={account_settlement}
                            ptp_minimum={ptp_minimum}
                            frequencies={frequencies}
                            periods={periods}
                            dispatch={handleModalEvent}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "once-off-installment":
                setModal({
                    Component: (
                        <PtpSubmit.OnceOffInstalment
                            account_no={account_no}
                            account_settlement={account_settlement}
                            ptp_minimum={ptp_minimum}
                            frequencies={frequencies}
                            periods={periods}
                            dispatch={handleModalEvent}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "special-installments":
                setModal({
                    Component: (
                        <PtpSubmit.SpecialInstalment
                            account_no={account_no}
                            account_settlement={account_settlement}
                            ptp_minimum={ptp_minimum}
                            frequencies={frequencies}
                            periods={periods}
                            dispatch={handleModalEvent}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "full-final-settlement":
                setModal({
                    Component: (
                        <PtpSubmit.FullFinalSettlement
                            account_no={account_no}
                            account_settlement={account_settlement}
                            ptp_minimum={ptp_minimum}
                            frequencies={frequencies}
                            periods={periods}
                            dispatch={handleModalEvent}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "short-settlement":
                setModal({
                    Component: (
                        <PtpSubmit.ShortSettlement
                            account_no={account_no}
                            account_settlement={account_settlement}
                            ptp_minimum={ptp_minimum}
                            frequencies={frequencies}
                            periods={periods}
                            dispatch={handleModalEvent}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "attorney-client-settlement":
                setModal({
                    Component: (
                        <PtpSubmit.AttorneyClientSettlement
                            account_no={account_no}
                            account_settlement={account_settlement}
                            ptp_minimum={ptp_minimum}
                            frequencies={frequencies}
                            periods={periods}
                            dispatch={handleModalEvent}
                        />
                    ),
                    size: "sml",
                });
                break;
            case "other":
                break;
            default:
                break;
        }
    };

    React.useEffect(() => {
        getList(account_no);
    }, [account_no]);

    return (
        <div style={{ display: "inline-block" }}>
            <Toolbar>
                <BluePillButton onClick={handlePaymentCommitment}>Make a Payment Commitment</BluePillButton>
                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    keepMounted
                    onClose={() => setMenuAnchor(undefined)}
                >
                    <MenuItem id="once-off" onClick={handleActionTypeSelect}>
                        Once Off Payment
                    </MenuItem>
                    <MenuItem id="installment-plan" onClick={handleActionTypeSelect}>
                        Installment Plan
                    </MenuItem>
                    <MenuItem id="once-off-installment" onClick={handleActionTypeSelect}>
                        Once Off + Installment
                    </MenuItem>
                    <MenuItem id="special-installments" onClick={handleActionTypeSelect}>
                        Special installments
                    </MenuItem>
                    <MenuItem id="attorney-client-settlement" onClick={handleActionTypeSelect}>
                        Attorney Client Settlement
                    </MenuItem>
                    <MenuItem id="other" onClick={handleActionTypeSelect}>
                        Other
                    </MenuItem>
                </Menu>
            </Toolbar>
        </div>
    );
};

export default PaymentCommitment;
