import {
    Box,
    Paper,
    TextField,
} from "@mui/material";

import { createTheme, ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { styled } from "@mui/styles";
import { forwardRef } from "react";
import cancel_icon from "../../assets/icons/Cancel.png";
import edit_icon from "../../assets/icons/FontAwsome (edit).png";
import delete_icon from "../../assets/icons/Layer -14.png";
import save_icon_orange from "../../assets/icons/Layer -16.png";
import { colors } from "../../constants/styles";
import TableSearch from "./components/TableSearch";
import MaterialTable, {
    MTableCell,
    MTableHeader, MTableActions } from "material-table";
import tableTheme from "../../components/table/Styles/TableTheme";

const TablePaper = styled(Paper)({
    padding: "0 25px",
    borderRadius: "25px !important",
});

const CustomMaterialTable = ({ validationSchema = null, ...tableParams }) => {
    const validateRow = (field, row) => {
        if (validationSchema != null) {
            try {
                validationSchema.validateSyncAt(field, row);
                return true;
            } catch (e) {
                return {
                    isValid: false,
                    helperText: e.message,
                };
            }
        }

        // return undefined;
    };

    return (
          <ThemeProvider theme={tableTheme}>
            <MaterialTable
                {...tableParams}
                style={{ maxWidth: "100%" }}
                columns={
                    tableParams.columns != null
                        ? tableParams.columns.map((column) => ({
                              ...column,
                              width: column.width ?? "auto",
                              validate: (row) => validateRow(column.field, row),
                          }))
                        : []
                }
                icons={{
                    Check: forwardRef((props, ref) => (
                        <img
                            src={save_icon_orange}
                            alt="save"
                            ref={ref}
                            {...props}
                        />
                    )),
                    Clear: forwardRef((props, ref) => (
                        <img
                            src={cancel_icon}
                            alt="save"
                            ref={ref}
                            {...props}
                        />
                    )),
                    Edit: forwardRef((props, ref) => (
                        <img src={edit_icon} alt="edit" ref={ref} {...props} />
                    )),
                    Delete: forwardRef((props, ref) => (
                        <img
                            src={delete_icon}
                            alt="delete"
                            ref={ref}
                            {...props}
                        />
                    )),
                    ...tableParams.icons,
                }}
                components={{
                    Container: (props) => (
                        <TablePaper {...props} elevation={0} />
                    ),
                    EditField: (props) => {
                        switch (props.columnDef.type) {
                            case "date":
                                return (
                                        <DatePicker
                                            {...props}
                                            disableToolbar
                                            variant="inline"
                                            inputVariant="outlined"
                                            format="dd/MM/yyyy"
                                            margin="none"
                                        />
                                    );

                                case "textArea":
                                    return (
                                        <TextField
                                            {...props}
                                            onChange={(e) =>
                                                props.onChange(e.target.value)
                                            }
                                            multiline
                                            rows={2}
                                            maxRows={4}
                                            variant="outlined"
                                        />
                                    );
                                default:
                                    return (
                                        <TextField
                                            {...props}
                                            onChange={(e) =>
                                                props.onChange(e.target.value)
                                            }
                                            variant="outlined"
                                        />
                                    );
                            }
                        },
                        Cell: (props) => (
                            <MTableCell {...props} style={{ paddingRight: 10 }} />
                        ),
                        Header: (props) => (
                            <MTableHeader
                                {...props}
                                sx={{ paddingRight: "10px !important" }}
                            />
                        ),
                        Actions: (props) => <MTableActions {...props} />,
                        ...tableParams.components,
                    }}
                    options={{
                        ...tableParams.options,
                        pageSize: 10,
                    }}
                />
        </ThemeProvider>
    );
};

export default CustomMaterialTable;
