import ClientRegister from "pages/Debtors/tables/ClientRegister";
import DebtorCaseRegister from "pages/Debtors/tables/DebtorCaseRegister";
import Phone from "pages/Phone/Phone";
import ViewEmail from "../components/modal/ViewEmail";
import {
    ADMINISTRATION,
    AGENT_MANAGER,
    CALENDAR,
    DEBTORS,
    DEBTOR,
    EMAILS,
    MANAGER_DASHBOARD,
    AGENT_CAMPAIGN_DASHBOARD,
    PHONE,
    RESET_PASSWORD,
    DEBTOR_DASHBOARD,
    DEBTOR_EDIT,
} from "../constants/pathNames";
import Administration from "../pages/Administration/Administration";
import AdministrationUser from "../pages/Administration/AdministrationUser";
import ClientDetails from "../pages/Administration/ClientDetails";
import CreateClient from "../pages/Administration/CreateClient";
import EditUser from "../pages/Administration/EditUser";
import NewUser from "../pages/Administration/NewUser";
import Agent from "../pages/Agent/Agent";
import ResetPassword from "../pages/Auth/ResetPassword";
import ManagerDashboard from "../pages/ManagerDashboard/ManagerDashboard";
import AgentCampaignDashboard from "../pages/Agent/AgentCampaignDashboard";
import DebtorDetail from "../pages/Debtors/DebtorDetail";
import Emails from "../pages/Emails/Emails";
import Calendar from "../pages/Calendar/Calendar";
import DebtorPortal from "pages/Debtors/DebtorPortal";

export const dashboardRoutes = [
    {
        path: MANAGER_DASHBOARD,
        Component: ManagerDashboard,
        permission: "view-agent-register", //TODO change permission
    },
    {
        path: AGENT_CAMPAIGN_DASHBOARD,
        Component: AgentCampaignDashboard,
        permission: "view-debtor-info", //TODO change permission to view-campaign-summary?
    },
    {
        path: DEBTOR_DASHBOARD,
        Component: ClientRegister,
        permission: "debtor-portal-view",
    },
    {
        path: `${DEBTOR_DASHBOARD}/:account_type`,
        Component: DebtorPortal,
        permission: "debtor-portal-view",
    },
    {
        path: DEBTOR_EDIT,
        Component: DebtorPortal,
        permission: "debtor-portal-account-edit",
    },
    {
        path: DEBTORS,
        Component: ClientRegister,
        permission: "view-client-register",
    },
    {
        path: `${DEBTORS}/:name`,
        Component: DebtorCaseRegister,
        permission: "view-client-register",
    },
    {
        path: `${DEBTORS}/:name/:id`,
        Component: DebtorDetail,
        permission: "view-debtor-info",
    },
    {
        path: `${DEBTOR}/:name`,
        Component: DebtorDetail,
        permission: "view-debtor-info",
    },
    {
        path: AGENT_MANAGER,
        Component: Agent,
        permission: "view-agent-register",
    },
    {
        path: CALENDAR,
        Component: Calendar,
        permission: "view-events",
    },
    {
        path: EMAILS,
        Component: Emails,
        permission: "view-emails",
    },
    {
        path: `${EMAILS}/view/:unique_hash`,
        Component: ViewEmail,
        permission: "view-emails",
    },
    {
        path: PHONE,
        Component: Phone,
        permission: "view-emails",
    },
    {
        path: `${ADMINISTRATION}/client_manager/`,
        Component: Administration,
    },
    {
        path: `${ADMINISTRATION}/client_manager/new_client`,
        Component: CreateClient,
    },
    {
        path: `${ADMINISTRATION}/client_manager/:id`,
        Component: ClientDetails,
    },
    {
        path: `${ADMINISTRATION}/user_manager`,
        Component: AdministrationUser,
    },
    {
        path: `${ADMINISTRATION}/user_manager/new_user`,
        Component: NewUser,
    },
    {
        path: `${ADMINISTRATION}/user_manager/edit_user/:id`,
        Component: EditUser,
    },
    {
        path: `${RESET_PASSWORD}/:email/:token`,
        Component: ResetPassword,
    },
];
