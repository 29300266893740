import * as api from "..";
import { DEBTOR_PORTAL } from "api/config/endpoints";

const DebtorPortalService = {
    async passwordOTP() {
        const resp = await api.post(DEBTOR_PORTAL.PASSWORD_OTP);
        return resp;
        console.log("OTP request");
    },

    async passwordUpdate({ otp, password, password_confirm }) {
        const resp = await api.post(DEBTOR_PORTAL.PASSWORD_UPDATE, ...arguments);
        return resp;
        console.log(...arguments);
    },

    async debtorCaseRegistry() {
        const resp = await api.get(DEBTOR_PORTAL.DEBTOR_CASE_REGISTER);
        return resp;
    },
};

export default DebtorPortalService;
