import { Chip, Grid, TextField } from "@mui/material";
import { Autocomplete } from '@mui/material';
import TagInput from "components/inputs/TagInput";
import { EditorState } from "draft-js";
import draftToHtml from "draftjs-to-html";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { useSelector } from "react-redux";
import { CommsService } from "../../../api/services";
import { useModal } from "../../../providers/hooks/useModal";
import ButtonComponent from "../../ButtonComponent";
import ButtonLoader from "components/buttons/ButtonLoader";
import TextInput from "../../inputs/TextInput";
import * as Yup from "yup";

const ComposeEmail = ({ sendTo = null }) => {
    const [clientOptions, setClientOptions] = useState([]);
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [recipientOptions, setRecipientOptions] = useState([]);
    const [recipient, setRecipient] = useState({
        name: "",
        email: "",
        debtor_id: 0,
    });
    const [cc, setCC] = useState([]);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [currentEditorContent, setCurrentEditorContent] = useState("");
    const agentId = useSelector((state) => state.auth.id);
    const userType = useSelector((state) => state.auth.user_type_id);
    const { setToast, unsetModal } = useModal();

    let fileInputRef = null;

    const getPossibleRecipients = async () => {
        let resp;

        if (userType !== 5) {
            resp = await CommsService.getPossibleRecipients({
                agent_id: agentId,
            });
        } else {
            resp = await CommsService.getDebtorRecipients();
        }

        if (resp.status === "success") {
            setRecipientOptions(resp.data.options);
            if (sendTo) {
                const to = resp.data.options.filter((item) => item.email === sendTo);
                if (to && to.length) {
                    setRecipient(to[0]);
                    handleRecipientChange(to[0]);
                }
            }
        }
    };

    useEffect(() => {
        getPossibleRecipients();
    }, []);

    const sendEmail = async (body) => {
        const resp = await CommsService.agentSendEmailToDebtor(body);
        return resp;
    };

    const onEditorUpdate = (e) => {
        setEditorState(e);
    };

    const onContentUpdate = (e) => {
        setCurrentEditorContent(draftToHtml(e));
    };

    const handleRecipientChange = (val) => {
        console.log(val);
        if (val?.email) {
            const obj = {
                email: val.email,
                debtor_id: val.id,
                name: val.name,
            };
            setRecipient(obj);
        }
    };

    const toggleCC = (email) => {
        if (cc.includes(email)) {
            const temp = cc.filter((item) => item !== email);
            setCC(temp);
        } else {
            const temp = [...cc];
            temp.push(email);
            setCC(temp);
        }
    };

    const addAttachment = (e) => {
        if (e.target.files) {
            const temp = [...attachments];
            temp.push(e.target.files[0]);
            setAttachments(temp);
        }
    };

    const removeAttachment = (index) => {
        setAttachments(attachments.filter((item, idx) => idx !== index));
    };

    return (
        <div
            style={{
                padding: 20,
                paddingBottom: 50,
                overflow: "auto",
                height: "90vh",
                backgroundColor: "var(--colour-gray)",
            }}
        >
            <h1 className={"colour-blue bold m-0 mb-2"}>Compose Email</h1>
            <Formik
                initialValues={{
                    recipient_email: "",
                    subject: "",
                    cc: "",
                    debtor_id: "",
                    attachments: "",
                }}
                validationSchema={Yup.object().shape({
                    subject: Yup.string().required("Please enter a subject"),
                })}
                onSubmit={async (values) => {
                    setLoading(true);
                    const formData = new FormData();
                    const obj = {
                        debtor_id: recipient.debtor_id,
                        subject: values.subject,
                        body: currentEditorContent,
                    };
                    Object.keys(obj).forEach((key) => {
                        formData.append(key, obj[key]);
                    });
                    formData.append("recipient[email]", recipient.email);
                    formData.append("recipient[name]", recipient.name);
                    attachments.forEach((att) => {
                        formData.append("attachments[]", att);
                    });
                    cc.forEach((item) => {
                        formData.append("cc[]", item);
                    });
                    console.log("sending");
                    const send = await sendEmail(formData);
                    console.log("sent", send);
                    if (send) {
                        unsetModal();
                        setToast("Email sent successfully", "success");
                    }
                    setLoading(false);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    resetForm,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <div>
                        <Grid container spacing={3} justifyContent="center">
                            <Grid item md={12}>
                                <div>
                                    <p
                                        className={"pl-2 mt-0 bold colour-blue"}
                                        style={{ fontSize: 12, marginBottom: 6 }}
                                    >
                                        TO
                                    </p>
                                    <Autocomplete
                                        disablePortal
                                        options={recipientOptions}
                                        getOptionLabel={(option) => `${option?.name} <${option?.email}>`}
                                        onChange={(e, v) => handleRecipientChange(v)}
                                        style={{
                                            border: "1px solid var(--colour-blue)",
                                            borderRadius: 25,
                                            backgroundColor: "var(--colour-white)",
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                name={"recipient_email"}
                                                error={errors.recipient_email}
                                                inputProps={{
                                                    ...params.inputProps,
                                                    style: {
                                                        height: 30,
                                                        borderRadius: 25,
                                                        paddingLeft: 10,
                                                    },
                                                }}
                                                InputProps={{
                                                    ...params.InputProps,
                                                    disableUnderline: true,
                                                }}
                                                style={{
                                                    backgroundColor: "transparent",
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.recipient_email && !recipient ? (
                                        <p className={"ta-c mt-1 mb-0"} style={{ color: "red" }}>
                                            {errors.recipient_email}
                                        </p>
                                    ) : null}
                                </div>
                            </Grid>
                            <Grid item md={12}>
                                <p className={"pl-2 mt-0 bold colour-blue"} style={{ fontSize: 12, marginBottom: 6 }}>
                                    CC
                                </p>
                                <TagInput
                                    tags={cc}
                                    onTagAdd={toggleCC}
                                    onTagDelete={toggleCC}
                                    regEx={
                                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
                                    }
                                    regExMsg={"Please enter a valid email"}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <TextInput
                                    name={"subject"}
                                    value={values.subject}
                                    error={errors.subject}
                                    onChange={handleChange}
                                    label={"Subject"}
                                />
                            </Grid>
                            <Grid item md={12}>
                                <div>
                                    <Editor
                                        editorState={editorState}
                                        toolbarClassName="toolbarClassName"
                                        wrapperClassName="wrapperClassName"
                                        editorClassName="editorClassName"
                                        onEditorStateChange={onEditorUpdate}
                                        onContentStateChange={onContentUpdate}
                                        editorStyle={{
                                            backgroundColor: "#fff",
                                            padding: 10,
                                            marginBottom: 16,
                                            marginTop: 10,
                                            borderRadius: 20,
                                            border: "1px solid var(--colour-blue)",
                                            minHeight: 500,
                                        }}
                                        toolbar={{
                                            options: [
                                                "inline",
                                                "blockType",
                                                "fontSize",
                                                "fontFamily",
                                                "list",
                                                "textAlign",
                                                "link",
                                                "history",
                                            ],
                                        }}
                                        toolbarCustomButtons={[
                                            <div className={"mb-1 ml-1"}>
                                                <ButtonComponent
                                                    handler={() => {
                                                        fileInputRef.click();
                                                    }}
                                                    title={"+ Attachment"}
                                                />
                                            </div>,
                                        ]}
                                    />
                                    <input
                                        hidden
                                        type={"file"}
                                        onChange={addAttachment}
                                        ref={(r) => (fileInputRef = r)}
                                    />
                                    {errors.body && currentEditorContent === "" ? (
                                        <p className={"ta-c mt-1 mb-0"} style={{ color: "red" }}>
                                            {errors.body}
                                        </p>
                                    ) : null}
                                </div>
                                {attachments.map((item, index) => (
                                    <Chip label={item.name} onDelete={() => removeAttachment(index)} />
                                ))}
                            </Grid>
                            <ButtonLoader handler={handleSubmit} title={"Send"} loading={loading} />
                        </Grid>
                    </div>
                )}
            </Formik>
        </div>
    );
};

export default ComposeEmail;
