import React, { useState, useEffect } from "react";
import { TitleSection } from "../../common/Layouts";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import { AgentService, ClientService } from "../../api/services";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { useAuth } from "../../providers/hooks/useAuth";
import { useHistory } from "react-router";
import BulkAssignAgents from "../../components/modal/BulkAssignAgents";
import { useModal } from "../../providers/hooks/useModal";
import ButtonComponent from "../../components/ButtonComponent";
import ButtonLoader from "../../components/buttons/ButtonLoader";
import { Paper } from "@mui/material";
import { Edit } from "@mui/icons-material";
import { useGlobalLoader } from "providers/hooks/useGlobalLoader";
import TableSearch from "../../components/table/components/TableSearch";
import { useSelector } from "react-redux";
import AgentAssignedClients from "../../components/modal/AgentAssignedClients";

function Agent() {
    const [params, setParamsState] = useState({});
    const { setToast } = useModal();
    const { user_type_id } = useSelector((state) => state.auth);
    const { hasPermission } = useAuth();
    const history = useHistory();
    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                AgentService.agentsManager(params).then((response) => {
                    resolve({
                        status: response.status,
                        data: response.data?.agents,
                        count: response.data?.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: {},
    });
    const { setModal, unsetModal } = useModal();
    const [openModal, setOpenModal] = useState(false);
    const [listSelectedElem, setListSelectedElem] = useState([]);
    const [clients, setClients] = useState([]);
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState(null);
    const getClients = async () => {
        const resp = await ClientService.getClientManager({});
        if (resp.status === "success") {
            setClients(resp.data.clients);
            setLoading(false);
        }
    };

    useEffect(() => {
        getClients();
    }, []);

    const editAgent = (id) => {
        history.push(`/dashboard/administration/user_manager/edit_user/${id}`);
    };

    // useEffect(() => {
    //   let result;
    //   if (listAgents?.data) {
    //     if (listAgents?.data.length > 0) {
    //       result = listAgents?.data.map((el) => ({
    //         ...el,
    //         " ": (
    //           <span style={{ cursor: "pointer" }}>
    //             <img onClick={handlerComplete} src={edit_icon} alt="" />
    //           </span>
    //         ),
    //       }));
    //     } else {
    //       result = [
    //         {
    //           agents_name: "",
    //           manager: "",
    //           assigned_clients: "",
    //           " ": (
    //             <span style={{ cursor: "pointer" }}>
    //               <img src={""} alt="" />
    //             </span>
    //           ),
    //         },
    //       ];
    //     }
    //   }
    //   setListDetail(result);
    // }, [listAgents?.data]);

    const sendFilterRequest = (value) => {
        let newParams = {
            ...params,
            filter: [
                {
                    column_name: "name",
                    search_term: `${value}`,
                },
            ],
        };
        setParamsState(newParams);
    };

    useEffect(() => {
        setParams(params);
    }, [params]);

    // const sendSortRequest = (value) => {
    //   if (value === "agents_name" || value === "manager") {
    //     let name = value === "agents_name" ? "agent_name" : "manager";
    //     let newParams = {
    //       ...params,
    //       sort: {
    //         column_name: `${name}`,
    //         order: `${typeSort}`,
    //       },
    //     };
    //     setParamsState(newParams);
    //     setTypeSort(typeSort === "ASC" ? "DESC" : "ASC");
    //     if (!isLoading && listAgents?.data.length > 0) {
    //       dispatch(getListAgents(false, newParams));
    //     }
    //   }
    // };

    // const handleSetList = () => {
    //   dispatch(getListAgents());
    // };

    // useEffect(() => {
    //   if (listAgents.length === 0) {
    //     handleSetList();
    //   }
    // });

    // useEffect(() => {
    //   if (listAgents?.data) {
    //     handleSetList();
    //   }
    // }, [window.location.pathname]);

    // useListenerInterval(handleSetList, listAgents?.date);

    const assignAgents = async (assignTo) => {
        const obj = {};
        const selectedAgentIds = listSelectedElem.map((sel) => sel.id);
        console.log(assignTo, selectedAgentIds);
        assignTo.forEach((assign) => {
            obj[assign.id] = selectedAgentIds;
        });
        const resp = await AgentService.assignClients({ agents_clients: obj });
        if (resp.status === "success") {
            setParams({});
            unsetModal();
            setToast("Agent(s) updated successfully", "success");
        } else {
            setToast("An error occurred", "error");
        }
    };

    const openBulkAssignModal = () => {
        setModal({
            Component: <BulkAssignAgents clients={clients} onAssign={assignAgents} selectedAgents={listSelectedElem} />,
            size: "sml",
        });
    };

    const onAgentClick = (event, agent) => {
        console.log("agent", agent);
        setModal({
            Component: <AgentAssignedClients agent={agent} onUnassign={() => setParams({})} />,
            size: "sml",
        });
    };

    const editAction = hasPermission("update-user") && user_type_id === 1 ? {
        icon: () => <Edit style={{ color: "#ec7346" }} />,
        onClick: (e, row) => editAgent(row.id),
        position: "row",
    } : null;
    

    return (
        <div>
            <TitleSection>Agent manager</TitleSection>
            <div>
                {/* {listDetail?.length ? <TableUI
          align={'left'}
          type="agent"
          isFilter
          isCustom
          isClientManager
          handleSort={sendSortRequest}
          handlerClick={sendFilterRequest}
          handleAssign={() => setOpenModal(true)}
          handleChecked={handleChecked}
          data={listDetail}/> : ''} */}
                <div className={"p-2"} style={{ backgroundColor: "#fff", borderRadius: 20 }}>
                    <CustomMaterialTable
                        title={null}
                        tableRef={tableRef}
                        columns={[
                            {
                                title: "Name",
                                field: "name",
                                render: (rowData) => (
                                    <p>{`${rowData.name}${rowData.surname ? " " + rowData.surname : ""}`}</p>
                                ),
                            },
                            { title: "Manager", field: "manager" },
                            {
                                title: "Clients",
                                field: "clients",
                                render: (rowData) => (
                                    <p>
                                        {rowData.clients.map((item) => (
                                            <span className={"pill mr-1"}>{item.name}</span>
                                        ))}
                                    </p>
                                ),
                            },
                        ]}
                        data={remoteDataSource}
                        onRowClick={onAgentClick}
                        onSelectionChange={(rows) => setListSelectedElem(rows)}
                        onSearchChange={(e) => console.log("searching")}
                        actions={[
                            editAction,
                            {
                                icon: () => <ButtonLoader title={"Assign"} loading={loading} />,
                                onClick: openBulkAssignModal,
                                render: hasPermission("update-user"),
                            },
                            {
                                icon: () => <ButtonComponent disable={true} title={"Assign"} />,
                                onClick: () => {},
                                render: hasPermission("update-user"),
                                position: "toolbar",
                            },
                        ]}
                        options={{
                            actionsColumnIndex: 4,
                            toolbar: true,
                            selection: true,
                        }}
                        components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                        }}
                        localization={{
                            header: {
                                actions: "",
                            },
                        }}
                    />
                </div>
            </div>
        </div>
    );
}

export default Agent;
