import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import Modal from '@mui/material/Modal';
import { colors } from '../constants/styles';
import { ButtonBack, Section, TitleSection } from '../common/Layouts';
import { Grid } from '@mui/material';
import Fade from '@mui/material/Fade';
import CheckboxBase from './CheckboxBase';
import close_icon from '../assets/icons/Group 414.png'; 
import styled, {css} from 'styled-components'

function rand() {
  return Math.round(Math.random() * 20) - 10;
}

function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    width: 450,
    height: 450,
    backgroundColor: '#ffffff',
    borderRadius: '32px',
    outline: 'none',
    border: 'none',
    fontFamily: 'Avenir Next',
    '@media (min-width:1920px)': {
      width: 587,
      height: 587,
    },
  },
  paper_assign: {
    boxSizing: 'border-box',
    height: 'auto',
  },
  wrap_content: {
    position: 'relative',
    paddingLeft: 50,
    paddingTop: 25,
    '@media (min-width:1920px)': {
      paddingLeft: 70,
    },
  },
  text: {
    fontSize: '30px',
    fontWeight: 'bold',
    textAlign: 'center',
    padding: '40px 65px',
    fontFamily: 'Avenir Next Bold',
    '@media (min-width:1920px)': {
      fontSize: '40px',
      padding: '48px 80px',
    },
  },
  text_assign: {
    fontSize: '30px',
    fontWeight: 'bold',
    fontFamily: 'Avenir Next Bold',
    
    '@media (min-width:1920px)': {
      fontSize: '40px',
    },
  },
  text_subtitle: {
    fontFamily: 'Avenir Next Demi Bold',
    fontSize: '15px',
    textAlign: 'left',
    padding: '15px 0',
    '@media (min-width:1920px)': {
      fontSize: '20px',
      padding: '21px 0',
    },
  },
  text_row: {
    fontFamily: 'Avenir Next Medium',
    fontSize: '15px',
    textAlign: 'left',
    padding: '10px 0',
    '@media (min-width:1920px)': {
      fontSize: '20px',
    },
  },
  timer: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: '55px',
    '@media (min-width:1920px)': {
      paddingTop: '70px',
    },

    '& div': {
      fontSize: '70px',
      color: colors.orange,
      boxSizing: 'border-box',
      padding: '5px 0px',
      border: '1px solid #002B64',
      borderRadius: '17px',
      width: 230,
      textAlign: 'center',
      '@media (min-width:1920px)': {
        width: 266,
        fontSize: '80px',
      },
    }
  },
  wrap_btn: {
    marginTop: '25px',
    '@media (min-width:1920px)': {
      marginTop: '80px'
    },
  },
  wrap_btn_assign: {
    margin: '30px 0 50px 0',
    '@media (min-width:1920px)': {
      margin: '40px 0 60px 0'
    },
  },
  wrap_close: {
    position: 'absolute',
    top: '25px',
    right: '25px',
    cursor: 'pointer',
    zIndex: 10
  }
}));

const TextFieldComponent=styled.input`
  border: 1px solid ${colors.lightBlue};
  border-radius: 45px; 
  outline: none;
  padding: 13px 20px;
  font-size: 1.2vw;
  color: ${colors.lightBlue}; 
  margin-bottom: 10px;
  box-sizing: border-box;
  width: 100%;
  ${props => props.last && css`
    margin-bottom: 30px; 
  `}
  ::placeholder {
    opacity: 0.46; 
    font-size: 1.2vw;
   @media (min-width:1920px) {
      //font-size: 25px;
    }
  }
  @media (min-width:1920px) {
      //height: 74px;
      width: 570px;
      padding: 21px 42px;
   }
`;
 

export default function ModalComponent({
  open, 
  handleClose, 
  handleOpen, 

  type="base", 
  listSelectedElem=null, 
  handleChecked=null,
  isComplete=false, 
}) {
  
  const classes = useStyles();

  const [modalStyle] = React.useState(getModalStyle);

  const body = (
    <div style={modalStyle} className={classes.paper}>
     <div className={classes.text}>
      Call Time Limit Reached
     </div>
     <div className={classes.timer}>
       <div>3:00</div>
     </div>
     <Grid container justifyContent="center" className={classes.wrap_btn}>
       <ButtonBack
         onClick={handleClose}
         fontSize={40}
         small_size={30}
         height={91}
         width={372}>
         Close
       </ButtonBack>
     </Grid>
    </div>
  );

  const bodyAssign = (
    <div style={modalStyle} className={`${classes.paper} ${classes.paper_assign}`}>
      <div
       onClick={handleClose} 
       className={classes.wrap_close}>
        <img width={20} src={close_icon} alt={''}/>
      </div>
      <div className={classes.wrap_content}>
        <div className={classes.text_assign}>
        {(type == "Bulk Assign") && 'Bulk Assign'}
        {(type == "Forgot Password") && 'Forgot Password'}
        
      </div>
      <div className={classes.text_subtitle}>
        {`You have selected ${listSelectedElem ? listSelectedElem.length : 0 } agents.`}
      </div>
      {listSelectedElem && listSelectedElem.map(item => <Grid 
        style={{marginLeft: '-7px'}}
        container 
        alignItems="center" >
       {handleChecked && <CheckboxBase isChecked={item.isChecked} onChange={handleChecked} name={item.name}/>}
        <div className={classes.text_row}>
          {item.name}
        </div>
      </Grid>)}
      </div>

    
     { (type == "Bulk Assign") && <Grid container justifyContent="center" className={classes.wrap_btn_assign}>
       <ButtonBack
         onClick={handleClose}
         fontSize={40}
         small_size={30}
         customStyle={{
           backgroundColor: colors.white, 
           color: colors.blue,
           border: `2px solid #002B64`,
          }}
         height={61}
         width={450}>
         {!isComplete ? 'Assign' : 'Complete'}
       </ButtonBack>
     </Grid>}
    </div>
  );

  return (
    <div>
      <Modal
        open={open}
        onClose={handleClose}
        BackdropProps={{
          style: {
            backdropFilter: "blur(2px)",
            backgroundColor:'rgba(0, 0, 0, 0.45)',
            //backgroundColor:'rgba(0,0,0,0.1)'

          }
        }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >
        <Fade in={open}>
          {type === 'base' ? body : bodyAssign}
        </Fade>
      </Modal>
    </div>
  );
}