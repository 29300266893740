import React from 'react';
import { styled } from '@mui/system';
import Button from '@mui/material/Button';
import { CircularProgress } from '@mui/material';
import { colors } from '../../constants/styles';


const CustomButton = styled(Button)(({ theme, variant, color, height, width, small_size, fontSize }) => ({
  borderRadius: '36px',
  backgroundColor: variant === 'outlined' ? 'transparent' : colors.blue,
  color: variant === 'outlined' ? color : colors.white,
  textTransform: 'none',
  height: height - height / 5,
  fontSize: small_size,
  width: width - width / 5,
  '@media (min-width:1920px)': {
    height: height,
    fontSize: fontSize,
    width: width,
  },
  '&:hover': {
    backgroundColor: variant === 'outlined' ? colors.blue : colors.lightGray,
    color: variant === 'outlined' ? colors.white : colors.blue,
  }
}));

function ButtonLoader({
  title = '', 
  handler = null, 
  type = '', 
  disable = false,
  width = '150px', 
  color = 'inherit', 
  height = 'auto',
  fontSize = '1rem',
  small_size = '1vw',
  variant = 'outlined',
  loading = false,
}) {
  const handleClick = () => {
    if (handler) {
      handler();
    }
  };

  return (
    <CustomButton 
      type={type}
      disabled={loading || disable}
      variant={variant}
      color={color}
      height={height}
      width={width}
      small_size={small_size}
      fontSize={fontSize}
      onClick={handleClick}
    >
      {loading ? (
        <CircularProgress size={20} sx={{ color: variant === 'contained' ? '#fff' : 'var(--colour-blue)' }} />
      ) : (
        title
      )}
    </CustomButton>
  );
}

export default ButtonLoader;
