import { Box, CircularProgress, Grid, TextField } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import styles from "./styles.module.css";
import { colors } from "constants/styles";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import moment from "moment";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    contact_type_id: Yup.number()
        .required("Contact Type ID is a required field")
        .min(1, "ID can't be 0 or negative number"),
    contact_comments: Yup.string().required("Contact Comments is a required field"),
    report_date: Yup.date().required("Report Date is a required field"),
    visit_type: Yup.string().required("Visit Type is a required field"),
    visit_comment: Yup.string().required("Visit Comment is a required field"),
    how_found: Yup.string().required("How Found is a required field"),
    where_found: Yup.string().required("Where Found is a required field"),
    sect_129_ltr_sent_date: Yup.date().required("Sect 129 LTR Sent Date is a required field"),
});

const CollectionReport = ({ account_no, unsetModal }) => {
    const [loading, setLoading] = useState(false);
    const [reportInfo, setReportInfo] = useState({
        account_no: account_no,
        contact_type_id: "",
        contact_comments: "",
        report_date: "",
        visit_type: "",
        visit_comment: "",
        how_found: "",
        where_found: "",
        sect_129_ltr_sent_date: "",
    });
    const { setToast } = useModal();

    const handleChange = (e) => {
        setReportInfo({ ...reportInfo, [e.target.name]: e.target.value });
    };

    const handleSaveReportInfo = () => {
        setLoading(true);

        LssmService.saveCustomerCollectionReport({
            ...reportInfo,
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    React.useEffect(() => {
        LssmService.getCustomerCollectionReport({ account_no }).then((data) =>
            setReportInfo({
                account_no,
                contact_type_id: data.contact_type_id,
                contact_comments: data.contact_comments,
                report_date: moment(new Date(data.report_date).toLocaleString()).format("YYYY-MM-DD"),
                visit_type: data.visit_type,
                visit_comment: data.visit_comment,
                how_found: data.how_found,
                where_found: data.where_found,
                sect_129_ltr_sent_date: moment(new Date(data.sect_129_ltr_sent_date).toLocaleString()).format(
                    "YYYY-MM-DD",
                ),
            }),
        );
    }, []);

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Collection Report</Title>
            </Box>

            <Formik
                initialValues={reportInfo}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleSaveReportInfo}
            >
                {({ errors }) => (
                    <Form fullWidth>
                        <Grid container justifyContent="center" flexDirection="column">
                            <Grid item md={10}>
                                <Grid container direction="row">
                                    <Grid item md={12}>
                                        <Grid container spacing={4} justifyContent="space-between">
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>Contact Type ID</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="contact_type_id"
                                                        name="contact_type_id"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        type="number"
                                                        value={reportInfo.contact_type_id}
                                                        error={errors.contact_type_id ? true : false}
                                                        helperText={errors.contact_type_id}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>Contact Comments</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="contact_comments"
                                                        name="contact_comments"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={reportInfo.contact_comments}
                                                        error={errors.contact_comments ? true : false}
                                                        helperText={errors.contact_comments}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>Report Date</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="date"
                                                        id="report_date"
                                                        name="report_date"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={reportInfo.report_date}
                                                        error={errors.report_date ? true : false}
                                                        helperText={errors.report_date}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>Visit Type</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="visit_type"
                                                        name="visit_type"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={reportInfo.visit_type}
                                                        error={errors.visit_type ? true : false}
                                                        helperText={errors.visit_type}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>Visit Comment</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="visit_comment"
                                                        name="visit_comment"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={reportInfo.visit_comment}
                                                        error={errors.visit_comment ? true : false}
                                                        helperText={errors.visit_comment}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>How Found</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="how_found"
                                                        name="how_found"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={reportInfo.how_found}
                                                        error={errors.how_found ? true : false}
                                                        helperText={errors.how_found}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>Where Found</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="where_found"
                                                        name="where_found"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={reportInfo.where_found}
                                                        error={errors.where_found ? true : false}
                                                        helperText={errors.where_found}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div className={styles.wrapper_section} style={{ marginLeft: "-10px" }}>
                                                    <div className={styles.title}>Sect 129 LTR Sent Date</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="date"
                                                        id="sect_129_ltr_sent_date"
                                                        name="sect_129_ltr_sent_date"
                                                        customStyle={{
                                                            justifyContent: "flex-start",
                                                        }}
                                                        value={reportInfo.sect_129_ltr_sent_date}
                                                        error={errors.sect_129_ltr_sent_date ? true : false}
                                                        helperText={errors.sect_129_ltr_sent_date}
                                                        onChange={handleChange}
                                                        isSearch={false}
                                                    />
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            {loading ? (
                                <CircularProgress />
                            ) : (
                                <BluePillButton type="submit" style={{ marginTop: "2em" }}>
                                    Save Collection Report
                                </BluePillButton>
                            )}
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default CollectionReport;
