import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CommsService } from "../../../api/services";
import { useModal } from "../../../providers/hooks/useModal";
import { AttachFileRounded } from "@mui/icons-material";
import email_icon from "../../../assets/icons/FontAwsome (paper-plane-white).png";
import { colors } from "../../../constants/styles";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "50px",
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    customIconButton: {
        backgroundColor: colors.blue,
        color: colors.white,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: colors.lightGray,
            color: colors.blue,
        },
    },
    customIconButtonWithImage: {
        width: "150px",
        borderRadius: "36px",
        backgroundColor: colors.blue,
        color: colors.white,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: colors.lightGray,
            color: colors.blue,
        },
    },
    customIconButtonLoading: {
        backgroundColor: colors.white,
    },

    customTypography: {
        color: colors.blue,
        marginTop: "2px",
    },
}));

const EmailTemplate = ({ account_no, debtor_name, email }) => {
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
    const [emailData, setEmailData] = useState({
        subject: "",
        body: "",
        id: 0,
    });
    const { setToast, unsetModal } = useModal();

    const getEmailTemplates = async () => {
        const resp = await CommsService.getMessageTemplates({
            communication_type_id: 2,
        });

        if (resp.status === "success") {
            setEmailTemplateOptions(resp.data.options);
        }
    };

    useEffect(() => {
        getEmailTemplates();
    }, []);

    const sendEmail = async (body) => {
        const resp = await CommsService.agentSendEmailToDebtor(body);
        return resp;
    };

    const handleEmailTemplateChange = (val) => {
        console.log(val);
        if (val?.body) {
            const obj = {
                body: val.body,
                id: val.id,
            };
            setEmailData(obj);
        }
    };

    const addAttachment = (e) => {
        if (e.target.files) {
            const temp = [...attachments];
            temp.push(e.target.files[0]);
            setAttachments(temp);
        }
    };

    const removeAttachment = (index) => {
        setAttachments(attachments.filter((item, idx) => idx !== index));
    };

    return (
        <div
            style={{
                padding: 50,
                overflow: "auto",
                height: "70vh",
            }}
        >
            <h1 className={"colour-blue bold m-0 mb-2"}>Compose Email</h1>
            <Formik
                initialValues={{
                    recipient_email: "",
                    subject: "",
                    cc: "",
                    debtor_id: "",
                    attachments: "",
                }}
                onSubmit={async (values) => {
                    setLoading(true);
                    const formData = new FormData();
                    const obj = {
                        subject: account_no + "-" + debtor_name,
                        communication_id: emailData.id,
                        account_no,
                    };
                    Object.keys(obj).forEach((key) => {
                        formData.append(key, obj[key]);
                    });
                    formData.append("recipient[email]", email);
                    formData.append("recipient[name]", debtor_name);
                    attachments.forEach((att) => {
                        formData.append("attachments[]", att);
                    });
                    console.log("sending");
                    const send = await sendEmail(formData);
                    console.log("sent", send);
                    if (send) {
                        unsetModal();
                        setToast("Email sent successfully", "success");
                    }
                    setLoading(false);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    resetForm,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <Box component="Grid" container>
                        <Grid item md={12}>
                            <Box
                                component="Span"
                                justifyContent="space-between"
                                alignItems="center"
                                sx={{ display: "flex", mb: 3 }}
                            >
                                <div>
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        className={classes.customIconButton}
                                        size="large">
                                        <input hidden type={"file"} onChange={addAttachment} />
                                        <AttachFileRounded />
                                    </IconButton>
                                </div>

                                <IconButton
                                    disabled={!emailData.id}
                                    className={
                                        !loading ? classes.customIconButtonWithImage : classes.customIconButtonLoading
                                    }
                                    onClick={handleSubmit}
                                    size="large">
                                    {loading ? (
                                        <CircularProgress size={20} style={{ color: "var(--colour-blue)" }} />
                                    ) : (
                                        <>
                                            <img src={email_icon} color={"blue"} alt="call" />
                                            <Typography variant="body1" style={{ marginLeft: "15px" }}>
                                                <strong>Send</strong>
                                            </Typography>
                                        </>
                                    )}
                                </IconButton>
                            </Box>
                        </Grid>
                        <Divider />
                        <Grid item md={12}>
                            <Typography className={"colour-blue"}>
                                <strong>To</strong>: {debtor_name}
                            </Typography>
                        </Grid>
                        <Divider />
                        <Grid item md={12}>
                            <Typography className={"colour-blue"}>
                                <strong>Subject</strong>: {account_no + "-" + debtor_name}
                            </Typography>
                        </Grid>
                        <Divider />
                        <Grid item md={4}>
                            <Autocomplete
                                className={"mt-2"}
                                disablePortal
                                options={emailTemplateOptions}
                                getOptionLabel={(option) => `${option?.name}`}
                                onChange={(e, v) => handleEmailTemplateChange(v)}
                                style={{
                                    border: "1px solid var(--colour-blue)",
                                    borderRadius: 25,
                                    backgroundColor: "var(--colour-white)",
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name={"email_template"}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                borderRadius: 25,
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                        }}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                        label="Select A Message"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={12}>
                            {attachments.map((item, index) => (
                                <Chip label={item.name} onDelete={() => removeAttachment(index)} />
                            ))}
                        </Grid>
                    </Box>
                )}
            </Formik>
        </div>
    );
};

export default EmailTemplate;
