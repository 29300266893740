import { Box, CircularProgress, FormGroup, Grid, TextField, FormControlLabel, Switch, Tooltip, IconButton } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@mui/icons-material";


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    employer_party_name: Yup.string().required("Party two name is required"),
    employer_address_line1: Yup.string().required("Party two address line 1 is required"),
    employer_address_line2: Yup.string().required("Party two address line 2 is required"),
    employer_address_line3: Yup.string().required("Party two address line 3 is required"),
    employer_email: Yup.string().required("Party two address city is required"),
    contact_type: Yup.date().required("Letter of executorship received date is required"),
    report_date: Yup.date().required("Death certificate date is required"),
    other_income: Yup.string().required("Decedent's attorney is required"),
    salary_pay_date: Yup.string().required("Decedent's attorney telephone number is required"),
    visit_comment: Yup.string().required("Executor's firm is required"),
    employer_tel_type: Yup.date().required("Date executor appointed is required"),
    salary_amount: Yup.string().email("Invalid email format").required("Executor's email is required"),
    contact_comments: Yup.string().required("Previous executor name is required"),
    total_expenditure: Yup.string().required("Previous executor firm name is required"),
    sect_129_ltr_sent_date: Yup.date().required("Previous date executor appointed is required"),
    salary_type: Yup.string().required("High court is required"),
    how_found: Yup.string().required("where_found reference is required"),
    where_found: Yup.string().required("where_found name is required"),
});


const SendCustomerInformation = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loeReceivedChecked, setLoeReceivedChecked] = useState(false);
    const [decSpoaChecked, setDecSpoaChecked] = useState(false);
    const [suretyExistsChecked, setSuretyExistsChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [display, setDisplay] = useState({
        party_one: true,
        party_two: false,
        financial_details: false,
        collection_report_details: false,
    });

    const [data, setData] = useState({
        account_no,
        party_type: "A",
        email: "",
        occupation: "",
        address_type: "H",
        address_line1: "",
        address_line2: "",
        address_line3: "",
        employment_period: "",
        tel_type: "H",
        tel_no: "",
        //employment details
        employer_party_type: "E",
        employer_party_name: "",
        employer_email: "",
        employer_address_type: "B",
        employer_address_line1: "",
        employer_address_line2: "",
        employer_address_line3: "",
        employer_tel_type: "B",
        employer_tel_no: "",
        //financial details
        salary_type: "M",
        salary_amount: 0,
        salary_proof_status: "N",
        salary_pay_date: "25",
        other_income: 0,
        total_expenditure: 0,
        //collection report details
        contact_type: "B",
        contact_comments: "",
        report_date: "",
        visit_type: "W",
        visit_comment: "",
        how_found: "",
        where_found: "",
        sect_129_ltr_sent_date: "",

    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSuretyExistsChecked= (e) => {
        setSuretyExistsChecked(e.target.checked);
        !suretyExistsChecked
          ? setData({ ...data, salary_proof_status: "Y"})
          : setData({ ...data, salary_proof_status: "N" });
    };

    

    const handleSendCustomerInformation = () => {
        setLoading(true);
        LssmService.sendCustomerInformation({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const isFormValid = () => {
        for (let key in data) {
            if( data[key] === 'salary_proof_status') continue;
            if (!data[key]) {
                return true; 
            }
        }
        
        return false;
    };

    const isFormPartyOneValid = () => {

        const { 
            email,
            occupation,
            employment_period,
            tel_type,
            address_type,
            address_line1,
            address_line2,
            address_line3,
            tel_no 
        } = data;
    
        const extractedData = {
            email,
            occupation,
            employment_period,
            tel_type,
            address_type,
            address_line1,
            address_line2,
            address_line3,
            tel_no
        };

        for (let key in extractedData) {
            if (!data[key]) {
                return true; 
            }
        }        
        return false;
    };

    const isFormPartyTwoValid = () => {
        const { 
            employer_party_name,
            employer_address_type,
            employer_address_line1,
            employer_address_line2,
            employer_address_line3,
            employer_email,
            employer_tel_type,
            employer_tel_no 
        } = data;
    
        const extractedData = {
            employer_party_name,
            employer_address_type,
            employer_address_line1,
            employer_address_line2,
            employer_address_line3,
            employer_email,
            employer_tel_type,
            employer_tel_no 
        };

        for (let key in extractedData) {
            if (!data[key]) {
                return true; 
            }
        }        
        return false;
    };

    const isFinancialFormValid = () => {
        const { 
            salary_type,
            salary_amount,
            salary_proof_status,
            salary_pay_date,
            other_income,
            total_expenditure
        } = data;
    
        const extractedData = {
            salary_type,
            salary_amount,
            salary_proof_status,
            salary_pay_date,
            other_income,
            total_expenditure
        };

        for (let key in extractedData) {
            if (!data[key]) {
                return true; 
            }
        }        
        return false;
    };

    const handleAddPartyOne = () => {
        setDisplay({ ...display, party_two: true, party_one: false});
    };

    const handleAddPartyTwo = () => {
        setDisplay({ ...display, party_two: false, financial_details: true});
        console.log(data);
    };

    const handleFinancialDetails = () => {
        setDisplay({ ...display, financial_details: false, collection_report_details: true});
        console.log(data);
    };
    

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Sent to update SBSA of the customer’s details">
                    <Title>
                        Customer information
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendCustomerInformation}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            {display.party_one && (
                            
                            <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>

                            <Box mb={2}  display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Telephone Number</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <TextField
                                                variant="outlined"
                                                fullWidth
                                                id="tel_no"
                                                name="tel_no"
                                                value={data.tel_no}
                                                error={errors.tel_no ? true : false}
                                                helperText={errors.tel_no}
                                                onChange={handleChange}
                                                
                                            />
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Occupation</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="occupation"
                                        name="occupation"
                                        value={data.occupation}
                                        error={errors.occupation ? true : false}
                                        helperText={errors.occupation}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>
                       
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                <div className={styles.title}>Email Address</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="email"
                                        name="email"
                                        value={data.email}
                                        error={errors.email ? true : false}
                                        helperText={errors.email}
                                        onChange={handleChange}
                                        
                                    />
                                </div>
                            </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Address Line 1</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="address_line1"
                                        name="address_line1"
                                        value={data.address_line1}
                                        error={errors.address_line1 ? true : false}
                                        helperText={errors.address_line1}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Address Line 2</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="address_line2"
                                        name="address_line2"
                                        value={data.address_line2}
                                        error={errors.address_line2 ? true : false}
                                        helperText={errors.address_line2}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Address Line 3</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="address_line3"
                                        name="address_line3"
                                        value={data.address_line3}
                                        error={errors.address_line3 ? true : false}
                                        helperText={errors.address_line3}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Address Type</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.address_type ? true : false}
                                                id="address_type"
                                                name="address_type"
                                                helperText={errors.address_type}
                                                className={"select-input-e4"}
                                                value={data.address_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Address Type</option>
                                                <option value="B">Business</option>
                                                <option value="D">Domicilium</option>
                                                <option value="H">Home</option>
                                                <option value="O">Other</option>
                                                <option value="P">Postal</option>
                                                <option value="S">Street</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Employment Period</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="employment_period"
                                        name="employment_period"
                                        value={data.employment_period}
                                        error={errors.employment_period ? true : false}
                                        helperText={errors.employment_period}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Telephone Type</div>
                                        <select
                                            fullWidth
                                            error={errors.tel_type ? true : false}
                                            id="tel_type"
                                            name="tel_type"
                                            helperText={errors.tel_type}
                                            className={"select-input-e4"}
                                            value={data.tel_type}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Telephone Type</option>
                                            <option value="B">Business</option>
                                            <option value="C">Cellular</option>
                                            <option value="F">Fax</option>
                                            <option value="O">Other</option>
                                            <option value="H">Home</option>
                                            <option value="W">Work</option>
                                            <option value="U">Unknown</option>
                                        </select>
                                        <span className={style.select_field}>
                                            <ArrowDown base={13} side={9} color={"#959595"} />
                                        </span>
                                    </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            </Grid>

                            )}
                            {display.party_two && (
                            
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Employer Name</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="employer_party_name"
                                        name="employer_party_name"
                                        value={data.employer_party_name}
                                        error={errors.employer_party_name ? true : false}
                                        helperText={errors.employer_party_name}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Telephone Type</div>
                                        <select
                                            fullWidth
                                            error={errors.employer_tel_type ? true : false}
                                            id="employer_tel_type"
                                            name="employer_tel_type"
                                            helperText={errors.employer_tel_type}
                                            className={"select-input-e4"}
                                            value={data.employer_tel_type}
                                            onChange={handleChange}
                                        >
                                            <option value="">Select Telephone Type</option>
                                            <option value="B">Business</option>
                                            <option value="C">Cellular</option>
                                            <option value="F">Fax</option>
                                            <option value="O">Other</option>
                                            <option value="H">Home</option>
                                            <option value="W">Work</option>
                                            <option value="U">Unknown</option>
                                        </select>
                                        <span className={style.select_field}>
                                            <ArrowDown base={13} side={9} color={"#959595"} />
                                        </span>
                                    </div>
                                </FormGroup>
                            </Box>
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                <div className={styles.title}>Telephone Number</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="employer_tel_no"
                                        name="employer_tel_no"
                                        value={data.employer_tel_no}
                                        error={errors.employer_tel_no ? true : false}
                                        helperText={errors.employer_tel_no}
                                        onChange={handleChange}
                                        
                                    />
                                </div>
                            </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Address Line 1</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="employer_address_line1"
                                        name="employer_address_line1"
                                        value={data.employer_address_line1}
                                        error={errors.employer_address_line1 ? true : false}
                                        helperText={errors.employer_address_line1}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Address Line 2</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="employer_address_line2"
                                        name="employer_address_line2"
                                        value={data.employer_address_line2}
                                        error={errors.employer_address_line2 ? true : false}
                                        helperText={errors.employer_address_line2}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Address Line 3</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="employer_address_line3"
                                        name="employer_address_line3"
                                        value={data.employer_address_line3}
                                        error={errors.employer_address_line3 ? true : false}
                                        helperText={errors.employer_address_line3}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Address Type</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.employer_address_type ? true : false}
                                                id="employer_address_type"
                                                name="employer_address_type"
                                                helperText={errors.employer_address_type}
                                                className={"select-input-e4"}
                                                value={data.employer_address_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Address Type</option>
                                                <option value="B">Business</option>
                                                <option value="D">Domicilium</option>
                                                <option value="H">Home</option>
                                                <option value="O">Other</option>
                                                <option value="P">Postal</option>
                                                <option value="S">Street</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Email Address</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="employer_email"
                                        name="employer_email"
                                        value={data.employer_email}
                                        error={errors.employer_email ? true : false}
                                        helperText={errors.employer_email}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            </Grid>

                            )}
                            {display.collection_report_details && (
                            <>
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Visit Type</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.visit_type ? true : false}
                                                id="visit_type"
                                                name="visit_type"
                                                helperText={errors.visit_type}
                                                className={"select-input-e4"}
                                                value={data.visit_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Visit Type</option>
                                                <option value="W">Work</option>
                                                <option value="U">Unknown</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                <div className={styles.title}>Contact Type</div>
                                    <select
                                        fullWidth
                                        error={errors.contact_type ? true : false}
                                        id="contact_type"
                                        name="contact_type"
                                        helperText={errors.contact_type}
                                        className={"select-input-e4"}
                                        value={data.contact_type}
                                        onChange={handleChange}
                                    >
                                        <option value="">Select Contact Type</option>
                                        <option value="C">Cell Phone</option>
                                        <option value="W">Work Phone</option>
                                        <option value="H">Home Phone</option>
                                        <option value="O">Other</option>
                                        <option value="N">No Contact</option>
                                    </select>
                                    <span className={style.select_field}>
                                        <ArrowDown base={13} side={9} color={"#959595"} />
                                    </span>
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>How Found</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="how_found"
                                        name="how_found"
                                        value={data.how_found}
                                        error={errors.how_found ? true : false}
                                        helperText={errors.how_found}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Report Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="report_date"
                                        name="report_date"
                                        value={data.report_date}
                                        error={errors.report_date ? true : false}
                                        helperText={errors.report_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={6}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Visit Comment</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="visit_comment"
                                        name="visit_comment"
                                        value={data.visit_comment}
                                        error={errors.visit_comment ? true : false}
                                        helperText={errors.visit_comment}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Contact Comments</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="contact_comments"
                                        name="contact_comments"
                                        value={data.contact_comments}
                                        error={errors.contact_comments ? true : false}
                                        helperText={errors.contact_comments}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={4}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Where Found</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="where_found"
                                        name="where_found"
                                        value={data.where_found}
                                        error={errors.where_found ? true : false}
                                        helperText={errors.where_found}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Section 129 Ltr Sent Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="sect_129_ltr_sent_date"
                                        name="sect_129_ltr_sent_date"
                                        value={data.sect_129_ltr_sent_date}
                                        error={errors.sect_129_ltr_sent_date ? true : false}
                                        helperText={errors.sect_129_ltr_sent_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            </Grid>
                            </>
                            )}

                            {display.financial_details && (
                            <>
                            <Grid container spacing={2}>
                   
                            <Grid item xs={12} sm={6}>

                            <Box mb={6}>
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={suretyExistsChecked} color="primary" onChange={handleSuretyExistsChecked} />}
                                        label="is there salary proof status?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Salary Type</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.salary_type ? true : false}
                                                id="salary_type"
                                                name="salary_type"
                                                helperText={errors.salary_type}
                                                className={"select-input-e4"}
                                                value={data.salary_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Salary Type</option>
                                                <option value="W">Weekly</option>
                                                <option value="M">Monthly</option>
                                                <option value="B">Bi-Weekly</option>
                                                <option value="C">Commission</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Total Expenditure</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="total_expenditure"
                                        name="total_expenditure"
                                        value={data.total_expenditure}
                                        error={errors.total_expenditure ? true : false}
                                        helperText={errors.total_expenditure}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                
                            <Grid item xs={12} sm={6}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Salary Pay Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="salary_pay_date"
                                        name="salary_pay_date"
                                        value={data.salary_pay_date}
                                        error={errors.salary_pay_date ? true : false}
                                        helperText={errors.salary_pay_date}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Other Income</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="other_income"
                                        name="other_income"
                                        value={data.other_income}
                                        error={errors.other_income ? true : false}
                                        helperText={errors.other_income}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Salary Amount</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="salary_amount"
                                        name="salary_amount"
                                        value={data.salary_amount}
                                        error={errors.salary_amount ? true : false}
                                        helperText={errors.salary_amount}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>

                            </Grid>
                            </>
                            )}
                         

                            <Box  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <>

                                    {display.party_one && (
                                    <BluePillButton variant="contained" onClick={handleAddPartyOne} disabled={isFormPartyOneValid()}>
                                        Add Debtor Details
                                     </BluePillButton>
                                    )}

                                    {display.party_two && (
                                     <BluePillButton variant="contained" onClick={handleAddPartyTwo} disabled={isFormPartyTwoValid()}>
                                        Add Employer Details
                                     </BluePillButton>
                                    )}
                                    
                                    {display.financial_details && (
                                    <BluePillButton variant="contained" onClick={handleFinancialDetails} disabled={isFinancialFormValid()}>
                                        Add Financial Details
                                    </BluePillButton>
                                     )}

                                    {display.collection_report_details && (
                                    <BluePillButton variant="contained" onClick={handleSendCustomerInformation}>
                                        Send Customer Information
                                    </BluePillButton>
                                     )}
                                    </>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendCustomerInformation;
