import React, { useEffect } from "react";
import { useActivityTracker } from "../../../providers/hooks/useActivityTracker";

const ActivityTracker = () => {
  const { reset, incrementInactivityTime } = useActivityTracker();

  useEffect(() => {
    const handleActivity = () => {
      reset();
      incrementInactivityTime(); 
    };

    document.addEventListener("click", handleActivity);
    document.addEventListener("mousemove", handleActivity);
    document.addEventListener("input", handleActivity);

    return () => {
      document.removeEventListener("click", handleActivity);
      document.removeEventListener("mousemove", handleActivity);
      document.removeEventListener("input", handleActivity);
    };
  }, [reset]);

  return <div></div>;
};

export default ActivityTracker;
