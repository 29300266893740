import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import SwitchComponent from "../../components/SwitchComponent";
import TimeFilter from "../../components/TimeFilter";
import styled from "styled-components";
import { useModal } from "../../providers/hooks/useModal";
import { AgentService } from "../../api/services";
import AgentLogoutBreaks from "./Tables/AgentLogoutBreaks";
import AgentSummary from "./Tables/AgentSummary";
import CampaignSummary from "./Tables/CampaignSummary";
import { Section, TitleSection } from "../../common/Layouts";
import DebtorCasePtpService from "api/services/debtorCasePtpService";
import PtpOverrideRequests from "./Tables/PtpOverrideRequests";
import ButtonComponent from "components/ButtonComponent";

const SectionTable = styled.div`
  margin: 20px 0px;
  .MuiPaper-rounded {
    border-radius: 15px !important;
    box-sizing: border-box;
  }
`;

const getClientById = (name, clients) => {
  const client = clients.find((item) => item.name === name);
  return client ? { client_id: client.id } : {};
};

function ManagerDashboard() {
  const { setModal } = useModal();
  const [configList, setConfigList] = useState([
    "agents summary",
    "logout/breaks summary",
  ]);
  const [currentTable, setCurrentTable] = useState(configList[0]);
  const [clients, setClients] = useState([]);
  const [ptpOverrides, setPtpOverrides] = useState(null);
  const [ptpDiscountOverrides, setPtpDiscountOverrides] = useState(null);
  const [paramLogout, setParamLogout] = useState({});
  const [params, setParams] = useState({});
  const [loading, setLoading] = useState(true);

  const getClients = async () => {
    setLoading(true);
    try {
      const resp = await AgentService.getAvailableClientsFromActiveCampaigns();
      if (resp.status === "success") {
        setClients(resp.data.clients);
      }
    } catch (error) {
      console.error("Error fetching clients:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleTimerFilterVisibility = (tabIndex) => {
    return !(tabIndex === configList[0] || tabIndex === configList[1]);
  };


  const getListOfPtpDiscountOverrides = async () => {
    try {
      const resp = await DebtorCasePtpService.checkOverrides({ ptp_status_id: 7 });
      if (resp.status === "success") {
        setPtpDiscountOverrides(resp.data.ptp_override_available ? 'PTP Overrides' : null);
      }
    } catch (error) {
      console.error("Error fetching PTP discount overrides:", error);
    }
  };

  const getListOfPtpOverrides = async () => {
    try {
      const resp = await DebtorCasePtpService.checkOverrides({ ptp_status_id: 6 });
      if (resp.status === "success") {
        setPtpOverrides(resp.data.ptp_override_available ? 'PTP Overrides' : null);
      }
    } catch (error) {
      console.error("Error fetching PTP overrides:", error);
    }
  };

  const handlerSelectTable = (data) => {
    setCurrentTable(data);
  };

  useEffect(() => {
    getClients();
    getListOfPtpOverrides();
    getListOfPtpDiscountOverrides();
  }, []);

  useEffect(() => {
    if (clients.length) {
      setConfigList((prevConfig) => [
        ...prevConfig,
        ...clients.map((item) => item.name),
      ]);
    }
  }, [clients]);

  useEffect(() => {
    if (currentTable === configList[0] || currentTable === configList[1]) {
      setParams({});
    } else {
      setParams(getClientById(currentTable, clients));
    }
  }, [currentTable, clients]);

  const showTable = (tabIndex) => {
    switch (tabIndex) {
      case configList[0]:
        return <AgentSummary params={params} />;
      case configList[1]:
        return <AgentLogoutBreaks params={params} />;
      default:
        return <CampaignSummary params={params} />;
    }
  };

  const openPtpOverride = (ptp_override_type) => {
    setModal({
      Component: <PtpOverrideRequests params={ptp_override_type} />,
      size: "sml",
    });
  };

  const selectTime = (time) => {
    const from = format(time?.from, "yyyy-MM-dd");
    const to = format(time?.to, "yyyy-MM-dd");
    const newParams = {
      ...paramLogout,
      filter: [
        {
          column_name: "date_time",
          search_term: { from: `${from}`, to },
        },
      ],
    };
    setParamLogout(newParams);
    setParams(newParams);
  };

  // Show loading state
  if (loading) return <div>Loading...</div>;

  return (
    <div>
      <TitleSection>
        <p style={{ marginRight: 10, marginBottom: 0, marginTop: 5 }}>
          Manager Dashboard
        </p>

        {ptpOverrides && (
          <div style={{ marginRight: 10 }}>
            <ButtonComponent
              title={"Ptp Overrides"}
              handler={() => openPtpOverride({ ptp_status_id: 6 })}
              style={{
                '> span': {
                  marginLeft: '60px',
                },
              }}
            />
          </div>
        )}

        {ptpDiscountOverrides && (
          <div>
            <ButtonComponent
              title={"Ptp Discount Overrides"}
              handler={() => openPtpOverride({ ptp_status_id: 7 })}
              style={{
                '> span': {
                  marginLeft: '60px',
                },
              }}
            />
          </div>
        )}
      </TitleSection>

      

      <SectionTable>
        <Section>
          <SwitchComponent
            currentItem={currentTable}
            configList={configList}
            handler={handlerSelectTable}
          />
          <TimeFilter
            disable={handleTimerFilterVisibility(currentTable)}
            handler={selectTime}
          />
        </Section>
        {showTable(currentTable)}
      </SectionTable>
    </div>
  );
}


export default ManagerDashboard;


