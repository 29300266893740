import { useState } from "react";
import { IconButton } from "@mui/material";
import close_icon from "../../../../assets/icons/Group 414.png";
import styles from "./styles.module.css";
import ButtonLoader from "components/buttons/ButtonLoader";
import { AgentService } from "../../../../api/services";
import { useModal } from "providers/hooks/useModal";

function AssignDebtors({ agent_id, debtors, onClose }) {
    const [loading, setLoading] = useState(false);
    const { setToast } = useModal();
    const handleConfirm = async () => {
        setLoading(true);
        const obj = {};
        const debtorIds = debtors.map((item) => item.id);
        debtorIds.forEach((id) => (obj[id] = [agent_id]));
        const resp = await AgentService.assignClients({ agents_clients: obj });
        if (resp.status === "success") {
            setLoading(false);
            onClose("close");
            setToast("Debtor(s) assigned successfully", "success");
        } else {
            setLoading(false);
            setToast("An error occurred", "error");
        }
    };

    const handleClose = () => {
        if (onClose != null) {
            onClose("close");
        }
    };
    const handleCancel = () => {
        if (onClose != null) {
            onClose("cancel");
        }
    };
    return (
        <div className={styles.assignDebtorsContainer}>
            <IconButton
                onClick={handleClose}
                style={{ position: "absolute", right: 4, top: 4 }}
                size="large">
                <img src={close_icon} alt="close" width={20} />
            </IconButton>
            <div>
                <h2>Assign Debtors</h2>
            </div>
            <div>
                <p>You are assigning the following debtors to yourself, please confirm below.</p>
            </div>
            <div>
                <ul>
                    {debtors != null && Array.isArray(debtors) ? (
                        debtors.map((debtor) => <li key={debtor.account_number}>{debtor.debtor_name}</li>)
                    ) : (
                        <li>
                            <i>No debtors selected.</i>
                        </li>
                    )}
                </ul>
            </div>
            <div>
                <ButtonLoader
                    title={"Confirm"}
                    handler={handleConfirm}
                    variant={"outlined"}
                    loading={loading}
                    width={200}
                    height={50}
                />
            </div>
        </div>
    );
}

export default AssignDebtors;
