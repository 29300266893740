import { MaterialTableCurrencySettings } from "constants/locale";
import React, { useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { ClientService } from "../../../api/services";
import { Section, TitleSection } from "../../../common/Layouts";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import { useAuth } from "providers/hooks/useAuth";
import { reject } from "lodash";
import DebtorPortalService from "api/services/debtorPortalService";
import WelcomeScreen from "../WelcomeScreen";
import { useModal } from "providers/hooks/useModal";

function ClientRegister() {
    const history = useHistory();
    const { pathname } = useLocation();
    const { user_type_id, first_log_in } = useAuth();
    const [client, setClient] = React.useState(false);
    const { setModal, unsetModal, setToast } = useModal();

    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                if (client) {
                    DebtorPortalService.debtorCaseRegistry().then((response) => {
                        resolve({
                            status: response.status,
                            data: response.data.debtor_cases,
                            count: response.data.count,
                            pagination: response.pagination,
                        });
                    });
                } else {
                    ClientService.getClientManager(params).then((response) => {
                        resolve({
                            status: response.status,
                            data: response.data?.clients,
                            count: response.data?.count,
                            pagination: response.pagination,
                        });
                    });
                }
            }),
        initParams: {},
    });

    const handleRowClick = (event, row) => {
        if (row != null) {
            const location = {
                pathname: row.name ? `${pathname}/${row.name}` : `${pathname}/${row.account_type}`,
                state: {
                    client_id: row.id,
                    client_name: row.name,
                },
            };
            history.push(location);
        }
    };

    useEffect(() => {
        user_type_id === 5 ? setClient(true) : setClient(false);

        if (first_log_in) {
            if (user_type_id === 5) {
                setModal({
                    Component: <WelcomeScreen unsetModal={unsetModal} setToast={setToast} />,
                    size: "sml",
                });
            }
        }
    }, []);

    return (
        <div>
            <TitleSection>Client Register</TitleSection>
            <Section>
                <div style={{ width: "100%" }}>
                    {!client && (
                        <CustomMaterialTable
                            tableRef={tableRef}
                            title={null}
                            columns={[
                                { title: "Client Name", field: "name", align: "left" },
                                {
                                    title: "Capital",
                                    field: "capital",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Interest",
                                    field: "interest",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Fees",
                                    field: "fees",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Legal Fees",
                                    field: "legal_fees",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Settlement",
                                    field: "settlement",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Total Client Register",
                                    field: "debtors",
                                    type: "numeric",
                                    align: "left",
                                },
                            ]}
                            data={remoteDataSource}
                            onRowClick={handleRowClick}
                        />
                    )}
                    {client && (
                        <CustomMaterialTable
                            tableRef={tableRef}
                            title={null}
                            columns={[
                                { title: "Account Type", field: "account_type", align: "left" },
                                {
                                    title: "Account No",
                                    field: "account_number",
                                    type: "numeric",
                                    align: "left",
                                },
                                {
                                    title: "Reference No",
                                    field: "reference_no",
                                    type: "numeric",
                                    align: "left",
                                },
                                {
                                    title: "Capital",
                                    field: "capital",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Interest",
                                    field: "interest",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Fees",
                                    field: "fees",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Legal Fees",
                                    field: "legal_fees",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                                {
                                    title: "Settlement",
                                    field: "settlement",
                                    type: "currency",
                                    align: "left",
                                    currencySetting: MaterialTableCurrencySettings,
                                },
                            ]}
                            data={remoteDataSource}
                            onRowClick={handleRowClick}
                        />
                    )}
                </div>
            </Section>
        </div>
    );
}

export default ClientRegister;
