import React from 'react';
import Button from '@mui/material/Button';
import { colors } from '../constants/styles'; // Adjust the path as needed

const ButtonComponent = ({
  title,
  handler = null,
  type = '',
  disable = false,
  width = '150px',
  color = null,
  height = 'auto',
  fontSize = null,
  small_size = '1vw',
  variant = 'contained',
  style = null,
}) => {
  const handlerClick = () => {
    if (handler) {
      handler();
    }
  };

  return (
    <Button
      type={type}
      disabled={disable}
      onClick={handlerClick}
      variant={variant}
      sx={{
        borderRadius: '36px',
        backgroundColor: variant === 'outlined' ? 'transparent' : colors.blue,
        color: variant === 'outlined' ? color : colors.white,
        textTransform: 'none',
        height: height === 'auto' ? height : `${height - height / 5}px`,
        fontSize: fontSize || small_size,
        width: width === 'auto' ? width : `${width - width / 5}px`,
        '@media (min-width:1920px)': {
          height: height,
          fontSize: fontSize,
          width: width,
        },
        '&:hover': {
          backgroundColor: variant === 'outlined' ? colors.blue : colors.lightGray,
          color: variant === 'outlined' ? colors.white : colors.blue,
        },
      }}
      style={style}
    >
      {title}
    </Button>
  );
};

export default ButtonComponent;
