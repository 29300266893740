import { Grid } from "@mui/material";
import React, { useState } from "react";
import send_icon from "../../assets/icons/FontAwsome (external-link-alt).png";
import phone_icon from "../../assets/icons/FontAwsome (phone-alt)-1.png";
import phone_icon_active from "../../assets/icons/FontAwsome (phone-alt).png";
import play_icon from "../../assets/icons/FontAwsome (play-circle).png";
import arrow_icon_bold from "../../assets/icons/Group 132.png";
import email_icon_active from "../../assets/icons/Layer -17.png";
import email_icon from "../../assets/icons/Layer -18.png";
import mobile_icon_active from "../../assets/icons/Layer -19.png";
import mobile_icon from "../../assets/icons/Layer -20.png";
import arrow_icon_left from "../../assets/icons/Layer -23.png";
import arrow_icon_right from "../../assets/icons/Layer -24.png";
import ModalComponent from "../../components/ModalComponent";
import style from "./Debtor.module.css";
import CallsTable from "./tables/CallsTable";
import EmailsTable from "./tables/EmailsTable";
import MessagesTable from "./tables/MessagesTable";

const mockData = {
	date: "15 JAN 2021",
	time: "13:45",
	description: "Telephone Call Out",
	" ": (
		<span className={style.call_icon_section}>
			<img src={send_icon} alt='' />
			<img src={play_icon} alt='' />
		</span>
	),
	"duration (min)": "3:25",
};
const mockDataEmail = {
	date: "15 JAN 2021",
	time: "13:45",
	sender: "robson@collectiq.co.za",
	subject: "RE: Payment Plan Agreement",
	" ": (
		<span className={style.call_icon_section_arrow}>
			<img src={arrow_icon_left} alt='' />
			<img src={arrow_icon_bold} alt='' />
			<img src={arrow_icon_right} alt='' />
		</span>
	),
};
const mockDataMobile = {
	date: "15 JAN 2021",
	time: "13:45",
	sender: "robson@collectiq.co.za",
	recipient: "078 874 5342",
	subject: "RE: Payment Plan Agreement",
};

const switchList = [
	{ type: "phone", icon: phone_icon, active_icon: phone_icon_active },
	{ type: "email", icon: email_icon, active_icon: email_icon_active },
	{ type: "mobile", icon: mobile_icon, active_icon: mobile_icon_active },
];

const listBtns = ["ptp", "skip trace", "debtor query", "diarise"];

const SwitcherTypesContact = ({ handler, activeType }) => {
	return (
		<div className={style.wrapper_switch}>
			<div className={style.swith_type_contact}>
				{switchList.map((item) => (
					<div
						className={
							activeType === item.type ? style.switch_active : ""
						}
						onClick={() => handler(item.type)}
						key={item.type}
					>
						<img
							src={
								activeType !== item.type
									? item.icon
									: item.active_icon
							}
							alt=''
						/>
					</div>
				))}
			</div>
		</div>
	);
};

function Contacts({ account_no }) {
	let listDetail = new Array(5).fill(1).map((item) => mockData);
	let listDetailEmail = new Array(5).fill(1).map((item) => mockDataEmail);
	let listDetailMobile = new Array(5).fill(1).map((item) => mockDataMobile);
	const [typeContact, setTypeContact] = useState(switchList[0].type);
	const [openModal, setOpenModal] = useState(false);

	const handlerSwitch = (type) => {
		setTypeContact(type);
	};

	return (
		<Grid container direction='column'>
			<ModalComponent
				open={openModal}
				handleClose={() => setOpenModal(false)}
			/>
			<Grid container direction='row' className={style.marks_row}>
				<Grid item md={6}></Grid>
				<Grid item md={6}>
					{/* <Grid container justifyContent='center'>
						{listBtns.map((item) => (
							<ButtonBack
								small_size={12}
								height={34}
								isCustom
								customStyle={{
									textTransform: "uppercase",
									padding: "0px 15px",
									marginRight: "15px",
									fontFamily: "Avenir Next Bold",
								}}
								fontSize={15}
							>
								<span
									className={"mark_plus"}
									style={{ marginBottom: 0 }}
								>
									<span className='inner_mark'>+</span>
								</span>
								{item}
							</ButtonBack>
						))}
					</Grid> */}
				</Grid>
			</Grid>
			<Grid container spacing={1}>
				<Grid item md={2}>
					<SwitcherTypesContact
						activeType={typeContact}
						handler={handlerSwitch}
					/>
				</Grid>
				<Grid item md={10} justifyContent='center'>
					{typeContact === switchList[0].type && (
						<CallsTable account_no={account_no} />
					)}
					{typeContact === switchList[1].type && (
						<EmailsTable account_no={account_no} />
					)}
					{typeContact === switchList[2].type && (
						<MessagesTable account_no={account_no} />
					)}
				</Grid>
			</Grid>
		</Grid>
	);
}

export default Contacts;
