import {
    Box,
    Button,
    Chip,
    Divider,
    Grid,
    IconButton,
    InputAdornment,
    TextField,
    TextareaAutosize,
    Typography,
} from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import styles from "./styles.module.css";
import { colors } from "constants/styles";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import moment from "moment";
import { AttachFile, AttachFileRounded, Send, SendRounded, SendTwoTone } from "@mui/icons-material";
import TextArea from "components/inputs/TextArea";
import attach_icon from "../../../assets/icons/Layer -25.png";
import paper_plane_icon from "../../../assets/icons/FontAwsome (paper-plane-white).png";
import { CommsService } from "api/services";
import { Autocomplete } from '@mui/material';
import Select from "components/inputs/Select";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const AttachmentButton = styled.label`
    background-color: ${colors.blue};
    width: 3em;
    height: 3em;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const SendButton = styled.label`
    background-color: ${colors.blue};
    width: 8em;
    height: 3em;
    border-radius: 25px;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    padding: 1em;
`;

const validationSchema = Yup.object().shape({
    send_to: Yup.string().required("Send To is a required field"),
    subject: Yup.string().required("Subject is a required field"),
    body: Yup.string().required("Message is a required field"),
});

const SendEmail = ({ sendTo = null, account }) => {
    const [emailInfo, setEmailInfo] = useState({
        subject: "",
        body: "",
    });
    const { setToast, unsetModal } = useModal();
    const [attachments, setAttachments] = useState([]);
    const [recipientOptions, setRecipientOptions] = useState([]);
    const [recipient, setRecipient] = useState({
        name: "",
        email: "",
        agent_id: 0,
    });

    const [query, setQuery] = useState("");
    const [queriesList, setQueriesList] = useState([
        { name: "Please Select A Query", value: 0 },
        { name: "Request a full statement", value: 1 },
        { name: "Request a call back", value: 2 },
        { name: "Dispute the account", value: 3 },
    ]);
    const [querySelected, setQuerySelected] = useState(false);

    const handleChange = (e) => {
        setEmailInfo({ ...emailInfo, [e.target.name]: e.target.value });
    };

    const querySelect = (e) => {
        const selectedQuery = e.target.value;

        setQuerySelected(true);

        switch (selectedQuery) {
            case "1":
                setQuery(`Request a full statement`);
                break;
            case "2":
                setQuery(`Request a call back`);
                break;
            case "3":
                setQuery(`Dispute the account`);
                break;
            default:
                break;
        }
    };

    const addAttachment = (e) => {
        if (e.target.files) {
            const temp = [...attachments];
            temp.push(e.target.files[0]);
            setAttachments(temp);
        }
    };

    const removeAttachment = (index) => {
        setAttachments(attachments.filter((item, idx) => idx !== index));
    };

    const emailSubject = `${account?.name} ${account?.account_number}`;
    const emailRecipient = recipientOptions[recipientOptions.length - 1];

    const sendEmail = () => {
        const formData = new FormData();
        const obj = {
            agent_id: emailRecipient.agent_id,
            subject: emailSubject,
            body: emailInfo.body.length > 0 ? emailInfo.body : query,
        };
        Object.keys(obj).forEach((key) => {
            formData.append(key, obj[key]);
        });
        formData.append("recipient[email]", recipient.email);
        formData.append("recipient[name]", recipient.name);
        attachments.forEach((att) => {
            formData.append("attachments[]", att);
        });

        CommsService.debtorSendEmailToAgent(formData).then((response) => {
            const resp = { response: response };

            if (response.status === "success") {
                setToast(response.message, "success");
                unsetModal();
            } else {
                setToast(response.message, "error");
                unsetModal();
            }

            if (resp.response.response?.data) {
                switch (resp.response.response.data?.status) {
                    case "success":
                        setToast(resp.response.response.data?.message, "success");
                        unsetModal();
                        break;
                    case "error":
                        setToast(resp.response.response.data?.message, "error");
                    default:
                        break;
                }
            }
        });
    };

    const getDebtorRecipients = async () => {
        const resp = await CommsService.getDebtorRecipients();
        if (resp.status === "success") {
            setRecipientOptions(resp.data.options);
            if (sendTo) {
                const to = resp.data.options.filter((item) => item.email === sendTo);
                if (to && to.length) {
                    setRecipient(to[0]);
                    handleRecipientChange(to[0]);
                }
            }
        }
    };

    const handleRecipientChange = (val) => {
        console.log(val);
        if (val?.email) {
            const obj = {
                email: val.email,
                agent_id: val.agent_id,
                name: val.name,
            };
            setRecipient(obj);
        }
    };

    React.useEffect(() => {
        getDebtorRecipients();
    }, []);

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"1.5em"}>
            <Box sx={{ width: "100%" }}>
                <Box
                    mt={4}
                    mb={4}
                    display={"flex"}
                    alignItems={"center"}
                    justifyContent={"space-between"}
                    width={"100%"}
                >
                    <AttachmentButton onChange={addAttachment}>
                        <input hidden type="file" multiple />
                        <AttachFileRounded />
                    </AttachmentButton>

                    <SendButton onClick={sendEmail}>
                        <img src={paper_plane_icon} />
                        <Typography style={{ marginLeft: "1em" }}>Send</Typography>
                    </SendButton>
                </Box>
                <Box width={"100%"} sx={{ padding: "0 1em" }}>
                    {attachments.map((item, index) => (
                        <Chip label={item.name} onDelete={() => removeAttachment(index)} />
                    ))}
                </Box>
            </Box>

            <Formik
                initialValues={emailInfo}
                enableReinitialize
                validationSchema={validationSchema}
                // onSubmit={handleSaveReportInfo}
            >
                {({ errors }) => (
                    <Form style={{ width: "100%" }}>
                        <Grid container direction="row">
                            <Grid item md={12}>
                                <div className={styles.wrapper_section}>
                                    <Divider />
                                    <TextField
                                        fullWidth
                                        id="send_to"
                                        name="send_to"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={emailRecipient?.name}
                                        error={errors.send_to ? true : false}
                                        helperText={errors.send_to}
                                        onChange={handleChange}
                                        isSearch={false}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: `${colors.blue}`,
                                                        }}
                                                    >
                                                        To:
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                    {/* <Autocomplete
                                        disablePortal
                                        options={recipientOptions}
                                        getOptionLabel={(option) => `${option?.name}`}
                                        onChange={(e, v) => handleRecipientChange(v)}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="To:"
                                                name="send_to"
                                                error={errors.send_to}
                                                InputLabelProps={{
                                                    style: {
                                                        fontWeight: "bold",
                                                        color: `${colors.blue}`,
                                                    },
                                                }}
                                            />
                                        )}
                                    /> */}
                                </div>
                            </Grid>
                            <Grid item md={12} z>
                                <div className={styles.wrapper_section}>
                                    <TextField
                                        fullWidth
                                        id="subject"
                                        name="subject"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={emailSubject}
                                        error={errors.contact_type_id ? true : false}
                                        helperText={errors.contact_type_id}
                                        onChange={handleChange}
                                        isSearch={false}
                                        InputProps={{
                                            startAdornment: (
                                                <InputAdornment position="start">
                                                    <Typography
                                                        style={{
                                                            fontWeight: "bold",
                                                            color: `${colors.blue}`,
                                                        }}
                                                    >
                                                        Subject:
                                                    </Typography>
                                                </InputAdornment>
                                            ),
                                        }}
                                    />
                                </div>
                            </Grid>
                            <Grid item md={12} style={{ margin: "1em 0" }}>
                                <Select
                                    label={"Possible Queries List"}
                                    type="text"
                                    options={queriesList}
                                    onChange={(e) => querySelect(e)}
                                />
                            </Grid>
                            <Grid item md={12}>
                                {!querySelected && (
                                    <div className={styles.wrapper_section}>
                                        <textarea
                                            name="body"
                                            id="body"
                                            onChange={handleChange}
                                            fullWidth
                                            placeholder="Type Message..."
                                            style={{
                                                border: "none",
                                                width: "100%",
                                                minHeight: "20em",
                                                fontFamily: "inherit",
                                                fontSize: "1rem",
                                                padding: ".4em",
                                            }}
                                        ></textarea>
                                    </div>
                                )}
                            </Grid>
                        </Grid>
                    </Form>
                )}
            </Formik>
        </Box>
    );
};

export default SendEmail;
