import axios from "axios";
import { UPDATE_FEES } from "../../constants/reduxConstants";
import { ClientService } from "../../api/services";
import { filterFees, refreshFeesList, setClientDetails, setClientFees, setIsLoadingClients, setListClientsRegister, setNewFees, setUpdateFees, setClientManager } from "../reducers/clientsReducer";

export const getClientsRegister = (params) => async(dispatch) => {
    dispatch(setIsLoadingClients(true)); 
    try {
      let result = await ClientService.register(params);   
       dispatch(setListClientsRegister(result)); 
    } catch (e) {
        console.log(e); 
    }
    dispatch(setIsLoadingClients(false)); 
}; 

export const getClientManager = (params) => async(dispatch) => {
  dispatch(setIsLoadingClients(true)); 
  try {
    let result = await ClientService.client_manager(params);   
     dispatch(setClientManager(result)); 
  } catch (e) {
      console.log(e); 
  }
  dispatch(setIsLoadingClients(false)); 
}; 

export const createClient = (params, redirect) => async(dispatch) => {
    dispatch(setIsLoadingClients(true)); 
    try { 
      let result = await ClientService.create(params); 
      if(result.data?.cleint_id) {
        let data = await ClientService.details(result.data?.cleint_id); 
        dispatch(setClientDetails(data));  
        redirect(result.data?.cleint_id); 
      }
    } catch (e) {
        console.log(e); 
    }
    dispatch(setIsLoadingClients(false)); 
}; 

export const updateClient = (params, id) => async(dispatch) => {
    dispatch(setIsLoadingClients(true)); 
    try {
      let result = await ClientService.update_client(params); 
      if(result?.status === 'success') {
        let data = await ClientService.details(id); 
        dispatch(setClientDetails(data));  
      }
    } catch (e) {
        console.log(e); 
    }
    dispatch(setIsLoadingClients(false)); 
}; 

export const deleteClient = (id, goBack) => async(dispatch) => {
  dispatch(setIsLoadingClients(true)); 
  try {
    let result = await ClientService.actions({client_id: id }, "delete"); 
    if(result?.status === 'success') {
       goBack(); 
    }
  } catch (e) {
      console.log(e); 
  }
  dispatch(setIsLoadingClients(false)); 
}; 


export const getClientFees = (params) => async(dispatch) => {
    dispatch(setIsLoadingClients(true)); 
    try {
      let result = await ClientService.fees(params);  
      dispatch(setClientFees(result));  
    } catch (e) {
        console.log(e); 
    }
    dispatch(setIsLoadingClients(false)); 
}; 

export const createFees = (params) => async(dispatch) => {
  dispatch(setIsLoadingClients(true)); 
  console.log(params)
  try {
     dispatch(setNewFees(params));  
     let result = await ClientService.fees_crud(params, "create");  
    if(result?.status === 'success') {
      let data = await ClientService.fees({client_id: params.client_id});  
      dispatch(setClientFees(data));  
   } 
  } catch (e) {
      console.log(e); 
  }
  dispatch(setIsLoadingClients(false)); 
}; 

export const updateFees = (params) => async(dispatch) => {
  dispatch(setIsLoadingClients(true)); 
  try {
    dispatch(setUpdateFees(params)); 
    let result = await ClientService.fees_crud(params, "update");  
    if(result?.status === 'success') {
      //dispatch(setClientFees(params)); 
      let data = await ClientService.fees({client_id: params.client_id});  
      dispatch(setClientFees(data));  
   }
  } catch (e) {
      console.log(e); 
  }
  dispatch(setIsLoadingClients(false)); 
}; 

export const deleteFees = (id, client_id) => async(dispatch) => {
  dispatch(setIsLoadingClients(true)); 
  try {
    dispatch(filterFees(id)); 
    let result = await ClientService.fees_crud({"id": id }, "delete");  
    if(result?.status === 'success') {
   
       //let data = await ClientService.fees({client_id: client_id});  
      //dispatch(setClientFees(data));   
   }
  } catch (e) {
      console.log(e); 
  }
  dispatch(setIsLoadingClients(false)); 
}; 

export const getClientDetails = (id) => async(dispatch) => {
    dispatch(setIsLoadingClients(true)); 
    try {
      let result = await ClientService.details(id); 
      dispatch(setClientDetails(result));  
    } catch (e) {
        console.log(e); 
    }
    dispatch(setIsLoadingClients(false)); 
}; 

export const deleteClientDetails = () => async(dispatch) => {
  dispatch(setClientDetails(null)); 
}; 

export const refreshFees = () => async(dispatch) => {
  dispatch(refreshFeesList(null)); 
}; 

