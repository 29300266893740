import { Grid, IconButton, MenuItem, Paper, TextField, Typography } from "@mui/material";

import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import DebtorCasePtpService from "api/services/debtorCasePtpService";
import ConfirmAction from "components/modal/ConfirmAction";
import { format } from "date-fns";
import { useFormik } from "formik";
import { useModal } from "providers/hooks/useModal";
import * as Yup from "yup";
import close_icon from "../../../../assets/icons/Group 414.png";
import BluePillButton from "../../../../components/buttons/BluePillButton";
import styles from "./styles.module.css";
import { usePtpOverride } from "../../../../providers/hooks/usePtpOverride";
import { useEffect, useState, useCallback } from "react";

const textFieldParams = {
    variant: "outlined",
};

const selectParams = {
    select: true,
    SelectProps: {
        // @MM: disabling the portal fixes the issue where selecting causes the modal to close
        MenuProps: { disablePortal: true },
    },
};

const schema = {
    due_date: Yup.date().required("Payment date is required."),
    start_date: Yup.date().required("Payment start date is required."),
    amount: Yup.number().required("Arrangement amount is required."),
    period: Yup.string().required("Payment period is required."),
    frequency_id: Yup.number().required("Select a frequency."),
};

const CustomTextField = ({ touched, value, error, ...props }) => (
    <TextField
        value={value}
        error={touched && error}
        helperText={touched && error}
        fullWidth
        {...textFieldParams}
        {...props}
    />
);

const Base = ({ dispatch, children }) => {
    const handleClose = () => {
        if (dispatch instanceof Function) {
            dispatch({ type: "close" });
        }
    };
    return (
        <Paper className={styles.container}>
            <IconButton onClick={handleClose} className={styles.close} size="large">
                <img src={close_icon} alt="close" width="25px" />
            </IconButton>
            {children}
        </Paper>
    );
};

const submitValues = async (values, account_no, type, statusId) => {
    const start_date = values.start_date && { start_date: format(values.start_date, "yyyy-MM-dd") };

    const res = await DebtorCasePtpService.create({
        account_no,
        type,
        status: statusId ? statusId : 1,
        ...values,
        ...start_date,
        due_date: format(values.due_date, "yyyy-MM-dd"),
    });

    return res;
};

const PaymentWithDiscount = ({ title, type, dispatch, account_no, ptp_minimum }) => {
    const { setToast } = useModal();
    const { handlePtpDiscountOverride } = usePtpOverride(ptp_minimum);

    const handlePreSubmit = (e) => {
        handlePtpDiscountOverride(values, account_no, type);
        e.preventDefault();
    };

    const { values, errors, touched, handleChange, setFieldValue } = useFormik({
        onSubmit: async (values) => {
            try {
                const res = await submitValues(values, account_no, type);
                if (res.status == "success") {
                    setToast(res.message, "success");
                    dispatch({ type: "save" });
                    return;
                }
                throw res.message;
            } catch (e) {
                setToast(e, "error");
                return e;
            }
        },
        validationSchema: Yup.object().shape({
            due_date: schema.due_date,
            amount: schema.amount,
            // discount: schema.discount,
        }),
        initialValues: {
            due_date: new Date(),
            amount: "0",
            // discount: "0.0"
        },
    });

    return (
        <Base dispatch={dispatch}>
            <form noValidate onSubmit={handlePreSubmit}>
                <Grid container spacing={2} direction="column">
                    <Grid item sm={12}>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="none"
                            id="date-picker-inline"
                            label="Payment Date"
                            value={values?.due_date}
                            onChange={(date) => setFieldValue("due_date", date ?? new Date())}
                            error={touched.due_date && errors.due_date}
                            helperText={(touched.due_date && errors.due_date) || "DD/MM/YYYY"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            fullWidth
                            label="Arrangement Amount"
                            name="amount"
                            value={values?.amount}
                            error={errors.amount}
                            touched={touched.amount}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            fullWidth
                            label="Discount"
                            name="discount"
                            value={values?.discount}
                            error={errors.discount}
                            touched={touched.discount}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <BluePillButton variant="contained" type="submit" fullWidth>
                            Save
                        </BluePillButton>
                    </Grid>
                </Grid>
            </form>
        </Base>
    );
};

const Payment = ({ title, type, dispatch, account_no, account_settlement, ptp_minimum }) => {
    const { setToast } = useModal();
    const { openConfirmPtpOverrideModal, checkPtpAllowedAmount } = usePtpOverride(ptp_minimum);

    const handlePreSubmit = (e) => {
        if (!checkPtpAllowedAmount(values.amount, account_settlement, ptp_minimum, 0)) {
            openConfirmPtpOverrideModal(values, account_no, type);
            return;
        }

        handleSubmit(values);
        e.preventDefault();
    };

    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
        onSubmit: async (values) => {
            try {
                const res = await submitValues(values, account_no, type);

                if (res.status == "success") {
                    setToast(res.message, "success");
                    dispatch({ type: "save" });
                    return;
                }
                throw res.message;
            } catch (e) {
                setToast(e, "error");
                return e;
            }
        },
        validationSchema: Yup.object().shape({
            due_date: schema.due_date,
            amount: schema.amount,
        }),
        initialValues: {
            due_date: new Date(),
            amount: "",
        },
    });

    return (
        <Base dispatch={dispatch}>
            <form noValidate onSubmit={handlePreSubmit}>
                <Grid container spacing={2} direction="column">
                    <Grid item sm={12}>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="none"
                            id="date-picker-inline"
                            label="Payment Date"
                            value={values?.due_date}
                            onChange={(date) => setFieldValue("due_date", date ?? new Date())}
                            error={touched.due_date && errors.due_date}
                            helperText={(touched.due_date && errors.due_date) || "DD/MM/YYYY"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            fullWidth
                            label="Arrangement Amount"
                            name="amount"
                            value={values?.amount}
                            error={errors.amount}
                            touched={touched.amount}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <BluePillButton variant="contained" type="submit" fullWidth>
                            Save
                        </BluePillButton>
                    </Grid>
                </Grid>
            </form>
        </Base>
    );
};

const Instalment = ({ title, type, dispatch, account_no, account_settlement, frequencies, periods, ptp_minimum }) => {
    const { setToast } = useModal();
    const { openConfirmPtpOverrideModal, checkPtpAllowedAmount } = usePtpOverride(ptp_minimum);

    const handlePreSubmit = (e) => {
        if (!checkPtpAllowedAmount(values.amount, account_settlement, ptp_minimum, 0)) {
            openConfirmPtpOverrideModal(values, account_no, type);
            return;
        }

        handleSubmit(values);
        e.preventDefault();
    };

    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
        onSubmit: async (values) => {
            try {
                const res = await submitValues(values, account_no, type);

                if (res.status == "success") {
                    setToast(res.message, "success");
                    dispatch({ type: "save" });
                    return;
                }
                throw res.message;
            } catch (e) {
                setToast(e, "error");
                return e;
            }
        },
        validationSchema: Yup.object().shape({
            due_date: schema.due_date,
            amount: schema.amount,
            period: schema.period,
            frequency_id: schema.frequency_id,
        }),
        initialValues: {
            due_date: new Date(),
            amount: "",
            period: "",
            frequency_id: "",
        },
    });
    return (
        <Base dispatch={dispatch}>
            <form noValidate onSubmit={handlePreSubmit}>
                <Grid container spacing={2} direction="column">
                    <Grid item sm={12}>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>

                    <Grid item>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="none"
                            id="date-picker-inline"
                            label="Payment Date"
                            value={values?.due_date}
                            onChange={(date) => setFieldValue("due_date", date ?? new Date())}
                            error={touched.due_date && errors.due_date}
                            helperText={(touched.due_date && errors.due_date) || "DD/MM/YYYY"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            label="Arrangement Amount"
                            name="amount"
                            value={values?.amount}
                            error={errors.amount}
                            touched={touched.amount}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            {...selectParams}
                            label="Payment Period"
                            name="period"
                            value={values?.period}
                            error={errors.period}
                            touched={touched.period}
                            onChange={handleChange}
                        >
                            {Array.isArray(periods) &&
                                periods.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </CustomTextField>{" "}
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            {...selectParams}
                            label="Frequency"
                            name="frequency_id"
                            value={values?.frequency_id}
                            error={errors.frequency_id}
                            touched={touched.frequency_id}
                            onChange={handleChange}
                        >
                            {Array.isArray(frequencies) &&
                                frequencies.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </CustomTextField>
                    </Grid>
                    <Grid item>
                        <BluePillButton fullWidth variant="contained" type="submit">
                            Save
                        </BluePillButton>
                    </Grid>
                </Grid>
            </form>
        </Base>
    );
};

const Combo = ({ title, type, dispatch, account_no, account_settlement, frequencies, periods, ptp_minimum }) => {
    const { setToast } = useModal();
    const { openConfirmPtpOverrideModal, checkPtpAllowedAmount } = usePtpOverride(ptp_minimum);

    const handlePreSubmit = (e) => {
        if (!checkPtpAllowedAmount(values.amount, account_settlement, ptp_minimum, 0)) {
            openConfirmPtpOverrideModal(values, account_no, type);
            return;
        }

        handleSubmit(values);
        e.preventDefault();
    };

    const { values, errors, touched, handleChange, handleSubmit, setFieldValue } = useFormik({
        onSubmit: async (values) => {
            try {
                const res = await submitValues(values, account_no, type);
                if (res.status == "success") {
                    setToast(res.message, "success");
                    dispatch({ type: "save" });
                    return;
                }
                throw res.message;
            } catch (e) {
                setToast(e, "error");
                return e;
            }
        },
        validationSchema: Yup.object().shape({
            due_date: schema.due_date,
            amount: schema.amount,
            start_date: schema.start_date,
            period: schema.period,
            frequency_id: schema.frequency_id,
        }),
        initialValues: {
            due_date: new Date(),
            start_date: new Date(),
            amount: "",
            period: "",
            frequency_id: "",
        },
    });
    return (
        <Base dispatch={dispatch}>
            <form noValidate onSubmit={handlePreSubmit}>
                <Grid container spacing={2} direction="column">
                    <Grid item sm={12}>
                        <Typography variant="h3">{title}</Typography>
                    </Grid>
                    <Grid item>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="none"
                            id="date-picker-inline"
                            label="Payment Date"
                            value={values?.due_date}
                            onChange={(date) => setFieldValue("due_date", date ?? new Date())}
                            error={touched.due_date && errors.due_date}
                            helperText={(touched.due_date && errors.due_date) || "DD/MM/YYYY"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            fullWidth
                            label="Arrangement Amount"
                            name="amount"
                            value={values?.amount}
                            error={errors.amount}
                            touched={touched.amount}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <DatePicker
                            disableToolbar
                            variant="inline"
                            inputVariant="outlined"
                            format="dd/MM/yyyy"
                            margin="none"
                            id="date-picker-inline"
                            label="Payment Start Date"
                            value={values?.start_date}
                            onChange={(date) => setFieldValue("start_date", date ?? new Date())}
                            error={touched.start_date && errors.start_date}
                            helperText={(touched.start_date && errors.start_date) || "DD/MM/YYYY"}
                            fullWidth
                        />
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            fullWidth
                            {...selectParams}
                            label="Payment Period"
                            name="period"
                            value={values?.period}
                            error={errors.period}
                            touched={touched.period}
                            onChange={handleChange}
                        >
                            {Array.isArray(periods) &&
                                periods.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </CustomTextField>
                    </Grid>
                    <Grid item>
                        <CustomTextField
                            fullWidth
                            {...selectParams}
                            label="Frequency"
                            name="frequency_id"
                            value={values?.frequency_id}
                            error={errors.frequency_id}
                            touched={touched.frequency_id}
                            onChange={handleChange}
                        >
                            {Array.isArray(frequencies) &&
                                frequencies.map((item) => (
                                    <MenuItem key={item.id} value={item.id}>
                                        {item.name}
                                    </MenuItem>
                                ))}
                        </CustomTextField>
                    </Grid>
                    <Grid item>
                        <BluePillButton fullWidth variant="contained" type="submit">
                            Save
                        </BluePillButton>
                    </Grid>
                </Grid>
            </form>
        </Base>
    );
};

const PtpSubmit = {
    OnceOff: ({ dispatch, account_no, account_settlement, ptp_minimum }) => (
        <Payment
            title="Once Off"
            type={1}
            dispatch={dispatch}
            account_settlement={account_settlement}
            ptp_minimum={ptp_minimum}
            account_no={account_no}
        />
    ),
    InstalmentPlan: ({ dispatch, account_no, frequencies, periods, account_settlement, ptp_minimum }) => (
        <Instalment
            title="Instalment Plan"
            type={2}
            dispatch={dispatch}
            account_no={account_no}
            account_settlement={account_settlement}
            ptp_minimum={ptp_minimum}
            frequencies={frequencies}
            periods={periods}
        />
    ),
    OnceOffInstalment: ({ dispatch, account_no, frequencies, periods, account_settlement, ptp_minimum }) => (
        <Combo
            title="Once off Instalment"
            type={3}
            dispatch={dispatch}
            account_no={account_no}
            account_settlement={account_settlement}
            ptp_minimum={ptp_minimum}
            frequencies={frequencies}
            periods={periods}
        />
    ),
    SpecialInstalment: ({ dispatch, account_no, frequencies, periods, account_settlement, ptp_minimum }) => (
        <Instalment
            title="Special Instalment"
            type={4}
            dispatch={dispatch}
            account_no={account_no}
            account_settlement={account_settlement}
            ptp_minimum={ptp_minimum}
            frequencies={frequencies}
            periods={periods}
        />
    ),
    FullFinalSettlement: ({ dispatch, account_no, account_settlement, ptp_minimum }) => (
        <Payment
            title="Full + Final Settlement"
            type={5}
            dispatch={dispatch}
            account_settlement={account_settlement}
            ptp_minimum={ptp_minimum}
            account_no={account_no}
        />
    ),
    ShortSettlement: ({ dispatch, account_no, account_settlement, ptp_minimum }) => (
        <PaymentWithDiscount
            title="Short Settlement"
            type={6}
            dispatch={dispatch}
            ptp_minimum={ptp_minimum}
            account_no={account_no}
        />
    ),
    AttorneyClientSettlement: ({ dispatch, account_no, account_settlement, ptp_minimum }) => (
        <Payment
            title="Attorney Client Settlement"
            type={7}
            dispatch={dispatch}
            account_no={account_no}
            account_settlement={account_settlement}
            ptp_minimum={ptp_minimum}
        />
    ),
};

export default PtpSubmit;
