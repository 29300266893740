// import React, {useEffect, useState} from 'react'
// import { useHistory, useLocation } from 'react-router-dom';
// import { Section, TitleSection } from '../../common/Layouts'
// import TableComponent from '../../components/TableComponent'
// import TableUI from '../../components/TableUI';
// import { colors } from '../../constants/styles';
// import { getClientManager} from '../../redux/actions/clientsAction';
// import { useDispatch, useSelector } from 'react-redux';
// import { ClientService } from '../../api/services';

// const mockData = {
//   "client_name": "Absa Group Limited",
//   "contact_name": "Dumasani Xulu",
//   "contact_number": "000 000 0000",
//   "contact_email": 'email@example.com',
//   "": " "
// };

// function Administration() {
//   //let listDetail = new Array(8).fill(1).map(item => mockData);
//   const history = useHistory();
//   const {pathname} = useLocation();
//   const dispatch = useDispatch();
//   const [params, setParams] = useState({});
//   let client_manager = useSelector(state => state.clientsReducer?.client_manager);
//   const isLoading = useSelector(state => state.clientsReducer?.isLoading);
//   const [listDetail, setListDetail] = useState([]);
//   const [listId, setListId] = useState(null);
//   const [typeSort, setTypeSort] = useState('DESC');

//   // useEffect(() => {
//   //   if(!client_manager) {
//   //     dispatch(getClientManager());
//   //   }
//   // });

//   // useEffect(() => {
//   //   if(client_manager) {
//   //     dispatch(getClientManager());
//   //   }
//   // }, [window.location.pathname]);

//   // useEffect(() => {
//   //   let result;

//   //   if(client_manager?.data) {
//   //     let listId = {};
//   //     if(client_manager?.data?.clients?.length > 0) {
//   //       result = client_manager?.data?.clients?.map((el, idx) => {
//   //       listId[idx] = el.id;
//   //        return  {
//   //         "client_name": el.name,
//   //         "contact_name": el.contact_name,
//   //         "contact_number": el.contact_number,
//   //         "contact_email": el.contact_email,
//   //         "": " "
//   //       }})
//   //       setListId(listId);
//   //     } else {
//   //       result = [{
//   //         "client_name": "",
//   //         "contact_name": "",
//   //         "contact_number": "",
//   //         "contact_email": "",
//   //         "": " "
//   //       }]
//   //     }
//   //   }
//   //   setListDetail(result);
//   //  }, [client_manager?.data]);

//   const sendFilterRequest = async (value) => {
//     let newParams = {...params, "filter": [{
//       "column_name": "client_name",
//       "search_term": `${value}`
//     }]};
//     setParams(newParams);

//     if(!isLoading ) {
//       dispatch(getClientManager(newParams));
//     }
//   }

//   const sendSortRequest = (value) => {
//       let newParams = {...params,  "sort": {
//         "column_name": `${value}`,
//         "order": `${typeSort}`
//       }};
//       setParams(newParams);
//       setTypeSort(typeSort === "ASC" ? "DESC" : "ASC");
//       if(!isLoading && client_manager?.data?.clients?.length > 0) {
//         dispatch(getClientManager(newParams));
//       }

//   }

//   const selectRow = (data) => {
//    let id = listId[data.idx];
//    history.push(`${pathname}/:${id}`);
//   }

//   const addNewClient = () => {
//     history.push(`${pathname}/new_client`);
//   }

//   return (
//     <>
//       <TitleSection>
//         Client Manager
//       </TitleSection>
//       <Section customStyle={{height: '100%'}}>
//        {/* {listDetail?.length ? <TableUI
//           isAdministration
//           title_btn={"ADD"}
//           isCustom
//           isFilter
//           isClientManager
//           handleSort={sendSortRequest}
//           handleSearch={sendFilterRequest}
//           handlerClick={addNewClient}
//           handlerSelectRow={selectRow}
//           color_btn={colors.blue}
//           data={listDetail}
//         /> : ""} */}
//       </Section>
//     </>
//   )
// }

// export default Administration

import React, { useEffect } from "react";
import { Section, TitleSection } from "../../common/Layouts";
import { useHistory, useLocation } from "react-router-dom";
import TableComponent from "../../components/TableComponent";
import { colors } from "../../constants/styles";
import { ClientService, UserService } from "../../api/services";
import TableUI from "../../components/TableUI";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import { dateFormat, timeFormat } from "../../utils/dateFormat";
import edit_icon from "../../assets/icons/FontAwsome (edit).png";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { useAuth } from "../../providers/hooks/useAuth";
import ButtonComponent from "../../components/ButtonComponent";
import { useModal } from "../../providers/hooks/useModal";
import ConfirmAction from "../../components/modal/ConfirmAction";
import { Edit } from "@mui/icons-material";
import { Paper } from "@mui/material";
import { MaterialTableCurrencySettings } from "../../constants/locale";

const mockData = {
    user_type: "Manager",
    agents_name: "Dumasani Xulu",
    manager: "Richard Andrews",
    contact_number: "000 000 0000",
    "": " ",
};

function Administration() {
    const { hasPermission } = useAuth();
    const { setModal, unsetModal, setToast } = useModal();
    let listDetail = new Array(8).fill(1).map((item) => mockData);

    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                ClientService.getClientManager(params).then((response) => {
                    resolve({
                        status: response.status,
                        data: response.data.clients,
                        count: response.data.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: {},
    });

    const history = useHistory();

    const { pathname } = useLocation();

    const addNewClient = () => {
        history.push(`${pathname}/new_client`);
    };

    const editClient = (id, row) => {
        history.push(`${pathname}/${id}`);
    };

    const deleteClient = async (id) => {
        unsetModal();
        const resp = await ClientService.delete({ client_id: id });
        if (resp.status === "success") {
            setToast("Client deleted successfully", "success");
            setParams({});
        }
    };

    const openConfirmDeleteModal = (id, name) => {
        setModal({
            Component: (
                <ConfirmAction
                    onConfirm={() => deleteClient(id)}
                    message={`Are you sure you want to delete client ${name}`}
                />
            ),
            size: "sml",
        });
    };

    return (
        <>
            <TitleSection>Client Manager</TitleSection>
            <div style={{ height: "100%" }}>
                <div className={"p-2"} style={{ backgroundColor: "#fff", borderRadius: 20 }}>
                    <CustomMaterialTable
                        title={null}
                        tableRef={tableRef}
                        columns={[
                            { title: "Name", field: "name" },
                            {
                                title: "Capital",
                                field: "capital",
                                type: "currency",
                                currencySetting: MaterialTableCurrencySettings,
                            },
                            {
                                title: "Interest",
                                field: "interest",
                                type: "currency",
                                currencySetting: MaterialTableCurrencySettings,
                            },
                            {
                                title: "Fees",
                                field: "fees",
                                type: "currency",
                                currencySetting: MaterialTableCurrencySettings,
                            },
                            {
                                title: "Legal Fees",
                                field: "legal_fees",
                                type: "currency",
                                currencySetting: MaterialTableCurrencySettings,
                            },
                            {
                                title: "Debtors",
                                field: "debtors",
                                type: "currency",
                                currencySetting: MaterialTableCurrencySettings,
                            },
                            {
                                title: "Settlement",
                                field: "settlement",
                                type: "currency",
                                currencySetting: MaterialTableCurrencySettings,
                            },
                        ]}
                        data={remoteDataSource}
                        actions={[
                            {
                                icon: () => <Edit style={{ color: "#ec7346" }} />,
                                onClick: (e, row) => editClient(row.id, row),
                                render: hasPermission("update-client"),
                            },
                            {
                                icon: (props) => <ButtonComponent title={"+ Add Client"}></ButtonComponent>,
                                position: "toolbar",
                                onClick: addNewClient,
                                render: hasPermission("create-client"),
                            },
                        ]}
                        options={{
                            actionsColumnIndex: 7,
                            pageSize: 10,
                            search: true,
                        }}
                        localization={{
                            header: {
                                actions: "",
                            },
                        }}
                        components={{
                            Container: (props) => <Paper {...props} elevation={0} />,
                        }}
                    />
                </div>

                {/* <TableComponent
          isAdministration
          title_btn={"ADD"}
          isCustom
          isFilterOne
          isClientManager
          handlerSelectRow={()=>{}}
          handlerClick={addNewUser}
          color_btn={colors.blue}
          excludeTableCols={['id']}
          service={UserService.getUserList().data}
        />
        <TableUI
          isAdministration
          title_btn={"ADD"}
          isCustom
          isFilterOne
          isClientManager
          handlerSelectRow={()=>{}}
          handlerClick={addNewUser}
          color_btn={colors.blue}
          data={listDetail}
          excludeTableCols={['id']}
        /> */}
            </div>
        </>
    );
}

export default Administration;
