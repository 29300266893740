import { Box, CircularProgress, FormGroup, IconButton, TextField, Tooltip } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import * as Yup from "yup";
import { HelpOutlineOutlined } from "@mui/icons-material";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    court_name: Yup.string().required("Court name is a required field"),
    instruction_type: Yup.string().required("instruction type is a required field"),
    date_summons_requested: Yup.date().required("Date summons requested is a required field"),
});


const SendSummonsRequested = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        court_name: "",
        instruction_type: "",
        date_summons_requested: ""
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendSummonsRequested = () => {
        console.log('we are here');
        setLoading(true);

        LssmService.sendSummonsRequested({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Summons were requested from court">
                    <Title>
                        Summons Requested
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendSummonsRequested}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            <Box mb={2}  display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={styles.title}>Summons Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="date_summons_requested"
                                        name="date_summons_requested"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.date_summons_requested}
                                        error={errors.date_summons_requested ? true : false}
                                        helperText={errors.date_summons_requested}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>
                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Instruction Type</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.instruction_type ? true : false}
                                                id="instruction_type"
                                                name="instruction_type"
                                                helperText={errors.instruction_type}
                                                className={"select-input-e4"}
                                                value={data.instruction_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Instruction Type</option>
                                                <option value="B">Balance</option>
                                                <option value="W">Warrant</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>
                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Court Name</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.court_name ? true : false}
                                                id="court_name"
                                                name="court_name"
                                                helperText={errors.court_name}
                                                className={"select-input-e4"}
                                                value={data.court_name}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Court Name</option>
                                                <option value="ECBHC">ECBHC (Bhisho)</option>
                                                <option value="FSHC">FSHC (Bloemfontein)</option>
                                                <option value="WCH">WCH (Cape Town)</option>
                                                <option value="KZDHC">KZDHC (Durban)</option>
                                                <option value="ECGHC">ECGHC (Grahamstown)</option>
                                                <option value="GPJHC">GPJHC (Johannesburg)</option>
                                                <option value="NCHC">NCHC (Kimberley)</option>
                                                <option value="KZPHC">KZPHC (Pietermaritzburg)</option>
                                                <option value="ECHC">ECHC (Port Elizabeth)</option>
                                                <option value="NGHC">NGHC (Pretoria)</option>
                                                <option value="LMPHC">LMPHC (Thohoyandou)</option>
                                                <option value="ECMHC">ECMHC (Mthatha)</option>
                                                <option value="NWHC">NWHC (Mmabatho/Mafikeng)</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>
 
                            <Box mb={2} display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Summons Requested
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendSummonsRequested;
