import { MenuItem, TextField } from "@mui/material";
function EditSelect({ params, options = [] }) {
	return (
		<TextField
			{...params}
			fullWidth
			variant='outlined'
			value={params.value?.id}
			onChange={(e) =>
				params.onChange(options.find((opt) => opt.id == e.target.value))
			}
			select
		>
			{Array.isArray(options) &&
				options.map((opt) => (
					<MenuItem value={opt.id}>{opt.name}</MenuItem>
				))}
		</TextField>
	);
}

export default EditSelect;
