import { Box } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import { CloudDownloadOutlined, EmailOutlined } from "@mui/icons-material";
import SendEmail from "../SendEmail";
import { CommsService } from "api/services";
import axios from "axios";

const ViewStatement = () => {
    const { setModal, unsetModal, setToast } = useModal();

    const handleDownload = () => {
        CommsService.downloadStatement()
            .then((response) => {
                switch (response.status) {
                    case "success":
                        const pdf = response.data.base64Pdf;

                        const linkSource = `data:application/pdf;base64,${pdf}`;
                        const downloadLink = document.createElement("a");
                        const fileName = "statement.pdf";
                        downloadLink.href = linkSource;
                        downloadLink.download = fileName;
                        downloadLink.click();

                        setToast(response.message, "success");
                        unsetModal();
                        break;
                    case "error":
                        setToast(response.message, "error");

                    default:
                        break;
                }
            })
            .catch((error) => console.error("Error downloading PDF file:", error));

        unsetModal();
    };

    const handleSendViaEmail = () => {
        CommsService.emailStatement().then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    unsetModal();

                default:
                    break;
            }
        });
        unsetModal();
    };

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box m={2}>
                <BluePillButton
                    variant="contained"
                    fullWidth
                    style={{
                        width: "20em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: ".4em",
                    }}
                    onClick={handleDownload}
                >
                    <CloudDownloadOutlined style={{ marginRight: ".4em" }} />
                    Download Statement
                </BluePillButton>
            </Box>
            <Box m={2}>
                <BluePillButton
                    variant="contained"
                    fullWidth
                    style={{
                        width: "20em",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        padding: ".4em",
                    }}
                    onClick={handleSendViaEmail}
                >
                    <EmailOutlined style={{ marginRight: ".4em" }} />
                    Send Via E-Mail
                </BluePillButton>
            </Box>
        </Box>
    );
};

export default ViewStatement;
