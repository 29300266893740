import { Grid, IconButton } from "@mui/material";
import AgentService from "api/services/agentService";
import PlayAudioButton from "components/buttons/PlayAudioButton";
import ComposeEmail from "components/modal/ComposeEmail";
import VoipCall from "components/modal/content/VoipCall";
import CustomMaterialTable from "components/table/CustomMaterialTable";
import { useMaterialTable } from "providers/hooks/useMaterialTable";
import { useModal } from "providers/hooks/useModal";
import usePersistentTransistion from "providers/hooks/usePersistentTransition";
import React from "react";
import { useSelector } from "react-redux";
import { dateFormat, timeFormat } from "utils/dateFormat";
import phone_icon from "../../assets/icons/FontAwsome (phone-alt).png";
import email_icon from "../../assets/icons/Layer -15.png";
import { Box, TitleSection } from "../../common/Layouts";
import style from "./Phone.module.css";
import { useHistory } from "react-router-dom";

function Phone() {
    const { setModal, setPersistent, setToast } = useModal();
    const { id: agent_id, user_type_id } = useSelector((state) => state.auth);
    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                AgentService.getAgentCalls(params).then((response) => {
                    resolve({
                        status: response.status,
                        data: response.data.calls,
                        count: response.data.count,
                        pagination: response.pagination,
                    });
                });
            }),
        initParams: null,
    });
    const { status: voip_status } = usePersistentTransistion();
    const history = useHistory();

    const handlePhone = (account_no, phone_number) => {
        setPersistent({
            Component: <VoipCall phone_number={phone_number} account_number={account_no} />,
        });
    };

    const handleEmail = (email) => {
        setModal({ Component: <ComposeEmail sendTo={email} /> });
    };

    React.useEffect(() => {
        if (voip_status === "closed") {
            if (tableRef.current != null) {
                tableRef.current.onQueryChange();
            }
        }
    }, [voip_status]);

    React.useEffect(() => {
        if (agent_id != null) {
            setParams({ agent_id });
        }
    }, [agent_id]);

    React.useEffect(() => {
        if (user_type_id === 5) {
            setToast("Feature unavailable", "warning");
            history.push("/");
        }
    }, [user_type_id]);

    return (
        <div>
            <TitleSection>Call Register</TitleSection>
            <Box padding={"17px 21p"} paddingSmall={"17px 21px"}>
                <div className={style.title_table}>Call register</div>

                <Grid container>
                    <Grid item md={12}>
                        <CustomMaterialTable
                            title={null}
                            tableRef={tableRef}
                            columns={[
                                {
                                    title: "Debtor Name",
                                    field: "debtor_name",
                                },
                                {
                                    title: "Date",
                                    field: "date_time",
                                    render: (row) => (row.date_time != null ? dateFormat(row.date_time) : "-"),
                                },
                                {
                                    title: "Time",
                                    field: "date_time",
                                    render: (row) => (row.date_time != null ? timeFormat(row.date_time) : "-"),
                                },
                                { title: "Description", field: "description" },
                                { title: "Duration", field: "duration" },
                                {
                                    render: (row) => (
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "4px",
                                            }}
                                        >
                                            <IconButton
                                                onClick={() => handlePhone(row.account_number, row.phone_mobile)}
                                                size="large">
                                                <img src={phone_icon} width="22px" alt="" />
                                            </IconButton>
                                            <IconButton onClick={() => handleEmail(row.email)} size="large">
                                                <img src={email_icon} width="22px" alt="" />
                                            </IconButton>
                                            {!!row.recording ? <PlayAudioButton url={row.recording} /> : ""}
                                        </div>
                                    ),
                                },
                            ]}
                            data={remoteDataSource}
                            options={{
                                toolbar: false,
                                pageSize: 10,
                                pageSizeOptions: [10, 15, 20],
                            }}
                        />
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Phone;
