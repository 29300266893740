import { Grid, IconButton, InputAdornment, MenuItem, TextField } from "@mui/material";
import clsx from "clsx";
import { Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import CaseService from "services/caseService";
import styled from "styled-components";
import * as Yup from "yup";
import write_icon from "../../../assets/icons/FontAwsome (edit).png";
import load_icon from "../../../assets/icons/Layer -16.png";
import cancel_icon from "../../../assets/icons/Cancel.png";
import { colors } from "../../../constants/styles";
import { useModal } from "../../../providers/hooks/useModal";
import styles from "./styles.module.css";

const EmploymentDetail = styled.div`
    padding-left: 23px;
    padding-right: 23px;
    padding: 48px 0px;
    @media (min-width: 1920px) {
        padding-left: 29px;
        padding-right: 29px;
    }
`;

const Text = styled.div`
    color: ${colors.blue};
    font-size: 12px;
    font-weight: 500;
    font-family: "Avenir Next Medium";
    display: flex;
    align-items: center;
    span {
        margin-left: 5px;
        line-height: 20px;
        .left_icon {
            margin-right: -5px;
        }
    }
    @media (min-width: 1920px) {
        font-size: 15px;
    }
`;

const WrapperSection = styled.div`
    padding: 20px 0px;
    ${(props) => props.customStyle};
    @media (min-width: 1920px) {
        padding-left: 27px 0px;
    }
    img {
        width: 20px;
        height: auto;
        //margin-bottom: 10px;
        cursor: pointer;
        @media (min-width: 1920px) {
            width: 25px;
            height: auto;
        }
    }
`;
const validationSchema = Yup.object().shape({
    company_name: Yup.string(),
    proll: Yup.string(),
    occupation: Yup.string(),
    supervisor: Yup.string(),
    employee_number: Yup.string(),
    company_address: Yup.string(),
    department: Yup.string(),
    payment_period: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .nullable(),
    pay_day: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .nullable(),
    sallary_cap: Yup.object()
        .shape({
            id: Yup.string(),
        })
        .nullable(),
    account_number: Yup.string(),
    identification_number: Yup.string(),
    reference_number: Yup.string(),
    employment_period: Yup.string(),
    employer_party_name: Yup.string(),
    employer_email: Yup.string(),
    employer_address_line1: Yup.string(),
    employer_address_line2: Yup.string(),
    employer_address_line3: Yup.string(),
    employer_tel_no: Yup.string(),
    salary_amount: Yup.string(),
    salary_proof_status: Yup.string(),
    salary_pay_date: Yup.string(),
    other_income: Yup.string(),
    total_expenditure: Yup.string(),
});

function EmploymentDetails({ account_status, account_details, dispatch }) {
    const { setToast } = useModal();
    const { id: agent_id } = useSelector((state) => state.auth);
    const [editMode, setEditMode] = useState(false);
    const [periods, setPeriods] = React.useState([]);
    const [departments, setDepartments] = React.useState([]);
    const [pay_days, setPayDays] = React.useState([]);
    const [sallaryCap, setSallaryCap] = React.useState([]);
    const [employmentDetails, setEmploymentDetails] = React.useState({
        company_address: "",
        company_name: "",
        proll: "",
        occupation: "",
        supervisor: "",
        employee_number: "",
        department: "",
        payment_period: "",
        pay_day: "",
        sallary_cap: "",

        account_number: "",
        identification_number: "",
        reference_number: "",
        occupation: "",
        supervisor: "",

        pay_period_id: "",
        salary_type_id: "",
        employment_period: "",

        employer_party_name: "",
        employer_email: "",
        employer_address_line1: "",
        employer_address_line2: "",
        employer_address_line3: "",
        employer_tel_no: "",

        salary_amount: "",
        salary_proof_status: "",
        salary_pay_date: "",
        other_income: "",
        total_expenditure: "",
    });

    console.log("Account Details:", account_details);

    const handleDetailsSubmit = async (values) => {
        try {
            const body = {
                ...Object.keys(values)
                    .filter((key) => !!values[key]) // "" or null
                    .reduce((obj, key) => {
                        switch (key) {
                            case "payment_period":
                                obj["pay_period_id"] = values[key].id;
                                break;
                            case "pay_day":
                                obj["pay_day_id"] = values[key].id;
                                break;
                            case "sallary_cap":
                                obj["sallary_cap_id"] = values[key].id;
                                break;
                            default:
                                obj[key] = values[key];
                        }
                        return obj;
                    }, {}),
                account_no: account_details.account_number,
                agent_id,
            };

            console.log("Object body for server upload: ", body);
            const res = await CaseService.updateDebtorCase(body);
            if (res.status == "success") {
                setToast("Saved successfully.", "success");
                setEditMode(false);
                dispatch("save");
                return true;
            }
            throw res.message;
        } catch (e) {
            console.log(e);
            setToast("We encountered an error.", "error");
            return e;
        }
    };

    const handleEditDetails = (e) => {
        e.stopPropagation();
        e.preventDefault();
        setEditMode(true);
    };

    React.useEffect(() => {
        if (account_details != null) {
            setEmploymentDetails((state) => {
                for (const key in state) {
                    if (key in account_details) {
                        state[key] = account_details[key];
                    }
                }
                return { ...state };
            });
        }
    }, [account_details]);

    React.useEffect(() => {
        var isActive = true;

        CaseService.debtorCaseSummaryOptions().then((response) => {
            if (response.data.payment_periods != null && Array.isArray(response.data.payment_periods)) {
                setPeriods([...response.data.payment_periods]);
            }
            if (response.data.departments != null && Array.isArray(response.data.departments)) {
                setDepartments([...response.data.departments]);
            }
            if (response.data.pay_days != null && Array.isArray(response.data.pay_days)) {
                setPayDays([...response.data.pay_days]);
            }
            if (response.data.sallary_caps != null && Array.isArray(response.data.sallary_caps)) {
                setSallaryCap([...response.data.sallary_caps]);
            }

            console.log(response.data);
        });
        return () => {
            isActive = false;
        };
    }, []);

    const showEditButton = () => {
        return (
            <span>
                {!editMode ? (
                    <IconButton onClick={handleEditDetails} type="button" size="large">
                        <img size={25} src={write_icon} alt="" />
                    </IconButton>
                ) : (
                    <>
                        <IconButton type="submit" size="large">
                            <img
                                size={25}
                                // onClick={
                                //     saveChanges
                                // }
                                src={load_icon}
                                alt=""
                            />
                        </IconButton>
                        <IconButton onClick={() => setEditMode(false)} size="large">
                            <img src={cancel_icon} />
                        </IconButton>
                    </>
                )}
            </span>
        );
    };

    return (
        <EmploymentDetail>
            <Formik
                initialValues={employmentDetails}
                enableReinitialize
                validationSchema={validationSchema}
                onSubmit={handleDetailsSubmit}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    resetForm,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <form onSubmit={handleSubmit}>
                        <Grid container justifyContent="center" flexDirection="column">
                            <Grid item md={8}>
                                <Grid container direction="row">
                                    <Grid item md={8}>
                                        <Grid container spacing={2} justifyContent="space-between">
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Company name</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.company_name}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="company_name"
                                                            name="company_name"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.company_name}
                                                            error={touched.company_name && errors.company_name}
                                                            helperText={touched.company_name && errors.company_name}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>P/Roll closed</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{account_details?.proll}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="proll"
                                                            name="proll"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.proll}
                                                            error={touched.proll && errors.proll}
                                                            helperText={touched.proll && errors.proll}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Occupation</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{account_details?.occupation}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="occupation"
                                                            name="occupation"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.occupation}
                                                            error={touched.occupation && errors.occupation}
                                                            helperText={touched.occupation && errors.occupation}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Supervisor</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{account_details?.supervisor}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="supervisor"
                                                            name="supervisor"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.supervisor}
                                                            error={touched.supervisor && errors.supervisor}
                                                            helperText={touched.supervisor && errors.supervisor}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Department</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>{account_details?.department}</div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="department"
                                                            name="department"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.department}
                                                            error={touched.department && errors.department}
                                                            helperText={touched.department && errors.department}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                            select
                                                        >
                                                            {Array.isArray(departments) &&
                                                                departments.map((opt) => (
                                                                    <MenuItem value={opt.name}>{opt.name}</MenuItem>
                                                                ))}
                                                        </TextField>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Employee Number</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.employee_number}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="employee_number"
                                                            name="employee_number"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.employee_number}
                                                            error={touched.employee_number && errors.employee_number}
                                                            helperText={
                                                                touched.employee_number && errors.employee_number
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Employer Address Line 1</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.employer_address_line1}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="employer_address_line1"
                                                            name="employer_address_line1"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.employer_address_line1}
                                                            error={
                                                                touched.employer_address_line1 &&
                                                                errors.employer_address_line1
                                                            }
                                                            helperText={
                                                                touched.employer_address_line1 &&
                                                                errors.employer_address_line1
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Employer Address Line 2</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.employer_address_line2}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="employer_address_line2"
                                                            name="employer_address_line2"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.employer_address_line2}
                                                            error={
                                                                touched.employer_address_line2 &&
                                                                errors.employer_address_line2
                                                            }
                                                            helperText={
                                                                touched.employer_address_line2 &&
                                                                errors.employer_address_line2
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Employer Address Line 3</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.employer_address_line3}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="employer_address_line3"
                                                            name="employer_address_line3"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.employer_address_line3}
                                                            error={
                                                                touched.employer_address_line3 &&
                                                                errors.employer_address_line3
                                                            }
                                                            helperText={
                                                                touched.employer_address_line3 &&
                                                                errors.employer_address_line3
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Employer Email</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.employer_email}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="employer_email"
                                                            name="employer_email"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.employer_email}
                                                            error={touched.employer_email && errors.employer_email}
                                                            helperText={touched.employer_email && errors.employer_email}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Employer Tel No</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.employer_tel_no}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="employer_tel_no"
                                                            name="employer_tel_no"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.employer_tel_no}
                                                            error={touched.employer_tel_no && errors.employer_tel_no}
                                                            helperText={
                                                                touched.employer_tel_no && errors.employer_tel_no
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Salary Amount</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.salary_amount}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="salary_amount"
                                                            name="salary_amount"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.salary_amount}
                                                            error={touched.salary_amount && errors.salary_amount}
                                                            helperText={touched.salary_amount && errors.salary_amount}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                            startAdornment={
                                                                <InputAdornment position="start">R</InputAdornment>
                                                            }
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Salary Proof Status</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.salary_proof_status}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="salary_proof_status"
                                                            name="salary_proof_status"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.salary_proof_status}
                                                            error={
                                                                touched.salary_proof_status &&
                                                                errors.salary_proof_status
                                                            }
                                                            helperText={
                                                                touched.salary_proof_status &&
                                                                errors.salary_proof_status
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                            select
                                                        >
                                                            <MenuItem value="Y">Yes</MenuItem>
                                                            <MenuItem value="N">No</MenuItem>
                                                        </TextField>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Salary Pay Date</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.salary_pay_date}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="salary_pay_date"
                                                            name="salary_pay_date"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.salary_pay_date}
                                                            error={touched.salary_pay_date && errors.salary_pay_date}
                                                            helperText={
                                                                touched.salary_pay_date && errors.salary_pay_date
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Other Income</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.other_income}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="other_income"
                                                            name="other_income"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.other_income}
                                                            error={touched.other_income && errors.other_income}
                                                            helperText={touched.other_income && errors.other_income}
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Total Expenditure</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.total_expenditure}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id="total_expenditure"
                                                            name="total_expenditure"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.total_expenditure}
                                                            error={
                                                                touched.total_expenditure && errors.total_expenditure
                                                            }
                                                            helperText={
                                                                touched.total_expenditure && errors.total_expenditure
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={4}>
                                        <Grid container spacing={2} justifyContent="center" alignItems="center">
                                            <Grid item>
                                                <WrapperSection>
                                                    <div className={styles.title}>
                                                        Company address
                                                        {account_status === 1 ? showEditButton() : null}
                                                    </div>
                                                    {!editMode ? (
                                                        <p className={clsx(styles.text, styles.address)}>
                                                            {account_details.company_address}
                                                        </p>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            multiline
                                                            rows={4}
                                                            id="company_address"
                                                            name="company_address"
                                                            customStyle={{
                                                                justifyContent: "flex-start",
                                                            }}
                                                            value={values.company_address}
                                                            error={touched.company_address && errors.company_address}
                                                            helperText={
                                                                touched.company_address && errors.company_address
                                                            }
                                                            onChange={handleChange}
                                                            isSearch={false}
                                                        />
                                                    )}
                                                </WrapperSection>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid item md={8}>
                                        <Grid container spacing={2} justifyContent="flex-start">
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Pay Period</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.payment_period?.name}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id={"payment_period"}
                                                            name={"payment_period"}
                                                            value={values.payment_period?.id}
                                                            error={touched.payment_period && errors.payment_period}
                                                            helperText={touched.payment_period && errors.payment_period}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "payment_period",
                                                                    periods.find((opt) => opt.id == e.target.value),
                                                                )
                                                            }
                                                            select
                                                        >
                                                            {Array.isArray(periods) &&
                                                                periods.map((opt) => (
                                                                    <MenuItem value={opt.id}>{opt.name}</MenuItem>
                                                                ))}
                                                        </TextField>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Payday</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.pay_day?.name}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id={"pay_day"}
                                                            name={"pay_day"}
                                                            value={values.pay_day?.id}
                                                            error={touched.pay_day && errors.pay_day}
                                                            helperText={touched.pay_day && errors.pay_day}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "pay_day",
                                                                    pay_days.find((opt) => opt.id == e.target.value),
                                                                )
                                                            }
                                                            select
                                                        >
                                                            {Array.isArray(pay_days) &&
                                                                pay_days.map((opt) => (
                                                                    <MenuItem value={opt.id}>{opt.name}</MenuItem>
                                                                ))}
                                                        </TextField>
                                                    )}
                                                </div>
                                            </Grid>
                                            <Grid item md={4}>
                                                <div
                                                    className={styles.wrapper_section}
                                                    style={{
                                                        marginLeft: "-10px",
                                                    }}
                                                >
                                                    <div className={styles.title}>Salary Cap</div>

                                                    {!editMode ? (
                                                        <div className={styles.text}>
                                                            {account_details?.sallary_cap?.name}
                                                        </div>
                                                    ) : (
                                                        <TextField
                                                            variant="outlined"
                                                            fullWidth
                                                            id={"sallary_cap"}
                                                            name={"sallary_cap"}
                                                            value={values.sallary_cap?.id}
                                                            error={touched.sallary_cap && errors.sallary_cap}
                                                            helperText={touched.sallary_cap && errors.sallary_cap}
                                                            onChange={(e) =>
                                                                setFieldValue(
                                                                    "sallary_cap",
                                                                    sallaryCap.find((opt) => opt.id == e.target.value),
                                                                )
                                                            }
                                                            select
                                                        >
                                                            {Array.isArray(sallaryCap) &&
                                                                sallaryCap.map((opt) => (
                                                                    <MenuItem value={opt.id}>{opt.name}</MenuItem>
                                                                ))}
                                                        </TextField>
                                                    )}
                                                </div>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </form>
                )}
            </Formik>
        </EmploymentDetail>
    );
}

export default EmploymentDetails;
