import 'date-fns';
import React from 'react';
import Grid from '@mui/material/Grid';
import { DatePicker } from '@mui/x-date-pickers';
import makeStyles from '@mui/styles/makeStyles';
import CalendarIcon from './../components/calendar/CalendarIcon';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiInput-underline:before': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:after': {
      borderBottom: 'none',
    },
    '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
      borderBottom: 'none',
    },
    '& .MuiOutlinedInput-notchedOutline': {
      border: 'none', 
    },
    '& .MuiOutlinedInput-input':{
      width: '0px',
    }
  },
  titleCalendar: {
    fontSize: '1.2vw',
    marginRight: '-10px',
    paddingTop: '5px',
    display: 'flex',
    alignItems: 'center',
    fontFamily: 'Avenir Next',
    '@media (min-width:1920px)': {
      fontSize: '20px',
    },
  },
  icon_calendar: {
    width: (props) => `${props.size}px`, 
  },
}));


export default function SelectDate({
  title,
  size = 22,
  isFee = false,
  updateTitle,
}) {
  const classes = useStyles({ size });

  const [selectedDate, setSelectedDate] = React.useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
    if (updateTitle) {
      updateTitle(date);
    }
  };

  return (
    <Grid
      container
      justifyContent={!isFee ? 'space-around' : 'center'}
      alignItems="center"
      className={classes.root}
    >
      <div className={classes.titleCalendar}>{title}</div>
        <DatePicker
          disableToolbar
          variant="standard"
          format="MM/dd/yyyy"
          margin="none"
          id="date-picker-inline"
          value={selectedDate}
          onChange={handleDateChange}
          KeyboardButtonProps={{
            'aria-label': 'change date',  
            width: 300,
          }}
          InputProps={{
            sx: {
               borderBottom: 'none',
               width: '50px',
            }
          }}
          slots={{
            openPickerIcon: CalendarIcon,
          }}
        />
      
      </Grid>
  );
}
