import { Typography } from '@mui/material';
import { useHistory } from "react-router-dom"; 
import { useModal } from '../../../providers/hooks/useModal';
import ButtonComponent from '../../ButtonComponent';
import {AGENT_CAMPAIGN_DASHBOARD, DASHBOARD} from '../../../constants/pathNames';

const NextDebtorCallAvailable = ({client}) => {
    const history = useHistory();
    const { unsetModal } = useModal();

    const handleClick = () => {
        const location = {
            pathname: `${DASHBOARD}${AGENT_CAMPAIGN_DASHBOARD}`,
            state: {
                client_id: client.client_id,
                client_name: client.client_name,
            },
        };
        history.push(location);
        unsetModal();
    }

    return (
        <div className={'p-3 flex col ai-c jc-sb'} style={{minWidth: 300}}>
            <Typography variant="h5"> {client.client_name} Campaign Available </Typography>
            <p>Click OK to go to Campaign Summary</p>
            <ButtonComponent width={280} variant={'outlined'} title={'OK'} handler={handleClick} />
        </div>
    )
}

export default NextDebtorCallAvailable
