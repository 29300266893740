import React, { useState, useEffect } from "react";
import { Editor } from "react-draft-wysiwyg";
import { EditorState } from "draft-js";
import { AttachFile, Reply as ReplyIcon } from "@mui/icons-material";
import ButtonComponent from "../../ButtonComponent";
import draftToHtml from "draftjs-to-html";
import { CommsService } from "../../../api/services";
import { useHistory, useParams } from "react-router";
import { ButtonBack } from "../../../common/Layouts";
import { ArrowBack as ArrowBackIcon, FileCopy, MoreHoriz } from "@mui/icons-material";
import {
    ReplyOutlined,
    ReplyAllOutlined,
    ForwardOutlined,
    Flag as FlagFull,
    FlagOutlined,
    DeleteOutlined,
} from "@mui/icons-material";
import { IconButton } from "@mui/material";
import { useModal } from "providers/hooks/useModal";

const ViewEmail = () => {
    const [thread, setThread] = useState([]);
    const [replyAttachments, setReplyAttachments] = useState([]);
    const { unique_hash } = useParams();
    const [replying, setReplying] = useState(false);
    const [flagged, setFlagged] = useState(false);
    const [email, setEmail] = useState({});
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [currentEditorContent, setCurrentEditorContent] = useState("");
    const [expanded, setExpanded] = useState([0]);
    const history = useHistory();
    const { setToast } = useModal();
    const [upToEmailExpanded, setUpToEmailExpanded] = useState([]);
    let fileInputRef = null;

    const getEmailAndThread = async () => {};

    const getThread = async () => {
        const resp = await CommsService.getEmailThread({
            thread: unique_hash,
        });
        if (resp.status === "success") {
            const arr = resp.data.thread.sort((a, b) => b.id - a.id);
            setThread(arr);
            setFlagged(Boolean(arr[0].flagged));
        }
    };

    useEffect(() => {
        getThread();
    }, []);

    const onContentUpdate = (e) => {
        setCurrentEditorContent(draftToHtml(e));
    };

    const onEditorUpdate = (e) => {
        setEditorState(e);
    };

    const sendEmail = async (body) => {
        const resp = await CommsService.agentSendReplyEmail(body);
        return resp;
    };

    const sendReply = async () => {
        console.log(currentEditorContent);
        const formData = new FormData();
        const obj = {
            email_id: thread[0]?.id,
            body: currentEditorContent,
        };
        Object.keys(obj).forEach((key) => {
            formData.append(key, obj[key]);
        });
        replyAttachments.forEach((att) => {
            formData.append("attachments[]", att);
        });

        console.log("formData", formData);
        const send = await sendEmail(formData);
        if (send) {
            setReplying(false);
            setEditorState(EditorState.createEmpty());
            setCurrentEditorContent("");
            setToast("Email sent successfully", "success");
            getThread();
        }
    };

    const toggleExpanded = (index) => {
        if (expanded.includes(index)) {
            const temp = expanded.filter((item) => item !== index);
            setExpanded(temp);
        } else {
            const temp = [...expanded];
            temp.push(index);
            setExpanded(temp);
        }
    };

    const addAttachment = (e) => {
        if (e.target.files) {
            const temp = [...replyAttachments];
            temp.push(e.target.files[0]);
            setReplyAttachments(temp);
        }
    };

    const removeAttachment = (index) => {
        setReplyAttachments(replyAttachments.filter((item, idx) => idx !== index));
    };

    const toggleUpToEmailArray = (index) => {
        if (upToEmailExpanded.includes(index)) {
            const temp = upToEmailExpanded.filter((item) => item !== index);
            setUpToEmailExpanded(temp);
        } else {
            const temp = [...upToEmailExpanded];
            temp.push(index);
            setUpToEmailExpanded(temp);
        }
    };

    const flagEmail = async () => {
        if (thread.length) {
            const resp = await CommsService.flagEmail({ email_id: thread[0].id });
            if (resp.status === "success") {
                if (flagged) {
                    setToast("Email unflagged successfully", "success");
                    setFlagged(false);
                } else {
                    setToast("Email flagged successfully", "success");
                    setFlagged(true);
                }
            } else {
                setToast("An error occurred flagging the email", "error");
            }
        }
    };

    return (
        <div>
            <div className={"flex row ai-c pt-1 mb-3"}>
                <ButtonBack
                    height={30}
                    width={90}
                    isCustom
                    customStyle={{
                        fontFamily: "Avenir Next Demi Bold",
                    }}
                    fontSize={15}
                    small_size={12}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon fontSize="small" />
                    Back
                </ButtonBack>
                <h2 className={"m-0 ml-1 bold"} style={{ paddingTop: 4 }}>
                    E-MAIL
                </h2>
            </div>
            <div className={"flex row ai-c mb-1 mt-1"}>
                <div className={"clickable flex ai-c jc-c emailBtn"} onClick={() => setReplying(true)}>
                    <ReplyOutlined style={{ color: "white" }} />
                </div>
                {/* <div
          className={"clickable flex ai-c jc-c emailBtn"}
          onClick={() => setReplying(true)}
        >
          <ReplyAllOutlined style={{ color: "white" }} />
        </div>
        <div
          className={"clickable flex ai-c jc-c emailBtn"}
          onClick={() => setReplying(true)}
        >
          <ForwardOutlined style={{ color: "white" }} />
        </div> */}
                <div className={"clickable flex ai-c jc-c emailBtn"} onClick={flagEmail}>
                    {flagged ? <FlagFull style={{ color: "white" }} /> : <FlagOutlined style={{ color: "white" }} />}
                </div>
            </div>
            {replying ? (
                <div className={"flex col ai-c jc-c"} style={{ borderBottom: "1px solid black", paddingBottom: 16 }}>
                    <div>
                        <Editor
                            editorState={editorState}
                            toolbarClassName="toolbarClassName"
                            wrapperClassName="wrapperClassName"
                            editorClassName="editorClassName"
                            onEditorStateChange={onEditorUpdate}
                            onContentStateChange={onContentUpdate}
                            editorStyle={{
                                backgroundColor: "#fff",
                                padding: 10,
                                marginBottom: 16,
                                marginTop: 10,
                                borderRadius: 20,
                                border: "1px solid var(--colour-blue)",
                                minHeight: 500,
                            }}
                            toolbar={{
                                options: [
                                    "inline",
                                    "blockType",
                                    "fontSize",
                                    "fontFamily",
                                    "list",
                                    "textAlign",
                                    "link",
                                    "history",
                                ],
                            }}
                            toolbarCustomButtons={[
                                <div className={"mb-1 ml-1"}>
                                    <ButtonComponent handler={() => fileInputRef.click()} title={"+ Attachment"} />
                                </div>,
                            ]}
                        />
                        <div>
                            {replyAttachments.map((item, index) => (
                                <p className={"box-border"}>
                                    {item.name}
                                    <span className={"clickable ml-1"} onClick={() => removeAttachment(index)}>
                                        x
                                    </span>
                                </p>
                            ))}
                        </div>
                        <input type="file" hidden onChange={addAttachment} ref={(r) => (fileInputRef = r)} />
                    </div>

                    <ButtonComponent title={"Send"} handler={sendReply} />
                </div>
            ) : null}
            <div>
                {thread.map((item, index) =>
                    item.body ? (
                        <div className={"m-1 p-2"} style={{ borderRadius: 20, backgroundColor: "#fff" }}>
                            <div
                                className={"flex row ai-c pb-1 mb-1 clickable"}
                                style={{ borderBottom: "1px solid lightgray" }}
                                onClick={() => toggleExpanded(index)}
                            >
                                <h4 className={"m-0 mr-2"}>
                                    <span className={"bold"}>From: </span>
                                    <span className={"pill"}>{item.sender_email}</span>
                                </h4>
                                <h4 className={"m-0"}>
                                    <span className={"bold"}>To: </span>
                                    <span className={"pill"}>{item.recipient_email}</span>
                                </h4>
                            </div>
                            {expanded.includes(index) ? (
                                <>
                                    <div
                                        className={"flex row ai-c pb-1 mb-1"}
                                        style={{ borderBottom: "1px solid lightgray" }}
                                    >
                                        <h4 className={"m-0 mr-2"}>
                                            <span className={"bold"}>CC: </span>
                                            {item.ccs?.map((cc) => (
                                                <span className={"pill"}>{cc.cc}</span>
                                            ))}
                                        </h4>
                                    </div>
                                    <div
                                        className={"flex row ai-c pb-1 mb-1"}
                                        style={{ borderBottom: "1px solid lightgray" }}
                                    >
                                        <h4 className={"m-0 mr-2"}>
                                            <span className={"bold"}>Subject: </span>
                                            {item.subject}
                                        </h4>
                                    </div>
                                    <div
                                        className={"flex row ai-c pb-1 mb-2"}
                                        style={{ borderBottom: "1px solid lightgray" }}
                                    >
                                        <h4 className={"m-0 mr-2"}>
                                            <span className={"bold"}>Attachments: </span>
                                            {item.attachments.map((att) => {
                                                return (
                                                    <a
                                                        className={"pill clickable"}
                                                        style={{ textDecoration: "none" }}
                                                        href={att.url}
                                                        target={"_blank"}
                                                        rel="noreferrer"
                                                    >
                                                        {att.name}
                                                    </a>
                                                );
                                            })}
                                        </h4>
                                    </div>
                                </>
                            ) : null}
                            <div dangerouslySetInnerHTML={{ __html: item.body }}></div>
                            {expanded.includes(index) ? (
                                <>
                                    <div
                                        className={"clickable ta-c flex ai-c jc-c mt-1"}
                                        style={{ width: 60, backgroundColor: "#eee", borderRadius: 20 }}
                                        onClick={() => toggleUpToEmailArray(index)}
                                    >
                                        <MoreHoriz color={"#fff"} />
                                    </div>
                                    <div className={"pl-3 ml-3"} style={{ borderLeft: "1px solid var(--colour-blue)" }}>
                                        {upToEmailExpanded.includes(index) &&
                                            thread
                                                .filter((item, idx) => idx > index)
                                                .map((upToItem) => (
                                                    <div
                                                        className={"m-1 p-2"}
                                                        style={{
                                                            borderRadius: 20,
                                                            backgroundColor: "#fff",
                                                        }}
                                                    >
                                                        <div
                                                            className={"flex row ai-c pb-1 mb-1"}
                                                            style={{ borderBottom: "1px solid lightgray" }}
                                                        >
                                                            <h4 className={"m-0 mr-2"}>
                                                                <span className={"bold"}>From: </span>
                                                                <span className={"pill"}>{upToItem.sender_email}</span>
                                                            </h4>
                                                            <h4 className={"m-0"}>
                                                                <span className={"bold"}>To: </span>
                                                                <span className={"pill"}>
                                                                    {upToItem.recipient_email}
                                                                </span>
                                                            </h4>
                                                        </div>

                                                        <>
                                                            <div
                                                                className={"flex row ai-c pb-1 mb-1"}
                                                                style={{ borderBottom: "1px solid lightgray" }}
                                                            >
                                                                <h4 className={"m-0 mr-2"}>
                                                                    <span className={"bold"}>CC: </span>
                                                                    {upToItem.ccs?.map((cc) => (
                                                                        <span className={"pill"}>{cc.cc}</span>
                                                                    ))}
                                                                </h4>
                                                            </div>
                                                            <div
                                                                className={"flex row ai-c pb-1 mb-1"}
                                                                style={{ borderBottom: "1px solid lightgray" }}
                                                            >
                                                                <h4 className={"m-0 mr-2"}>
                                                                    <span className={"bold"}>Subject: </span>
                                                                    {upToItem.subject}
                                                                </h4>
                                                            </div>
                                                            <div
                                                                className={"flex row ai-c pb-1 mb-2"}
                                                                style={{ borderBottom: "1px solid lightgray" }}
                                                            >
                                                                <h4 className={"m-0 mr-2"}>
                                                                    <span className={"bold"}>Attachments: </span>
                                                                    {upToItem.attachments.map((att) => {
                                                                        return (
                                                                            <a
                                                                                className={"pill clickable"}
                                                                                style={{ textDecoration: "none" }}
                                                                                href={att.url}
                                                                                target={"_blank"}
                                                                                rel="noreferrer"
                                                                            >
                                                                                {att.name}
                                                                            </a>
                                                                        );
                                                                    })}
                                                                </h4>
                                                            </div>
                                                        </>
                                                        <div
                                                            dangerouslySetInnerHTML={{
                                                                __html: upToItem.body,
                                                            }}
                                                        ></div>
                                                    </div>
                                                ))}
                                    </div>
                                </>
                            ) : null}
                        </div>
                    ) : null,
                )}
            </div>
        </div>
    );
};

export default ViewEmail;
