import React, { useRef, useState } from "react";
import { Formik } from "formik";
import * as Yup from "yup";
import { ArrowDown, Box, ButtonBack, TitleSection } from "../../common/Layouts";
import { useHistory, useLocation } from "react-router-dom";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Grid } from "@mui/material";
import icon_delete from "../../assets/icons/Layer -14.png";
import edit_icon from "../../assets/icons/Layer -16.png";
import style from "./Administration.module.css";
import TableUI from "../../components/TableUI";
import { colors } from "../../constants/styles";
import { useDispatch, useSelector } from "react-redux";
import { createClient } from "../../redux/actions/clientsAction";
import CircularProgress from "@mui/material/CircularProgress";
import { ADMINISTRATION, DASHBOARD } from "../../constants/pathNames";
import CommonModal from "../../components/CommonModal";
import { ClientService } from "../../api/services";
import { useModal } from "../../providers/hooks/useModal";
import { useAuth } from "../../providers/hooks/useAuth";
import { useGlobalLoader } from "../../providers/hooks/useGlobalLoader";

const mockData = {
    code: "NA",
    "Activity/decription": "NA",
    price: "NA",
    "": (
        <span className={style.call_icon_section}>
            <img src={edit_icon} alt="" />
            <img src={icon_delete} alt="" />
        </span>
    ),
};

function CreateClient() {
    const history = useHistory();
    const { pathname } = useLocation();
    const dispatch = useDispatch();
    const isLoading = useSelector((state) => state.clientsReducer?.isLoading);
    const { toggleLoading } = useGlobalLoader();
    const { setToast } = useModal();
    const { hasPermission } = useAuth();

    const AddUserSchema = Yup.object().shape({
        name: Yup.string().required("Please enter a name"),
        contact_name: Yup.string().required("Please enter a company contact name"),
        contact_surname: Yup.string().required("Please enter a company contact surname"),
        contact_number: Yup.string()
            .min(10)
            .matches(/^([0-9\s\-\+\(\)]*)$/, "Is not in correct format")
            .required("Please enter a contacct number"),
        address: Yup.string().required("Please enter an address"),
        //logo: Yup.string().required('Field is required'),
        email: Yup.string().required("Please enter a email address").email("Please enter a valid email address"),
    });

    const initialValues = {
        name: "",
        contact_name: "",
        contact_surname: "",
        email: "",
        contact_number: "",
        address: "",
    };

    const names = {
        name: "client name",
        contact_name: "contact name",
        contact_surname: "contact surname",
        email: "email address",
        contact_number: "contact number",
        address: "client address",
    };

    let listData = new Array(1).fill(1).map((item) => mockData);
    const [addRow, setAddRow] = useState(false);
    const [logo, setLogo] = useState(null);
    const [previewLogo, setPreviewLogo] = useState(null);
    const [openModal, setOpenModal] = useState(false);
    const handleClose = () => {
        setAddRow(false);
    };

    const listFields = Object.keys(initialValues);
    const formRef = useRef();
    let sizeArrow = window.innerWidth > 1400 ? 13 : 8;

    const onKeyDown = (keyEvent) => {
        if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
            formRef.current.handleSubmit();
        }
    };

    const handleClick = () => {
        formRef.current.handleSubmit();
    };

    return (
        <div>
            <CommonModal open={openModal} handleClose={() => setOpenModal(false)}>
                <div className={style.wrapper_modal}>
                    <div>Are you sure?</div>
                    <Grid className={style.btn_modal}>
                        <button onClick={() => history.goBack()}>yes</button>
                        <button onClick={() => setOpenModal(false)}>no</button>
                    </Grid>
                </div>
            </CommonModal>
            <TitleSection>
                <ButtonBack
                    height={30}
                    width={91}
                    fontSize={15}
                    small_size={12}
                    isCustom
                    customStyle={{ textTransform: "uppercase", fontFamily: "Avenir Next Demi Bold" }}
                    onClick={() => history.goBack()}
                >
                    <ArrowBackIcon fontSize="small" /> Back
                </ButtonBack>
                <span style={{ fontFamily: "Avenir Next" }}> </span>
                {`CLIENT MANAGER`}
            </TitleSection>
            {!isLoading ? (
                <Box padding={"30px 37px"} paddingSmall={"23px 29px"}>
                    <Grid container justifyContent="space-between">
                        <div className={style.title}>NEW CLIENT DETAILS</div>
                        <Grid className={style.images_wrapper}>
                            {hasPermission("create-client") ? (
                                <img src={edit_icon} alt="" onClick={() => handleClick()} />
                            ) : null}
                            {hasPermission("delete-client") ? (
                                <img src={icon_delete} onClick={() => setOpenModal(true)} alt="" />
                            ) : null}
                        </Grid>
                        <Grid container alignItems="center" justifyContent="flex-start">
                            <Grid item md={2} alignItems="center">
                                {!previewLogo ? (
                                    <Grid container justifyContent="flex-start" alignItems="center">
                                        <label htmlFor="fusk" className={style.add_photo}>
                                            {"+Logo"}
                                        </label>
                                        <input
                                            accept=".png, .jpg, .jpeg"
                                            onChange={(e) => {
                                                setLogo(e.target?.files[0]);
                                                setPreviewLogo(URL.createObjectURL(e.target.files[0]));
                                            }}
                                            id="fusk"
                                            type="file"
                                            name="photo"
                                            style={{ display: "none" }}
                                        />
                                    </Grid>
                                ) : (
                                    <Grid container justifyContent="flex-start" alignItems="center">
                                        <Grid
                                            justifyContent="center"
                                            alignItems="center"
                                            className={style.wrapper_logo_create}
                                        >
                                            <img src={previewLogo} alt="" />
                                        </Grid>
                                        <Grid container justifyContent="center">
                                            <label htmlFor="fusk" style={{ cursor: "pointer" }}>
                                                Replace logo
                                            </label>
                                            <input
                                                accept=".png, .jpg, .jpeg"
                                                onChange={(e) => {
                                                    setLogo(e.target?.files[0]);
                                                    setPreviewLogo(URL.createObjectURL(e.target.files[0]));
                                                }}
                                                id="fusk"
                                                type="file"
                                                name="photo"
                                                style={{ display: "none" }}
                                            />
                                        </Grid>
                                    </Grid>
                                )}
                            </Grid>
                            <Grid item md={9}>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={AddUserSchema}
                                    enableReinitialize={true}
                                    innerRef={formRef}
                                    onSubmit={async (values, { resetForm }) => {
                                        /*   resetForm();  */
                                        toggleLoading();
                                        console.log("logo", logo);
                                        const formData = new FormData();
                                        Object.entries(values).forEach((item) => {
                                            formData.append(`${item[0]}`, item[1]);
                                        });
                                        if (logo) {
                                            formData.append("logo", logo);
                                        }
                                        const resp = await ClientService.create(formData);
                                        if (resp.status === "success") {
                                            setToast("Client added successfully", "success");
                                            history.goBack();
                                        }
                                        toggleLoading();
                                    }}
                                >
                                    {({
                                        errors,
                                        handleBlur,
                                        handleChange,
                                        handleSubmit,
                                        setFieldValue,
                                        setValues,
                                        setTouched,
                                        resetForm,
                                        isSubmitting,
                                        touched,
                                        values,
                                    }) => (
                                        <form onSubmit={handleSubmit} onKeyDown={onKeyDown}>
                                            <Grid container spacing={3} justifyContent="center">
                                                {listFields.map((el, idx) => (
                                                    <Grid key={el} item md={6} className={style.wrapper_input}>
                                                        <div className={style.input_title}>{names[el]}</div>
                                                        <input
                                                            name={el}
                                                            type={"text"}
                                                            value={values[el]}
                                                            onChange={handleChange}
                                                            error={errors[el]}
                                                        />
                                                        {errors[el] && touched[el] && <label>{errors[el]}</label>}
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        </form>
                                    )}
                                </Formik>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid container justifyContent="center">
                        <div className={"p-3"}></div>
                        {/* <Grid item md={10}>
              <TableUI
                addRow={addRow}
                data={listData} 
                isAdministration
                isTopText
                isFee
                isOpenFee={addRow}
                handlerClick={() => {}}
                closeRow={()=>{}}
                color_btn={colors.blue}
                isAdminSearch={false}
                title_btn={"FEE"}
                isCustom/>
            </Grid> */}
                    </Grid>
                </Box>
            ) : (
                <CircularProgress />
            )}
        </div>
    );
}

export default CreateClient;
