import { Grid } from "@mui/material";
import React from "react";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import CaseService from "../../services/caseService";

function AdditionalInfo({ account_no, agent_id }) {
	const { remoteDataSource, setParams, tableRef } = useMaterialTable({
		endpoint: (params) =>
			new Promise((resolve, reject) => {
				CaseService.debtorCaseAdditional(params).then((response) => {
					resolve({
						status: response.status,
						data: response.data.additional_data,
						count: response.data.count,
						pagination: response.pagination,
					});
				});
			}),
		initParams: {
			account_no,
		},
	});

	return (
		<Grid container justifyContent='center'>
			<Grid item md={11} style={{ padding: "0 50px" }}>
				<CustomMaterialTable
					tableRef={tableRef}
					title={null}
					columns={[
						{
							title: "Attribute",
							field: "attribute",
						},
						{
							title: "Value",
							field: "value",
						},
					]}
					data={remoteDataSource}
					options={{
						search: false,
						toolbarButtonAlignment: "left",
						actionsColumnIndex: -1,
					}}
				/>
			</Grid>
		</Grid>
	);
}

export default AdditionalInfo;
