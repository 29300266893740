import {
    Box,
    Chip,
    CircularProgress,
    Divider,
    Grid,
    IconButton,
    TextField,
    Typography,
} from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import { Autocomplete } from '@mui/material';
import { Formik } from "formik";
import React, { useEffect, useState } from "react";
import { CommsService } from "../../../api/services";
import { useModal } from "../../../providers/hooks/useModal";
import { AttachFileRounded } from "@mui/icons-material";
import email_icon from "../../../assets/icons/FontAwsome (paper-plane-white).png";
import { colors } from "../../../constants/styles";
import TagInput from "components/inputs/TagInput";

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "50px",
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    customIconButton: {
        backgroundColor: colors.blue,
        color: colors.white,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: colors.lightGray,
            color: colors.blue,
        },
    },
    customIconButtonWithImage: {
        padding: "15px",
        backgroundColor: colors.blue,
        color: colors.white,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: colors.lightGray,
            color: colors.blue,
        },
    },
    customIconButtonLoading: {
        backgroundColor: colors.white,
    },

    customTypography: {
        color: colors.blue,
        marginTop: "2px",
    },
}));

const debtorList = (accounts) => {
    let result = "";

    for (let index = 0; index < accounts.length; index++) {
        if (index > 6) {
            result += " ... (" + (accounts.length - index) + " more)";
            break;
        }

        if (index > 0) {
            result += ", ";
        }

        result += accounts[index].debtor_name;
    }

    return result;
};

const BulkEmailTemplate = ({ accounts }) => {
    const [cc, setCC] = useState([]);
    const classes = useStyles();
    const [loading, setLoading] = useState(false);
    const [attachments, setAttachments] = useState([]);
    const [emailTemplateOptions, setEmailTemplateOptions] = useState([]);
    const [emailData, setEmailData] = useState({
        subject: "",
        id: 0,
    });

    const { setToast, unsetModal } = useModal();

    const toggleCC = (email) => {
        if (cc.includes(email)) {
            const temp = cc.filter((item) => item !== email);
            setCC(temp);
        } else {
            const temp = [...cc];
            temp.push(email);
            setCC(temp);
        }
    };

    const getEmailTemplates = async () => {
        const resp = await CommsService.getMessageTemplates({
            communication_type_id: 2,
        });

        if (resp.status === "success") {
            setEmailTemplateOptions(resp.data.options);
        }
    };

    useEffect(() => {
        getEmailTemplates();
    }, []);

    const sendEmail = async (body) => {
        console.log("we fired the bulk emails");
        const resp = await CommsService.agentSendBulkEmailsToDebtors(body);
        return resp;
    };

    const handleEmailTemplateChange = (val) => {
        console.log(val);
        if (val?.id) {
            const obj = {
                id: val.id,
            };
            setEmailData(obj);
        }
    };

    const addAttachment = (e) => {
        if (e.target.files) {
            const temp = [...attachments];
            temp.push(e.target.files[0]);
            setAttachments(temp);
        }
    };

    const removeAttachment = (index) => {
        setAttachments(attachments.filter((item, idx) => idx !== index));
    };

    return (
        <div
            style={{
                paddingTop: "20px",
                paddingRight: "30px",
                paddingLeft: "30px",
                overflow: "auto",
                height: "50vh",
            }}
        >
            <h1 className={"colour-blue bold m-0 mb-2"}>COMPOSE BULK EMAIL</h1>
            <Formik
                initialValues={{
                    recipient_email: "",
                    subject: "",
                    cc: "",
                    debtor_id: "",
                    attachments: "",
                }}
                onSubmit={async (values) => {
                    setLoading(true);
                    const formData = new FormData();
                    const obj = {
                        communication_id: emailData.id,
                    };
                    Object.keys(obj).forEach((key) => {
                        formData.append(key, obj[key]);
                    });
                    cc.forEach((item) => {
                        formData.append("cc[]", item);
                    });
                    accounts.forEach((att) => {
                        formData.append("account_no[]", att.account_number);
                    });
                    attachments.forEach((att) => {
                        formData.append("attachments[]", att);
                    });
                    console.log("sending");
                    const send = await sendEmail(formData);
                    console.log("sent", send);
                    if (send) {
                        unsetModal();
                        setToast("Email sent successfully", "success");
                    }
                    setLoading(false);
                }}
            >
                {({
                    errors,
                    handleBlur,
                    handleChange,
                    handleSubmit,
                    setFieldValue,
                    setValues,
                    setTouched,
                    resetForm,
                    isSubmitting,
                    touched,
                    values,
                }) => (
                    <Box component="Grid" container>
                        <Grid item md={12}>
                            <Box justifyContent="left" display="flex" alignItems="flex-start" gap={5}>
                                <IconButton
                                    className={
                                        !loading ? classes.customIconButtonWithImage : classes.customIconButtonLoading
                                    }
                                    onClick={handleSubmit}
                                    size="large">
                                    {loading ? (
                                        <CircularProgress size={20} style={{ color: "var(--colour-blue)" }} />
                                    ) : (
                                        <>
                                            <img src={email_icon} color={"blue"} alt="call" width={20} />
                                        </>
                                    )}
                                </IconButton>
                                <div>
                                    <IconButton
                                        color="primary"
                                        aria-label="upload picture"
                                        component="label"
                                        className={classes.customIconButton}
                                        size="large">
                                        <input hidden type={"file"} onChange={addAttachment} />
                                        <AttachFileRounded />
                                    </IconButton>
                                </div>
                            </Box>
                        </Grid>
                        <Divider />
                        <Grid item md={12}>
                            <Typography className={"colour-blue pt-1"} style={{ minHeight: "45px" }}>
                                <strong>To</strong>:{debtorList(accounts)}
                            </Typography>
                        </Grid>
                        <Divider />
                        <Grid item md={12}>
                            <Box justifyContent="left" display="flex" alignItems="flex-start">
                                <Typography className={"colour-blue pt-1"}>
                                    <strong>Cc</strong>:
                                </Typography>

                                <TagInput
                                    style={{ border: "None", borderRadius: "None" }}
                                    tags={cc}
                                    onTagAdd={toggleCC}
                                    onTagDelete={toggleCC}
                                    regEx={
                                        /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/g
                                    }
                                    regExMsg={"Please enter a valid email"}
                                />
                            </Box>
                        </Grid>
                        <Divider />
                        <Grid item md={12}></Grid>
                        <Divider />
                        <Grid item md={4}>
                            <Autocomplete
                                className={"mt-2"}
                                disablePortal
                                options={emailTemplateOptions}
                                getOptionLabel={(option) => `${option?.name}`}
                                onChange={(e, v) => handleEmailTemplateChange(v)}
                                style={{
                                    border: "1px solid var(--colour-blue)",
                                    borderRadius: 25,
                                    backgroundColor: "var(--colour-white)",
                                }}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        name={"email_template"}
                                        inputProps={{
                                            ...params.inputProps,
                                            style: {
                                                borderRadius: 25,
                                            },
                                        }}
                                        InputProps={{
                                            ...params.InputProps,
                                            disableUnderline: true,
                                        }}
                                        style={{
                                            backgroundColor: "transparent",
                                        }}
                                        label="Select A Message"
                                    />
                                )}
                            />
                        </Grid>
                        <Grid item md={12}>
                            {attachments.map((item, index) => (
                                <Chip label={item.name} onDelete={() => removeAttachment(index)} />
                            ))}
                        </Grid>
                    </Box>
                )}
            </Formik>
        </div>
    );
};

export default BulkEmailTemplate;
