import { IconButton } from "@mui/material";
import clsx from "clsx";
import { useAuth } from "providers/hooks/useAuth";
import React, { useState, useReducer } from "react";
import { useSelector } from "react-redux";
import { Device } from "twilio-client";
import close_icon from "../../../../assets/icons/Group 414.png";
import call_icon from "../../../../assets/icons/Group_call.png";
import end_icon from "../../../../assets/icons/Group_call2.png";
import over_limit_end_icon from "../../../../assets/icons/Group_call486.png";
import over_limit_mute_icon from "../../../../assets/icons/Group_call_mute488.png";
import mute_icon from "../../../../assets/icons/Layer -28.png";
import { useModal } from "../../../../providers/hooks/useModal";
import TwilioService from "../../../../services/twilioService";
import styles from "./styles.module.css";

async function getAudioDevices(device) {
  await navigator.mediaDevices.getUserMedia({ audio: true });
}

const initialCallState = {
  backgroundColor: "#002b64",
  end_icon: end_icon,
  mute_icon: mute_icon,
  mute_icon_width: 35
};

const callReducer = (state, action) => {
  switch (action.type) {
    case "COMPLETE":
      console.log("testing....");
      return {
        ...state,
        backgroundColor: initialCallState.backgroundColor,
        end_icon: initialCallState.end_icon,
		mute_icon: initialCallState.mute_icon,
		mute_icon_width: initialCallState.mute_icon_width
      };
    case "OVER-LIMIT":
      return {
        ...state,
        backgroundColor: "red",
        end_icon: over_limit_end_icon,
		mute_icon: over_limit_mute_icon,
		mute_icon_width: 50
      };

    default:
      return state;
  }
};

function VoipCall({ phone_number, account_number }) {
  const [state, dispatch] = useReducer(callReducer, initialCallState);
  const { worker_id } = useAuth();
  const { id: user_id } = useSelector((state) => state.auth);
  const [deviceState, setDeviceState] = useState();
  const [connection, setConnection] = useState();
  const [callInfo, setCallInfo] = useState();
  const { unsetPersistent } = useModal();

  const call = () => {
    setCallInfo(null);
    if (connection == null && phone_number != null) {
      const connection = deviceState.connect({
        To: phone_number,
        From: "+27872502142",
        phone_no: phone_number,
        account_number,
        user_id,
      });
      setConnection(connection);
    } else {
      console.log({ connection, phone_number });
    }
  };

  const disconnect = () => {
    if (connection != null) {
      connection.disconnect();
      setCallInfo((state) => ({
        ...state,
        status: "Call Ended.",
      }));
    }
    deviceState.disconnectAll();
    setConnection(null);
  };

  const mute = () => {
    if (connection != null) {
      connection.mute(!connection.isMuted());
    }
  };

  const closeCall = () => {
    disconnect();
    unsetPersistent();
  };

  React.useEffect(() => {
    let active = true;

    TwilioService.getToken(worker_id).then((res) => {
      if (active == true) {
        let device = new Device(res.token, {
          codecPreferences: ["opus", "pcmu"],
          fakeLocalDTMF: true,
          enableRingingState: true,
          debug: true,
        });
        //device.audio.incoming(false);
        //device.audio.outgoing(true);
        //device.audio.disconnect(false);
        //device.on('incoming', connection => {
        // immediately accepts incoming connection
        //connection.accept();
        //});
        setDeviceState(device);
      }
    });

    return () => {
      active = false;
    };
  }, []);

  React.useEffect(() => {
    var timer = null;
    if (connection != null) {
      timer = setInterval(() => {
        setCallInfo((state) => {
          let call_status = connection.status();
          let new_state = {
            seconds: 0,
            duration: null,
            status: "",
          };

          switch (call_status) {
            case "pending":
              new_state.status = "Establishing...";
              break;
            case "connecting":
              new_state.status = "Connecting...";
              break;
            case "ringing":
              new_state.status = "Ringing...";
              break;
            case "open":
              new_state.status = "Ongoing Call...";
              new_state.seconds =
                state?.seconds != null ? state.seconds + 1 : 0;
              break;
            case "closed":
              new_state.status = "Call Ended.";
              connection.disconnect();
              setConnection(null);
              clearInterval(timer);
              break;
            default:
              return null;
          }
          return {
            ...new_state,
            duration: `${Math.floor((new_state.seconds % 3600) / 60)}:${String(
              Math.round(new_state.seconds % 60)
            ).padStart(2, "0")}`,
          };
        });
      }, 1000);

      connection.on("disconnect", () => {
		dispatch({ type: "COMPLETE" });
        console.log("WE HAVE DISCONNECTED!");
      });
      connection.on("reject", () => console.log("WE HAVE BEEN REJECTED!"));
    }

    return () => {
      if (timer != null) {
        clearInterval(timer);
      }
    };
  }, [connection]);

  React.useEffect(() => {
    if (callInfo?.seconds > 120) {
      dispatch({ type: "OVER-LIMIT" });
    }
  }, [callInfo?.seconds]);

  return (
    <div
      className={styles.voipContainer}
      style={{ backgroundColor: state.backgroundColor }}
    >
      {connection == null && (
        <IconButton className={styles.voipClose} onClick={closeCall} size="large">
          <img src={close_icon} alt="close" width={10} />
        </IconButton>
      )}
      <div>
        <p>{callInfo?.status ?? "Dial"}</p>
        <p>{phone_number}</p>
      </div>
      <div>
        <span>{callInfo?.duration ?? "0:00"}</span>
      </div>
      <div className={styles.voipActionsContainer}>
        <IconButton
          disabled={!(connection != null)}
          className={clsx(styles.voipActionsButton, {
            [styles.voipActionBackground]: connection?.isMuted(),
          })}
          onClick={mute}
          size="large">
          <img src={state.mute_icon} alt="mute" width={state.mute_icon_width} />
        </IconButton>
        {connection != null ? (
          <IconButton className={styles.voipActionsButton} onClick={disconnect} size="large">
            <img src={state.end_icon} alt="end call" width={50} />
          </IconButton>
        ) : (
          <IconButton
            disabled={!(deviceState != null)}
            className={styles.voipActionsButton}
            onClick={call}
            size="large">
            <img src={call_icon} alt="call" width={50} />
          </IconButton>
        )}
      </div>
    </div>
  );
}

export default VoipCall;
