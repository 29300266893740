import { Box, CircularProgress, TextField, FormGroup, FormControlLabel, Switch, Tooltip, IconButton } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from '@mui/icons-material'; // Import HelpOutlineOutlined icon from Material-UI


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    trace_method: Yup.string().required("Trace method is a required field"),
    trace_comments: Yup.string().required("Trace comments is a required field"),
});

const SendDebtorDebtorOnTrace = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);
    const [unableToTraceChecked, setUnableToTraceChecked] = useState(false);

    const [data, setData] = useState({
        account_no,
        unable_to_trace: "N",
        trace_method: "",
        trace_comments: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendDebtorDebtorOnTrace = () => {
        setLoading(true);
        LssmService.sendDebtorOnTrace({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const handleUnableToTraceChecked = (e) => {
        setUnableToTraceChecked(e.target.checked);
        !unableToTraceChecked
          ? setData({ ...data, unable_to_trace: "Y"})
          : setData({ ...data, unable_to_trace: "N" });
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Sent when the Debtor is under trace">
                    <Title>
                        Debtor On Trace
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendDebtorDebtorOnTrace}
                >
                    {({ errors }) => (
                        <Form fullWidth>

                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={unableToTraceChecked} color="primary" onChange={handleUnableToTraceChecked} />}
                                        label="Unable to trace Debtor?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Trace Method</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="trace_method"
                                        name="trace_method"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.trace_method}
                                        error={errors.trace_method ? true : false}
                                        helperText={errors.trace_method}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>  

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Trace Comments</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="trace_comments"
                                        name="trace_comments"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.trace_comments}
                                        error={errors.trace_comments ? true : false}
                                        helperText={errors.trace_comments}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>  

                            <Box mb={2}  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Debtor On Trace
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendDebtorDebtorOnTrace;
