import { Box, CircularProgress, Divider, Grid, IconButton, TextField, Typography } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';
import React from "react";
import AgentService from "../../../api/services/agentService";
import email_icon from "../../../assets/icons/FontAwsome (paper-plane-white).png";
import { TitleSection } from "../../../common/Layouts";
import { useModal } from "../../../providers/hooks/useModal";
import { colors } from "../../../constants/styles";
import { CommsService } from "api/services";
import { Autocomplete } from '@mui/material';

const useStyles = makeStyles((theme) => ({
    root: {
        borderRadius: "50px",
    },
    container: {
        display: "flex",
        flexWrap: "wrap",
    },
    customIconButton: {
        backgroundColor: colors.blue,
        color: colors.white,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: colors.lightGray,
            color: colors.blue,
        },
    },
    customIconButtonWithImage: {
        width: "150px",
        borderRadius: "36px",
        backgroundColor: colors.blue,
        color: colors.white,
        "&:hover, &.Mui-focusVisible": {
            backgroundColor: colors.lightGray,
            color: colors.blue,
        },
    },
    customIconButtonLoading: {
        backgroundColor: colors.white,
    },
    customTypography: {
        color: colors.blue,
        marginTop: "2px",
    },
}));

const debtorList = (accounts) => {
    let result = "";

    for (let index = 0; index < accounts.length; index++) {
        if (index > 6) {
            result += " ... (" + (accounts.length - index) + " more)";
            break;
        }

        if (index > 0) {
            result += ", ";
        }

        result += accounts[index].debtor_name;
    }

    return result;
};

const BulkSms = ({ accounts }) => {
    const classes = useStyles();
    const { setToast, unsetModal } = useModal();
    const [loading, setLoading] = React.useState(false);
    const [message, setMessage] = React.useState({});
    const [smsTemplateOptions, setSmsTemplateOptions] = React.useState([]);

    const handleSmsTemplateChange = (val) => {
        console.log(val);
        if (val?.body) {
            const obj = {
                body: val.body,
                id: val.id,
            };
            setMessage(obj);
        }
    };

    const getSmsTemplates = async () => {
        const resp = await CommsService.getMessageTemplates({
            communication_type_id: 1,
        });

        if (resp.status === "success") {
            setSmsTemplateOptions(resp.data.options);
        }
    };

    React.useEffect(() => {
        getSmsTemplates();
    }, []);

    const handleSendMessage = () => {
        setLoading(true);

        const account_number = accounts.map((attr) => {
            return attr.account_number;
        });

        AgentService.sendBulkMessage({
            account_no: account_number,
            communication_id: message.id,
        })
            .then((response) => {
                if (response.status === "success") {
                    setLoading(false);
                    setToast("Message send successfully.", "success");
                    unsetModal();
                } else {
                    setToast("There was an error sending the message.", "error");
                }
            })
            .catch(() => {
                setToast("There was an error sending the message.", "error");
                setLoading(false);
            });
    };
    return <>
        <div
            style={{
                paddingTop: "5px",
                paddingRight: "30px",
                paddingLeft: "30px",
                overflow: "auto",
                height: "50vh",
            }}
        >
            <Box component="Grid" container>
                <Grid item md={12}>
                    <TitleSection>COMPOSE BULK SMS</TitleSection>
                </Grid>
                <Divider />
                <Grid item md={12}>
                    <Typography className={"colour-blue pt-1"} style={{ minHeight: "45px" }}>
                        To: {debtorList(accounts)}
                    </Typography>
                </Grid>
                <Divider />
                <Grid item md={4}>
                    <Autocomplete
                        className={"mt-2"}
                        disablePortal
                        options={smsTemplateOptions}
                        getOptionLabel={(option) => `${option?.name}`}
                        onChange={(e, v) => handleSmsTemplateChange(v)}
                        style={{
                            border: "1px solid var(--colour-blue)",
                            borderRadius: 25,
                            backgroundColor: "var(--colour-white)",
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                name={"message_template"}
                                inputProps={{
                                    ...params.inputProps,
                                    style: {
                                        borderRadius: 25,
                                    },
                                }}
                                InputProps={{
                                    ...params.InputProps,
                                    disableUnderline: true,
                                }}
                                style={{
                                    backgroundColor: "transparent",
                                }}
                                label="Select A Message"
                            />
                        )}
                    />
                </Grid>
            </Box>
        </div>

        <div
            style={{
                paddingBottom: "20px",
                paddingRight: "30px",
                paddingLeft: "30px",
            }}
        >
            <Box component="Grid" display="flex" justifyContent="flex-end" alignItems="flex-end">
                <IconButton
                    disabled={Object.keys(message).length === 0}
                    className={!loading ? classes.customIconButtonWithImage : classes.customIconButtonLoading}
                    onClick={handleSendMessage}
                    size="large">
                    {loading ? (
                        <CircularProgress size={20} style={{ color: "var(--colour-blue)" }} />
                    ) : (
                        <>
                            <img src={email_icon} color={"blue"} alt="call" />
                            <Typography variant="body1" style={{ marginLeft: "15px" }}>
                                <strong>Send</strong>
                            </Typography>
                        </>
                    )}
                </IconButton>
            </Box>
        </div>
    </>;
};

export default BulkSms;
