import React from "react";
import { MaterialTableCurrencySettings } from "./locale";
import { Chip, Tooltip } from "@mui/material";
import withStyles from '@mui/styles/withStyles';


  const HtmlTooltip = withStyles((theme) => ({
    tooltip: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      width: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }))(Tooltip);

  const formatSeconds = (seconds) => {
    if (!seconds && seconds !== 0) return "";
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = Math.floor((seconds % 3600) % 60);
    return `${h < 10 ? "0" + h : h}:${m < 10 ? "0" + m : m}:${
      s < 10 ? "0" + s : s
    }`;
  };

  const CAMPAIGN_FIELDS = [
    { title: "ID", field: "id", hidden: true },
    { title: "Debtor Name", field: "debtor_name" },
    { title: "Account Type", field: "account_type" },
    { title: "Account Number", field: "account_number" },
    {
      title: "Reference Number",
      field: "reference_number",
    },
    {
      title: "Capital",
      field: "capital",
      type: "currency",
      currencySetting: MaterialTableCurrencySettings,
      align: "center",
    },
    {
      title: "Interest",
      field: "interest",
      type: "currency",
      currencySetting: MaterialTableCurrencySettings,
      align: "center",
    },
    {
      title: "Fees",
      field: "fees",
      type: "currency",
      currencySetting: MaterialTableCurrencySettings,
      align: "center",
    },
    {
      title: "Legal Fees",
      field: "legal_fees",
      type: "currency",
      currencySetting: MaterialTableCurrencySettings,
      align: "center",
    },
    {
      title: "Settlement",
      field: "settlement",
      type: "currency",
      currencySetting: MaterialTableCurrencySettings,
      align: "center",
    },
    {
      title: "Agent",
      field: "agent",
      align: "center",
      render: (row) =>
        row.agent != null &&
        Array.isArray(row.agent) &&
        row.agent?.length > 0 ? (
          <HtmlTooltip
            title={
              <React.Fragment>
                {row.agent.map((agent) => (
                  <p key={`agent-${agent.id}`}>
                    {agent.name}
                    {agent.surname != null ? " " + agent.surname : null}
                  </p>
                ))}
              </React.Fragment>
            }
            arrow
          >
            <Chip
              label={`${row.agent.length} Agent${
                row.agent.length > 1 ? "s" : ""
              }`}
            />
          </HtmlTooltip>
        ) : (
          "-"
        ),
    },
    {
      title: "Last Activity",
      field: "last_activity",
      align: "center",
    },
  ];

  const AGENT_FIELDS = [
    {
      title: "Agent",
      field: "agent",
    },
    {
      title: "Manager",
      field: "manager",
    },
    {
      title: "Number of Matters",
      field: "number_of_matters",
    },
    {
      title: "Total PtP",
      field: "total_ptp",
    },
    {
      title: "Payments Received Per Day",
      field: "payments_recieved_per_day",
    },
    {
      title: "Received Payments",
      field: "recieved_payments",
    },
    {
      title: "PtP Per Day",
      field: "ptp_per_day",
    },
  ];

  const LOGOUT_FIELDS = [
    {
      title: "Agent Name",
      field: "agent_name",
    },
    {
      title: "Manager",
      field: "Manager",
    },
    {
      title: "Working Time",
      field: "working_time",
      render: (r) => formatSeconds(r.working_time),
    },
    {
      title: "Lunch Break",
      field: "lunch_break",
      render: (r) => formatSeconds(r.lunch_break),
    },
    {
      title: "Meeting",
      field: "meeting",
      render: (r) => formatSeconds(r.meeting),
    },
    {
      title: "Consultations",
      field: "consultations",
      render: (r) => formatSeconds(r.consultations),
    },
    {
      title: "Tea Break",
      field: "tea_break",
      render: (r) => formatSeconds(r.tea_break),
    },
    {
      title: "Comfort Break",
      field: "comfort_break",
      render: (r) => formatSeconds(r.comfort_break),
    },
    {
      title: "Smoke Break",
      field: "smoke_break",
      render: (r) => formatSeconds(r.smoke_break),
    },
    {
      title: "User Idle",
      field: "user_idle",
      render: (r) => formatSeconds(r.user_idle),
    },
  ];

  const PTP_OVERRIDE_FIELDS = [
    {title: "Account No.", field: "debtor_case_id", editable: 'never'},
    {title: "PTP Amount", field: "ptp_amount", editable: 'never'},
    {title: "Override Request Reason", field: "override_request_reason", editable: 'never'},
    {title: "Notes", field: "notes" }
  ];


  const PTP_DISCOUNT_FIELD = [
    {title: "PTP Discount%", field: "ptp_discount", editable: 'never'},
  ];

  const AGENT_PTP_OVERRIDE_FIELDS = [
      { title: "Account No.", field: "debtor_case_id" },
      {
          title: "PTP Amount",
          field: "ptp_amount",
          type: "currency",
          currencySetting: MaterialTableCurrencySettings,
      },
      { title: "Override Request Reason", field: "override_request_reason" },
      { title: "Status", field: "status" },
      { title: "Notes", field: "notes" },
  ];

  export { CAMPAIGN_FIELDS, AGENT_FIELDS, LOGOUT_FIELDS, PTP_OVERRIDE_FIELDS, AGENT_PTP_OVERRIDE_FIELDS, PTP_DISCOUNT_FIELD };
