import React, { useEffect, useState } from "react";
import { AgentService } from "../../../api/services";
import { useMaterialTable } from "../../../providers/hooks/useMaterialTable";
import CustomMaterialTable from "../../../components/table/CustomMaterialTable";

import {
  AGENT_FIELDS,
} from "../../../constants/tableColumns";

function AgentSummary({params}) {

  const { remoteDataSource, setParams, tableRef } = useMaterialTable({
    endpoint: (params) => new Promise((resolve, reject) => {
        AgentService.agentSummary(params).then((response) => {
          resolve({
            status: response.status,
            data: response.data?.agents,
            count: response.data?.count,
            pagination: response.pagination,
          });
        });
    }),
    initParams: {},
  });

  useEffect(() => {
    if (params) {
      setParams(params);
    }
  }, []);

  return (
    <div>
        <CustomMaterialTable
        title={null}
        tableRef={tableRef}
        columns={AGENT_FIELDS}
        data={remoteDataSource}
        options={{
            actionsColumnIndex: -1,
            pageSize: 10,
            search: true,
        }}
        />    
    </div>
  );
}

export default AgentSummary;
