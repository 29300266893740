import { createTheme, ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import AvenirNext from '../assets/fonts/AvenirNextLTPro-Regular.otf';

// Define your font-face rules
const avenirNextBold = {
  fontFamily: 'Avenir Next Bold',
  fontWeight: 'bold',
  src: `url(../assets/fonts/AvenirNextLTPro-Bold.otf) format('opentype')`,
};

const avenirNextDemiBold = {
  fontFamily: 'Avenir Next Demi Bold',
  src: `url(../assets/fonts/Avenir Next-SemiBold.otf) format('opentype')`,
};

const avenirNextIt = {
  fontFamily: 'Avenir Next It',
  src: `url(../assets/fonts/AvenirNextLTPro-It.otf) format('opentype')`,
};

const avenirNextMedium = {
  fontFamily: 'Avenir Next Medium',
  src: `url(../assets/fonts/Avenir Next-Medium.otf) format('opentype')`,
};

const avenirNext = {
  fontFamily: 'Avenir Next',
  src: `url(${AvenirNext}) format('opentype')`,
};

// Create a theme with MUI v5
const theme = createTheme({
  typography: {
    fontFamily: 'Avenir Next, Arial, Helvetica, sans-serif',
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        '@font-face': [
          avenirNextBold,
          avenirNextDemiBold,
          avenirNextIt,
          avenirNextMedium,
          avenirNext,
        ],
      },
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          border: '1px solid #01579b !important',
          borderRadius: '15px !important',
        },
      },
    },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: '18px',
        },
        input: {
          padding: '9px 12px',
        },
      },
    },
  },
  palette: {
    primary: {
      main: '#002b64',
    },
    secondary: {
      main: '#ec7346',
    },
  },
});

export default theme;
