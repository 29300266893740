import { Grid, Paper, TextField, Typography } from "@mui/material";
import DebtorCasePtpService from "api/services/debtorCasePtpService";
import { useFormik } from "formik";
import { useModal } from "providers/hooks/useModal";
import * as Yup from "yup";
import BluePillButton from "../../../../components/buttons/BluePillButton";
import styles from "./styles.module.css";

const schema = {
    override_request_reason: Yup.string().required("PTP override reason is required."),
};

const PtpOverride = (response) => {
    const { setToast, unsetModal } = useModal();
    const { status, message } = response.response;

    if (status === "error") {
        setToast(message, "error");
        unsetModal();
    }

    const { values, errors, handleChange, handleSubmit } = useFormik({
        onSubmit: async (values) => {
            try {
                const override_response = await DebtorCasePtpService.createPtpOverride({
                    override_request_reason: values.override_request_reason,
                });

                if (override_response.status === "success") {
                    setToast("PTP Override Request Sent.", "success");
                    unsetModal();
                }
                //throw res.message;
            } catch (e) {
                console.log(e);
                setToast("We ran into an error.", "error");
                unsetModal();
            }
        },
        validationSchema: Yup.object().shape({
            override_request_reason: schema.override_request_reason,
        }),
        initialValues: {
            override_request_reason: "",
        },
    });

    return (
        <Paper className={styles.container}>
            <form noValidate onSubmit={handleSubmit}>
                <Grid container spacing={2} direction="column">
                    <Grid item sm={12}>
                        <Typography variant="h5"> PTP Override </Typography>
                    </Grid>
                    <Grid item>
                        <TextField
                            fullWidth
                            label="Override Reason"
                            name="override_request_reason"
                            value={values?.override_request_reason}
                            error={errors.override_request_reason}
                            // touched={touched.override_request_reason}
                            onChange={handleChange}
                        />
                    </Grid>
                    <Grid item>
                        <BluePillButton variant="contained" type="submit" fullWidth>
                            Send Override Request
                        </BluePillButton>
                    </Grid>
                </Grid>
            </form>
        </Paper>
    );
};

export default PtpOverride;
