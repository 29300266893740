import { useEffect, useState } from "react";
import { useModal } from "./useModal";
import { useStopwatch } from "react-timer-hook";
import ActiveBreak from "../../components/modal/ActiveBreak";
import { useVoip } from "./useVoip";
import { useSelector } from "react-redux";

const useActivityTracker = () => {
  const {
    seconds,
    minutes,
    hours,
    start,
    pause,
    reset,
  } = useStopwatch({ autoStart: true });
  const { setModal, currentModal } = useModal();
  const user_type_id = useSelector((state) => state.auth.user_type_id);
  const [inactivityTime, setInactivityTime] = useState(0); // Track inactivity time
  const { updateWorkerAvailability } = useVoip();

  const openModal = () => {
    if (user_type_id === 4) {
      updateWorkerAvailability("Idle");
    }
    setModal({
      Component: <ActiveBreak breakType={{ id: 8, name: 'Idle User' }} />,
      size: 'sml',
      canClickAway: false,
      closeBtn: false,
    });
  };

  const incrementInactivityTime = () => {
    setInactivityTime(0); 
  };

  useEffect(() => {
    const inactivityThreshold = 300; // 5 minutes in seconds

    if (inactivityTime >= inactivityThreshold && !currentModal.Component) {
      openModal();
      setInactivityTime(0); // Reset after opening the modal
    }
  }, [inactivityTime, currentModal]);

  useEffect(() => {
    const interval = setInterval(() => {
      setInactivityTime((prev) => prev + 1); // Increment inactivity time
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return {
    seconds,
    minutes,
    hours,
    start,
    pause,
    reset,
    incrementInactivityTime, // Export the new function
  };
};

export { useActivityTracker };
