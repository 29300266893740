import React from "react";
import { Switch, Route, Redirect } from "react-router-dom";
import {
    ADMINISTRATION,
    DASHBOARD,
    MANAGER_DASHBOARD,
    AGENT_CAMPAIGN_DASHBOARD,
    DEBTOR_DASHBOARD,
} from "../constants/pathNames";
import { useAuth } from "../providers/hooks/useAuth";
import { dashboardRoutes } from "../utils/routes";

const DashboardRouter = () => {
    const { hasPermission, user_type_id } = useAuth();
    /*  let isLogin = true;  */
    return (
        <Switch>
            {dashboardRoutes.map(({ path, Component, permission }) =>
                hasPermission(permission) ? (
                    <Route key={path} path={`${DASHBOARD}${path}`} component={Component} exact />
                ) : null,
            )}
            <Redirect from={`${DASHBOARD}${ADMINISTRATION}`} to={`${DASHBOARD}${ADMINISTRATION}/client_manager`} />
            {user_type_id === 4 ? (
                <Redirect to={`${DASHBOARD}${AGENT_CAMPAIGN_DASHBOARD}`} />
            ) : user_type_id === 5 ? (
                <Redirect to={`${DASHBOARD}${DEBTOR_DASHBOARD}`} />
            ) : (
                <Redirect to={`${DASHBOARD}${MANAGER_DASHBOARD}`} />
            )}
        </Switch>
    );
};

export default DashboardRouter;
