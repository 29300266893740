import * as api from "..";
import { EVENT } from "../config/endpoints";

// const EventService = {
//     _url: process.env.REACT_APP_API_BASE_URL,

//     _token(token) {
//         if (typeof token !== 'undefined') {
//             sessionStorage.setItem('token', token);

//             return;
//         }

//         return sessionStorage.getItem('token');
//     },

//     create(params) {
//         // the promise gives you 2 functions to call on success or failure
//         return new Promise((resolve, reject) => {
//             // dont return the fetch, you should use the resolve/reject functions
//             fetch(`${this._url}/events/create`, {

//                 method: 'POST',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 },
//                 body: JSON.stringify(params)
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     resolve(res)
//                 });
//         });
//     },

//     events(params) {
//         // the promise gives you 2 functions to call on success or failure
//         return new Promise((resolve, reject) => {
//             // dont return the fetch, you should use the resolve/reject functions
//             fetch(`${this._url}/events`, {

//                 method: 'POST',
//                 headers: {
//                     'content-type': 'application/json',
//                     authorization: `Bearer ${this._token()}`
//                 },
//                 body: JSON.stringify(params)
//             })
//                 .then(res => res.json())
//                 .then(res => {
//                     if (res.status == "success") {
//                         const returnData = {
//                             data: res.data.events,
//                             count: res.data.count,
//                             status: res.status
//                         };
//                         resolve(returnData)
//                     } else {
//                         resolve(res)
//                     }
//                 });
//         });

//     }
// }

const EventService = {
  async getEventList() {
    const resp = await api.get(EVENT.LIST);
    return resp;
  },
  async create({
    start_time,
    end_time,
    clients,
    debtors,
    note,
    title,
    users,
  }) {
    const resp = await api.post(EVENT.CREATE, ...arguments);
    return resp;
  },
  async update({
    start_time,
    end_time,
    clients,
    debtors,
    note,
    title,
    users,
  }) {
      const resp = await api.post(EVENT.UPDATE, ...arguments);
      return resp;
  },
  async delete({ client_id }) {
      const resp = await api.post(EVENT.DELETE, { client_id });
      return resp;
  },
};

export default EventService;
