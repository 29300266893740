import * as api from "..";
import { LSSM } from "api/config/endpoints";

const LssmService = {
    async fraudMatter({ account_no, fraud_comment, fraud_docs }) {
        const resp = await api.post(LSSM.FRAUD_MATTER, ...arguments);
        return resp;
    },
    async sendMaritalType({ account_no, comment, marital_docs, marital_type }) {
        const resp = await api.post(LSSM.SEND_MARITAL_TYPE, ...arguments);
        return resp;
    },
    async sendSummonsRequested({ account_no, court_name, instruction_type, date_summons_requested }) {
        const resp = await api.post(LSSM.SEND_SUMMONS_REQUESTED, ...arguments);
        return resp;
    },
    async sendWritExecutionRequestDate({ account_no, description, writ_type, date_writ_requested }) {
        const resp = await api.post(LSSM.SEND_WRIT_EXECUTION_REQUEST_DATE, ...arguments);
        return resp;
    },
    async sendDebtorShortTermEmployed({ account_no, description, short_term_emp_start_date, short_term_emp_docs, short_term_emp_end_date }) {
        const resp = await api.post(LSSM.SEND_DEBTOR_SHORT_TERM_EMPLOYED, ...arguments);
        return resp;
    },

    async sendSummonsDocumentsReceived({ account_no, docs_received, summons_docs_received_date }) {
        const resp = await api.post(LSSM.SEND_SUMMONS_DOCUMENTS_RECEIVED, ...arguments);
        return resp;
    },
    async sendRecommendedLegalAction({ account_no, rla_action, rla_reason, rla_comment_1, rla_comment_2 }) {
        const resp = await api.post(LSSM.SEND_RECOMMENDED_LEGAL_ACTION, ...arguments);
        return resp;
    },
    async sendJudgementApplication({ account_no, court_action_type, case_no, court_date, court_place }) {
        const resp = await api.post(LSSM.SEND_JUDGEMENT_APPLICATION, ...arguments);
        return resp;
    },
    async sendDebtorDeceased({ 
        account_no,
        party_two_name,
        party_two_type,
        party_two_address_type,
        party_two_address_line1,
        party_two_address_line2,
        party_two_address_line3,
        party_two_address_city,
        party_two_address_code,
        party_two_telephone_type,
        party_two_telephone_no,
        loe_received,
        loe_received_date,
        cause_of_death,
        death_cert_date,
        dec_spoa,
        dec_attorney,
        dec_attorney_tel,
        surety_exists,
        exec_firm,
        date_exec_appointed,
        exec_email,
        prev_exec_name,
        prev_exec_firm_name,
        prev_date_exec_appointed,
        high_court,
        master_ref,
        master }) 
        {
        const resp = await api.post(LSSM.SEND_DEBTOR_DECEASED, ...arguments);
        return resp;
    },

    async sendDefendedMatter({ 
        account_no,
        case_no,
        court_date,
        amount,
        declaration_filed_date,
        plea_entered_date,
        declaration_received,
        declaration_reply,
        notice_of_bar_issued,
        notice_of_bar_issued_date,
        counter_claim_amount,
        trial_request_date,
        pre_trial_date,
        trial_date_set_date,
        trial_verdict_date,
        rescission_application_rec,
        rescission_reason,
        rescission_rec_date,
        rescission_opposed_status,
        opposing_affidavit_issued,
        rescission_granted, }) 
        {
        const resp = await api.post(LSSM.SEND_DEFENDED_MATTER, ...arguments);
        return resp;
    },


    async sendDebtorUnemployed({ account_no, unemployed_date, unemployed_reason, unemployment_doc_rec}) {
        const resp = await api.post(LSSM.SEND_DEBTOR_UNEMPLOYED, ...arguments);
        return resp;
    },

    async sendDebtorRetrenched({ account_no, retrenched, retrenchment_date, retrenchment_docs}) {
        const resp = await api.post(LSSM.SEND_DEBTOR_RETRENCHED, ...arguments);
        return resp;
    },

    async sendDebtorEmigrated({ account_no, emigrated_to, emigrated_date, emigrated_date_of_return}) {
        const resp = await api.post(LSSM.SEND_DEBTOR_EMIGRATED, ...arguments);
        return resp;
    },

    async sendAdminOrderFaxed({ account_no, date}) {
        const resp = await api.post(LSSM.SEND_ADMIN_ORDER_FAXED, ...arguments);
        return resp;
    },

    async sendReturnOfService({ account_no, return_of_service_date, comment}) {
        const resp = await api.post(LSSM.SEND_RETURN_OF_SERVICE, ...arguments);
        return resp;
    },

    async sendSummonsNotServed({ account_no, comment1, comment2, date_summons_not_served}) {
        const resp = await api.post(LSSM.SEND_SUMMONS_NOT_SERVED, ...arguments);
        return resp;
    },

    async sendWritExecutionIssuedDate({ account_no, description, date_writ_requested }) {
        const resp = await api.post(LSSM.SEND_WRIT_EXECUTION_ISSUED_DATE, ...arguments);
        return resp;
    },
    async sendWritExecutionServed({ account_no, description, date_writ_served, attached_goods_value }) {
        const resp = await api.post(LSSM.SEND_WRIT_EXECUTION_SERVED, ...arguments);
        return resp;
    },
    async sendSummonsServed({ account_no, address_line_1, address_line_2, address_line_3, date_summons_served }) {
        const resp = await api.post(LSSM.SEND_SUMMONS_SERVED, ...arguments);
        return resp;
    },
    async sendReasonForNonPayment({ account_no, non_pmt_reason, non_pmt_comment}) {
        const resp = await api.post(LSSM.SEND_REASON_FOR_NON_PAYMENT, ...arguments);
        return resp;
    },
    async adminSupport({ account_no, subject, text }) {
        const resp = await api.post(LSSM.ADMIN_SUPPORT, ...arguments);
        return resp;
    },
    async acknowledgementOfMatter({ account_no }) {
        const resp = await api.post(LSSM.ACKNOWLEDGEMNT_OF_MATTER, ...arguments);
        return resp;
    },
    async recallConfirmation({ account_no }) {
        const resp = await api.post(LSSM.RECALL_CONFIRMATION, ...arguments);
        return resp;
    },
    async returnMatterToSBSA({ account_no, reason_code }) {
        const resp = await api.post(LSSM.RETURN_MATTER_TO_SBSA, ...arguments);
        return resp;
    },
    async recallReasons() {
        const resp = await api.get(LSSM.RECALL_REASONS);
        return resp.data.recall_reasons;
    },
    async attachmentInformation({ account_no }) {
        const resp = await api.get(LSSM.ATTACHMENT_INFORMATION + `?account_no=${account_no}`);
        return resp.data;
    },

    async sendDebtorOnTrace({ 
        account_no, 
        unable_to_trace,
        trace_method,
        trace_comments,
        }) {
        const resp = await api.post(LSSM.SEND_DEBTOR_ON_TRACE, ...arguments);
        return resp;
    },

    async sendAccountSelectionChoice({ 
        account_no, 
        subject,
        text,
        select_for_collection,
        }) {
        const resp = await api.post(LSSM.SEND_ACCOUNT_SELECTION_CHOICE, ...arguments);
        return resp;
    },
    
    async sendFreeFormatReview({ 
        account_no, 
        type_of_review,
        description,
        subject,
        }) {
        const resp = await api.post(LSSM.SEND_FREE_FORMAT_REVIEW, ...arguments);
        return resp;
    },

    async sendAttachmentInformation({
        date,
        type,
        case_code,
        obtained_by,
        attorney,
        court_name,
        reserve_price_vaf,
        third_party_sale_date,
        account_no,
    }) {
        const resp = await api.post(LSSM.SEND_ATTACHMENT_INFORMATION, ...arguments);
        return resp;
    },

    async sendCustomerInformation({ 
        account_no,
        party_type,
        email,
        occupation,
        address_type,
        address_line1,
        address_line2,
        address_type3,
        employment_period,
        tel_type,
        tel_no,
        employer_party_type,
        employer_party_name,
        employer_email,
        employer_address_type,
        employer_address_line1,
        employer_address_line2,
        employer_address_type3,
        employer_tel_type,
        employer_tel_no,
        salary_type,
        salary_amount,
        salary_proof_status,
        salary_pay_date,
        other_income,
        total_expenditure,
        contact_type,
        contact_comments,
        report_date,
        visit_type,
        visit_comment,
        how_found,
        where_found,
        sect_129_ltr_sent_date }
    ) {
        const resp = await api.post(LSSM.SEND_CUSTOMER_INFORMATION, ...arguments);
        return resp;
    },

    async sendWritOfExecutionNonServiceDate({ 
        account_no, 
        date,
        comment,
        non_service_reason,
        non_service_reason_comment,
         }) {
        const resp = await api.post(LSSM.SEND_WRIT_OF_EXECUTION_NON_SERVICE_DATE, ...arguments);
        return resp;
    },

    async sendJudgementGrantedDetail({ 
        account_no,
        court_action_date,
        case_no,
        court_place,
        court_action_type,
        amount,
        int_rate,
        add_amount1,
        add_int_rate1,
        comment_1,
        comment_2
         }) {
        const resp = await api.post(LSSM.SEND_JUDGEMENT_GRANTED_DETAIL, ...arguments);
        return resp;
    },
    


    async saveCustomerCollectionReport({
        account_no,
        contact_type_id,
        contact_comments,
        report_date,
        visit_type,
        visit_comment,
        how_found,
        where_found,
        sect_129_ltr_sent_date,
    }) {
        const resp = await api.post(LSSM.SAVE_CUSTOMER_COLLECTION_REPORT, ...arguments);
        return resp;
    },

    async getCustomerCollectionReport({ account_no }) {
        const resp = await api.get(LSSM.GET_CUSTOMER_COLLECTION_REPORT + `?account_no=${account_no}`);
        return resp.data.collection_report;
    },
    // transactions
    async getAccountFinancialTransactions({ account_no, ...sort_filter_paginate }) {
        const resp = await api.post(LSSM.GET_ACCOUNT_FINANCIAL_TRANSACTIONS , {
            account_no,
            ...sort_filter_paginate,
        });
        return resp;
    },
};

export default LssmService;
