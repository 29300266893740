import { Grid } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Box, TitleSection } from "../../common/Layouts";
import SearchTextField from "../../components/SearchTextField";
import TableComponent from "../../components/TableComponent";
import style from "./Emails.module.css";
import arrow_icon_left from "../../assets/icons/Layer -23.png";
import arrow_icon_right from "../../assets/icons/Layer -24.png";
import arrow_icon_bold from "../../assets/icons/Group 132.png";
import icon_attach from "../../assets/icons/Layer -25.png";
import icon_delete from "../../assets/icons/Layer -26.png";
import icon_flag from "../../assets/icons/Layer -27.png";
import icon_edit from "../../assets/icons/Group 410.png";
import icon_letter from "../../assets/icons/Group 409.png";
import { CommsService } from "../../api/services";
import { useModal } from "../../providers/hooks/useModal";
import { useAuth } from "../../providers/hooks/useAuth";
import ComposeEmail from "../../components/modal/ComposeEmail";
import ViewEmail from "../../components/modal/ViewEmail";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import ButtonComponent from "../../components/ButtonComponent";
import { OfflineBoltSharp } from "@mui/icons-material";
import { useHistory } from "react-router";

const mockData = {
    date: "15 JAN 2021",
    time: "13:45",
    from: "robson@collectiq.co.za",
    subject: "RE: Payment Plan Agreement",
    "att.": (
        <span className={style.icon_section}>
            <img src={icon_attach} alt="" />
        </span>
    ),
    " ": (
        <span className={style.call_icon_section_arrow}>
            <img src={arrow_icon_left} alt="" />
            <img src={arrow_icon_bold} alt="" />
            <img src={arrow_icon_right} alt="" />
        </span>
    ),
};

const switchList = ["inbox", "flagged", "sent"];

const SwitcherTypesContact = ({ handler, activeType }) => {
    return (
        <div className={style.box_switch}>
            {switchList.map((item) => (
                <div
                    className={`${style.item_switch} ${activeType === item ? style.active_item : ""}`}
                    onClick={() => handler(item)}
                    key={item}
                >
                    <span className={style.text_switch}>{item}</span>
                </div>
            ))}
        </div>
    );
};

function Emails() {
    let listDetail = new Array(12).fill(1).map((item) => mockData);
    let listDetail_1 = new Array(7).fill(1).map((item) => mockData);
    let listDetail_2 = new Array(5).fill(1).map((item) => mockData);
    const agentId = useSelector((state) => state.auth.id);
    const history = useHistory();
    const { hasPermission } = useAuth();
    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
        endpoint: (params) =>
            new Promise((resolve, reject) => {
                CommsService.getAgentEmails(params).then((response) => {
                    resolve({
                        status: response.status,
                        data: response.data.emails[typeEmail].data,
                        count: response.data.emails[typeEmail].count,
                        pagination: response.pagination,
                    });
                });
            }),
    });
    const [emails, setEmails] = useState(null);
    const [typeEmail, setTypeEmail] = useState(switchList[0]);
    const [searchValue, setSearchValue] = useState("");
    const { setModal, setToast } = useModal();

    // const getEmails = async () => {
    //   const resp = await CommsService.getAgentEmails();
    //   if (resp.status === "success") {
    //     console.log('setting emails', resp.data.emails);
    //     setEmails(resp.data.emails);
    //   }
    // };

    // useEffect(() => {
    //   if (!emails) {
    //     getEmails();
    //   }
    // }, []);

    const openComposeModal = () => {
        setModal({
            Component: <ComposeEmail />,
            size: "full",
        });
    };

    const openViewEmailModal = (r) => {
        if (!r.read) {
            setReadEmail({ email_id: r.id });
            setParams({});
        }
        setModal({
            Component: <ViewEmail email={r} />,
            size: "full",
        });
    };

    const handlerSwitch = (type) => {
        setParams({});
        setTypeEmail(type);
    };

    const handleChangeSearch = (e) => {
        setSearchValue(e.target.value);
    };

    const flagEmail = (row) => {
        const email_ids = row.map((r) => {
            console.log("ID", r.id);
            return r.id;
        });

        email_ids.forEach((email_id) => {
            CommsService.flagEmail({ email_id }).then((response) => {
                switch (response.status) {
                    case "success":
                        setToast(response.message, "success");

                        break;
                    case "error":
                        setToast(response.message, "error");

                    default:
                        break;
                }
            });
        });
    };

    const setReadEmail = async ({ email_id }) => {
        const resp = await CommsService.setReadEmail({ email_id });
        console.log(resp);
    };

    return (
        <div style={{ height: "100%" }}>
            <TitleSection>E-mail</TitleSection>
            <Box padding={"25px 30px 30px 27px"} paddingSmall={"20px 25px 25px 23px"}>
                <Grid container direction="column">
                    <Grid container direction="row" alignItems="center" justifyContent="space-between">
                        <Grid item md={3} className={style.top_left}>
                            <img onClick={openComposeModal} src={icon_edit} alt="" />
                            <img src={icon_letter} alt="" onClick={() => setParams({})} />
                        </Grid>
                        {/* <Grid item md={3} className={style.top_right}>
              <Grid
                container
                wrap="nowrap"
                alignItems="center"
                justifyContent="flex-end"
              >
                <div>
                  <img className={style.icon} src={icon_flag} alt="" />
                </div>
                <div>
                  <SearchTextField
                    isClientManager
                    searchValue={searchValue}
                    handleChange={handleChangeSearch}
                  />
                </div>
              </Grid>
            </Grid> */}
                    </Grid>

                    <Grid container spacing={1}>
                        <Grid item>
                            <SwitcherTypesContact activeType={typeEmail} handler={handlerSwitch} />
                        </Grid>

                        <Grid item style={{ flexGrow: 1 }}>
                            {/* {typeEmail === switchList[0] && <TableComponent 
              isCustom
              handlerSelectRow={()=>{}}
              isCheckbox
              data={emails?.inbox}/>}
              {typeEmail === switchList[1] && <TableComponent 
              isCustom
              handlerSelectRow={()=>{}}
              isCheckbox
              data={emails?.flagged}/>}
              {typeEmail === switchList[2] && <TableComponent 
              isCustom
              isCheckbox
              handlerSelectRow={()=>{}}
              data={emails?.sent}/>} */}
                            <CustomMaterialTable
                                title={null}
                                tableRef={tableRef}
                                columns={[
                                    {
                                        title: "",
                                        field: "read",
                                        render: (params) => {
                                            return !params.read ? (
                                                <h2>
                                                    <strong>*</strong>
                                                </h2>
                                            ) : null;
                                        },
                                    },
                                    {
                                        title: "Date",
                                        field: "created_at",
                                        render: (r) => {
                                            const date = new Date(r.created_at);
                                            return date.toUTCString().substr(5, 11);
                                        },
                                    },
                                    {
                                        title: "Time",
                                        field: "created_at",
                                        render: (r) => {
                                            const date = new Date(r.created_at);
                                            return date.toUTCString().substr(16, 6);
                                        },
                                    },
                                    { title: "To", field: "recipient_email" },
                                    { title: "Subject", field: "subject" },
                                ]}
                                data={remoteDataSource}
                                onRowClick={(e, r) => history.push(`/dashboard/emails/view/${r.unique_hash}`)}
                                actions={[
                                    {
                                        icon: "reply",
                                        onClick: (e, row) => {},
                                        render: hasPermission("view-emails"),
                                    },
                                    {
                                        icon: "flag-outlined",
                                        onClick: (e, row) => flagEmail(row),
                                        render: hasPermission("view-emails"),
                                    },
                                ]}
                                options={{
                                    actionsColumnIndex: 6,
                                    search: true,
                                    selection: true,
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </div>
    );
}

export default Emails;
