export const colors = {
  blue: '#002B64',
  gray: '#F0F0F0',
  orange: '#EC7346',
  white: '#FFFFFF',
  green: '#56E166',
  lightGray: '#A8A8A8',
  lightBlue: '#184278',
  red: '#F52C2C',
  purple: '#931A8B',
  darkGreen: '#00A520', 
};

export const listDistances = {
  menuLeft: window.innerWidth < 1440 ? 100 : 129, 
}

export const statusPendingColor = {
  'Query Pending': 'rgba(236, 115, 70, 0.52)',
  '3rd Party': 'rgba(70, 236, 230, 0.52)',
  'Under Administration': 'rgba(70, 121, 236, 0.52)',
  'File Frozen': 'rgba(230, 70, 236, 0.52)',
  'Legal Manager': 'rgba(89, 236, 70, 0.52)'
};
