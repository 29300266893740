import { Box } from "@mui/material";
import React, { useState } from "react";
import styled from "styled-components";
import LssmService from "api/services/lssmService";
import { useMaterialTable } from "../../../../providers/hooks/useMaterialTable";
import CustomMaterialTable from "../../../../components/table/CustomMaterialTable";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const FinancialTransactions = ({ account_no, unsetModal }) => {

    const { remoteDataSource, setParams, tableRef } = useMaterialTable({
		endpoint: (params) =>
			new Promise((resolve, reject) => {
				LssmService.getAccountFinancialTransactions(params).then((response) => {

                    console.log(response);
					resolve({
						status: response.status,
						data: response.data.transactions,
						count: response.data?.count,
						pagination: response?.pagination,
					});
				});
			}),
		initParams: {
			account_no,
		},
	});

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Financial Transactions</Title>
            </Box>
            

            <Box>
                <CustomMaterialTable
                    title={null}
                    tableRef={tableRef}
                    columns={[
                        {
                            title: "Date Issued",
                            field: "date_issued",
                            render: (rowData) => rowData.date_issued // You can format the date here if needed
                        },
                        {
                            title: "Amount",
                            field: "amount",
                        },
                        {
                            title: "Commission Amount",
                            field: "com_amount",
                        },
                        {
                            title: "Type",
                            field: "type",
                        },
                    ]}
                    data={remoteDataSource}
                    options={{
                        actionsColumnIndex: -1,
                        search: false,
                        toolbar: false,
                    }}
                />
            </Box>
        </Box>
    );
};

export default FinancialTransactions;