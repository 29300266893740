import * as api from "..";
import { AGENT } from "../config/endpoints";

// const AgentService = {
//   _url: process.env.REACT_APP_API_BASE_URL,

//   _token(token) {
//     if (typeof token !== "undefined") {
//       sessionStorage.setItem("token", token);

//       return;
//     }

//     return sessionStorage.getItem("token");
//   },

//   agentsManager(verbose, params) {
//     const config = {
//       headers: { Authorization: `Bearer ${this._token()}` },
//     };
//     // the promise gives you 2 functions to call on success or failure
//     return new Promise((resolve, reject) => {
//       // dont return the fetch, you should use the resolve/reject functions
//       // managers/agent_register/{manager_id}
//       fetch(`${this._url}/managers/agent_register`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//           authorization: `Bearer ${this._token()}`,
//         },
//         body: JSON.stringify(params),
//       })
//         .then((res) => res.json())
//         .then((res) => {
//           if (res.status == "success") {
//             let returnData = {};

//             if (verbose) {
//               returnData = {
//                 data: res.data.agents,
//                 count: res.data.count,
//                 status: res.status,
//                 date: Date.now(),
//               };
//             } else {
//               let data = [];
//               Object.entries(res.data.agents).forEach(([key, value]) => {
//                 const clientsNameArr = [];
//                 //const clientsIdsArr = [];

//                 if (value.clients != null) {
//                   Object.entries(value.clients).forEach(
//                     ([keyClient, valueClient]) => {
//                       clientsNameArr.push(valueClient.name);
//                       //clientsIdsArr.push(value.id);
//                     }
//                   );
//                 }

//                 const clientsConcat = clientsNameArr.join(",").toString();
//                 const nameSurname = value.name + " " + value.surname;

//                 data.push({
//                   agents_name: value.name,
//                   manager: value.manager,
//                   assigned_clients: clientsConcat,
//                   //clientsIds: clientsIdsArr
//                 });
//               });
//               returnData = {
//                 data: data,
//                 count: res.data.count,
//                 status: res.status,
//                 date: Date.now(),
//               };
//             }

//             resolve(returnData);
//           } else {
//             resolve(res);
//           }
//         });
//     });
//   },

//   agentSummary(params) {
//     const config = {
//       headers: { Authorization: `Bearer ${this._token()}` },
//     };
//     // the promise gives you 2 functions to call on success or failure
//     return new Promise((resolve, reject) => {
//       // dont return the fetch, you should use the resolve/reject functions
//       fetch(`${this._url}/agents/summary`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//           authorization: `Bearer ${this._token()}`,
//         },
//         body: JSON.stringify(params),
//       })
//         .then((res) => res.json())
//         .then((res) => {
//           if (res.status == "success") {
//             const returnData = {
//               data: res.data.agents,
//               count: res.data.count,
//               status: res.status,
//               date: Date.now(),
//             };
//             resolve(returnData);
//           } else {
//             resolve(res);
//           }
//         });
//     });
//   },
//   /*
//     {
//     "pagination": {
//         "page": 1,
//         "rows_per_page": 4
//     },
//     "sort": {
//         "column_name": "recieved_payments",
//         "order": "DESC"
//     },
//     "filter": [
//         {
//             "column_name": "number",
//             "search_term": "01234579643"
//         }
//     ]
// }
//     */

//   timeLogs(params) {
//     const config = {
//       headers: { Authorization: `Bearer ${this._token()}` },
//     };
//     // the promise gives you 2 functions to call on success or failure
//     return new Promise((resolve, reject) => {
//       // dont return the fetch, you should use the resolve/reject functions
//       fetch(`${this._url}/agents/logout_breaks`, {
//         method: "POST",
//         headers: {
//           "content-type": "application/json",
//           authorization: `Bearer ${this._token()}`,
//         },
//         body: JSON.stringify(params),
//       })
//         .then((res) => res.json())
//         .then((res) => {
//           if (res.status == "success") {
//             const returnData = {
//               data: res.data.times,
//               count: res.data.count,
//               status: res.status,
//               date: Date.now(),
//             };
//             resolve(returnData);
//           } else {
//             resolve(res);
//           }
//         });
//     });
//   },
// };

const AgentService = {
	async agentsManager({ pagination, sort, filter }) {
		const resp = await api.post(AGENT.AGENT_REGISTER, ...arguments);
		return resp;
	},
	async agentSummary({ pagination, sort, filter }) {
		const resp = await api.post(AGENT.SUMMARY, ...arguments);
		return resp;
	},
	async assignClients({ agents_clients }) {
		const resp = await api.post(AGENT.BULK_ASSIGN_CLIENTS, ...arguments);
		return resp;
	},
	async unassignClients({ agent_id, client_ids }) {
		const resp = await api.post(AGENT.UNASSIGN_CLIENTS, ...arguments);
		return resp;
	},
	async timeLogStart({ type }) {
		const resp = await api.post(AGENT.TIME_LOG_START, ...arguments);
		return resp;
	},
	async timeLogStop({ type }) {
		const resp = await api.post(AGENT.TIME_LOG_STOP, ...arguments);
		return resp;
	},
	async getAgentsLogoutBreaks({ pagination, sort, filter }) {
		const resp = await api.post(AGENT.LOGOUT_BREAKS, ...arguments);
		return resp;
	},
	async getTimeLogBreakTypes() {
		const resp = await api.get(AGENT.GET_TIME_LOG_TYPES);
		return resp;
	},
	async getAgentCalls({ agent_id, sort, filter, paginate }) {
		const resp = await api.post(AGENT.CALLS, ...arguments);
		return resp;
	},
	async sendMessage({ number, account_no, message }) {
		const resp = await api.post(AGENT.MESSAGE, ...arguments);
		return resp;
	},
	async sendBulkMessage({ account_no }) {
		const resp = await api.post(AGENT.BULK_MESSAGE, ...arguments);
		return resp;
	},
	async getAgentsCampaignSummary({client_id, sort, filter, paginate }) { //TODO add the right api call parameters and probably change service name too
		const resp = await api.post(AGENT.CAMPAIGN_SUMMARY, ...arguments);
		return resp;
	},

	async checkAgentsCampaignSummary() {
		const resp = await api.post(AGENT.CHECK_AGENTS_CAMPAIGN_SUMMARY);
		return resp;
	},

	async assignCampaignToAgent({client_id}) {
		const resp = await api.post(AGENT.ASSIGN_CAMPAIGN_TO_AGENT, ...arguments);
		return resp;
	},

	async getAvailableClientsFromActiveCampaigns() {
		const resp = await api.get(AGENT.GET_AVAILABLE_CLIENTS_FROM_ACTIVE_CAMPAIGNS);
		return resp;
	},

	async getGraphSummary({client_id}) {
		const resp = await api.post(AGENT.GET_GRAPH_SUMMARY, ...arguments);
		return resp;
	},

	async getCampaignStatistics({client_id}) {
		const resp = await api.post(AGENT.GET_CAMPAIGN_STATISTICS, ...arguments);
		return resp;
	}
};

export default AgentService;
