import React from "react";
import { Box, Typography, Grid } from "@mui/material";
import makeStyles from '@mui/styles/makeStyles';

import { XAxis, YAxis, Tooltip, AreaChart, Area, ResponsiveContainer } from "recharts";

const useStyles = makeStyles({
  dot: {
    marginTop: "4px",
    marginLeft: "15px",
    height: "15px",
    width: "15px",
    backgroundColor: (props) => props.color,
    borderRadius: "50%",
    display: "inline-block",
  },
});

const CustomTooltip = ({ active, payload, label, color, type }) => {
  if (active && payload && payload.length && color) {
    console.log("whats in the payload: ", payload[0].payload.day);
    return (
      <Box
        sx={{
          px: 3,
          py: 1,
          borderRadius: "10px",
          bgcolor: color,
          color: "white",
        }}
      >
        <Typography variant="subtitle1" style={{ fontWeight: "1000" }}>
          {`${payload[0].payload.day} ${payload[0].payload.month} ${payload[0].payload.year}`}
        </Typography>
        <Typography variant="subtitle2">
          {type === "calls" && `${payload[0].value} Calls`}
          {type === "ptps" && `${payload[0].value} Ptps`}
          {type === "money" && `R ${payload[0].value}`}
        </Typography>
      </Box>
    );
  }

  return null;
};

const data = [
  {
    name: "1",
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: "2",
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: "3",
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: "4",
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: "5",
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: "6",
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: "7",
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

export default function Graph(props) {
  const classes = useStyles(props);
  return (
    <div>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <span className={classes.dot}></span>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            {props.type === "calls" && `Calls Made: ${props.data_set?.total}`}
            {props.type === "ptps" && `Ptps Made: ${props.data_set?.total}`}
            {props.type === "money" &&
              `Money Collected: R ${props.data_set?.total}`}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            Target: {props.type === "money" && 'R ' } 
            {`${props.data_set?.target}`}
          </Typography>
        </Grid>
      </Grid>

      <div style={{ width: "100%", height: 150 }}>
        <ResponsiveContainer>
          <AreaChart
            data={props.data_set?.data}
            // syncId="anyId"
            margin={{
              top: 10,
              right: 0,
              left: 0,
              bottom: 0,
            }}
          >
            <defs>
              <linearGradient id={props.color} x1="0" y1="0" x2="0" y2="1">
                <stop offset="5%" stopColor={props.color} stopOpacity={0.8} />
                <stop offset="95%" stopColor={props.color} stopOpacity={0} />
              </linearGradient>
            </defs>
            <XAxis axisLine={false} dataKey="day" />
            <YAxis hide={true} axisLine={false} />
            <Tooltip
              content={<CustomTooltip color={props.color} type={props.type} />}
            />
            <Area
              type="monotone"
              dataKey="value"
              fillOpacity={1}
              stroke={props.color}
              fill={`url(#${props.color})`}
            />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
}
