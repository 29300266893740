import React from "react";
import { TimePicker as TimePickerBase } from '@mui/x-date-pickers/TimePicker';
import { renderTimeViewClock } from '@mui/x-date-pickers/timeViewRenderers';

import styles from "./styles.module.css";

const TimePicker = ({ onChange, name, value, label, ...props }) => {
  return (
    <div className={styles.timePickerContainer}>
      <div className={styles.timePickerTitle}>{label}</div>
      <div className={styles.timePicker}>
        <TimePickerBase
          onChange={onChange}
          name={name}
          value={value}
          sx={{
            width: '100%',
            border: 'none',
            '& .MuiOutlinedInput-root': {
              border: 'none',              
              '& fieldset': {
                border: 'none',

              },
              '& .MuiOutlinedInput-input': {
                padding: '5px', 
              },
            },
           
          }}
       
          viewRenderers={{
            hours: renderTimeViewClock,
            minutes: renderTimeViewClock,
            seconds: renderTimeViewClock,
          }}
          {...props}
        />
      </div>
    </div>
  );
};

export default TimePicker;
