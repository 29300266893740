import { Box, CircularProgress, Typography } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";

const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const RecallConfirmation = ({ account_no, unsetModal, recall_confirmed }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const handleSendConfirmation = () => {
        if (recall_confirmed > 0) {
            setToast("This matter has been sent already", "warning");
            unsetModal();
            return;
        }

        setLoading(true);

        LssmService.recallConfirmation({ account_no }).then((response) => {
            const { data, message, status } = response;

            switch (status) {
                case "success":
                    setToast(message, "success");
                    setLoading(false);
                    unsetModal();

                    break;
                case "error":
                    setToast(message, "error");
                    setLoading(false);
                    unsetModal();

                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={"flex"} alignItems="center" justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={2}>
                <Title>Recall Confirmation</Title>
            </Box>

            <Box mb={2}>
                <Typography variant="body">
                    Click button to confirm recall confirmation for account: {account_no}
                </Typography>
            </Box>

            <Box mb={2}>
                {loading ? (
                    <CircularProgress />
                ) : (
                    <BluePillButton variant="contained" onClick={handleSendConfirmation}>
                        Send Confirmation
                    </BluePillButton>
                )}
            </Box>
        </Box>
    );
};

export default RecallConfirmation;
