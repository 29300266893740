import { Box, CircularProgress, FormGroup, Grid, TextField, FormControlLabel, Switch, Tooltip, IconButton } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import TextArea from "components/inputs/TextArea";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from '@mui/icons-material'; // Import HelpOutlineOutlined icon from Material-UI


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    add_amount1: Yup.string().required("add amount is required"),
    comment_1: Yup.string().required("comment is required"),
    comment_2: Yup.string().required("Comment is required"),
    court_action_date: Yup.date().required("court action date is required"),
    court_place: Yup.string().required("court place is required"),
    case_no: Yup.string().required("case no is required"),
    court_action_type: Yup.string().required("court action type is required"),
    amount: Yup.string()
    .required("Amount is required")
    .matches(/^\d+(\.\d{1,2})?$/, 'Invalid counter claim amount format. Use up to 2 decimal places.'),
    add_int_rate1: Yup.string()
    .required("add int rate1 is required")
    .matches(/^\d+(\.\d{1,2})?$/, 'Invalid counter claim amount format. Use up to 2 decimal places.'),
    int_rate: Yup.string()
    .required("int rate is required")
    .matches(/^\d+(\.\d{1,2})?$/, 'Invalid counter claim amount format. Use up to 2 decimal places.'),
});


const SendJudgementGrantedDetail = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        court_action_date : "",
        case_no : "",
        court_place : "",
        court_action_type : "",
        amount : "",
        int_rate : "",
        add_amount1 : "",
        add_int_rate1 : "",
        comment_1 : "",
        comment_2 : "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendJudgementGrantedDetail = () => {
        setLoading(true);
        LssmService.sendJudgementGrantedDetail({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Sent when the judgement against debtor was granted">
                    <Title>
                        Judgement Granted Detail
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendJudgementGrantedDetail}
                >
                    {({ errors }) => (
                        <Form fullWidth>
    
                            <Grid container spacing={2}>
                              
                                <Grid item xs={12} sm={6}>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                <div className={styles.title}>Case Number</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="case_no"
                                                        name="case_no"
                                                        value={data.case_no}
                                                        error={errors.case_no ? true : false}
                                                        helperText={errors.case_no}
                                                        onChange={handleChange}
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>
                         
                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>Amount</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="amount"
                                                        name="amount"
                                                        value={data.amount}
                                                        error={errors.amount ? true : false}
                                                        helperText={errors.amount}
                                                        onChange={handleChange}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>Interest Rate</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="int_rate"
                                                        name="int_rate"
                                                        value={data.int_rate}
                                                        error={errors.int_rate ? true : false}
                                                        helperText={errors.int_rate}
                                                        onChange={handleChange}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                <div className={styles.title}>additional amount</div>
                                                <TextField
                                                    variant="outlined"
                                                    fullWidth
                                                    id="add_amount1"
                                                    name="add_amount1"
                                                    value={data.add_amount1}
                                                    error={errors.add_amount1 ? true : false}
                                                    helperText={errors.add_amount1}
                                                    onChange={handleChange}
                                                    
                                                />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>additional interest rate</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="add_int_rate1"
                                                        name="add_int_rate1"
                                                        value={data.add_int_rate1}
                                                        error={errors.add_int_rate1 ? true : false}
                                                        helperText={errors.add_int_rate1}
                                                        onChange={handleChange}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>Court place</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="court_place"
                                                        name="court_place"
                                                        value={data.court_place}
                                                        error={errors.court_place ? true : false}
                                                        helperText={errors.court_place}
                                                        onChange={handleChange}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                <div className={styles.title}>Court Action Type</div>
                                                <div style={{ position: "relative", paddingTop: 'auto'}}>
                                                    <select
                                                        fullWidth
                                                        error={errors.court_action_type ? true : false}
                                                        id="court_action_type"
                                                        name="court_action_type"
                                                        helperText={errors.court_action_type}
                                                        className={"select-input-e4"}
                                                        value={data.court_action_type}
                                                        onChange={handleChange}
                                                    >
                                                        <option value="">Select Court action Type</option>
                                                        <option value="J">Judgment</option>
                                                        <option value="D">Defended</option>
                                                        <option value="F">Fraud</option>
                                                        <option value="I">Insolvent</option>
                                                        <option value="JD">Default Judgment</option>
                                                        <option value="JS">Summary Judgment</option>
                                                        <option value="AOA">Admin Order application</option>
                                                        <option value="AOJ">Admin Order Judgment</option>
                                                        <option value="AOR">Admin Order Rescinded</option>
                                                        <option value="AD">Application Judgment default</option>
                                                        <option value="AN">Application Judgment Normal</option>
                                                        <option value="AS">Application Judgment Summary</option>
                                                        <option value="N">Unknown</option>
                                                    </select>
                                                    <span className={style.select_field}>
                                                        <ArrowDown base={13} side={9} color={"#959595"} />
                                                    </span>
                                                </div>
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>Court Action Date</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        type="date"
                                                        id="court_action_date"
                                                        name="court_action_date"
                                                        value={data.court_action_date}
                                                        error={errors.court_action_date ? true : false}
                                                        helperText={errors.court_action_date}
                                                        onChange={handleChange}
                                                        InputLabelProps={{
                                                            shrink: true,
                                                        }}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>Comment 1</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="comment_1"
                                                        name="comment_1"
                                                        value={data.comment_1}
                                                        error={errors.comment_1 ? true : false}
                                                        helperText={errors.comment_1}
                                                        onChange={handleChange}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                    <Box mb={2}>
                                        <FormGroup>
                                            <div className={style.wrapper_input}>
                                                    <div className={styles.title}>Comment 2</div>
                                                    <TextField
                                                        variant="outlined"
                                                        fullWidth
                                                        id="comment_2"
                                                        name="comment_2"
                                                        value={data.comment_2}
                                                        error={errors.comment_2 ? true : false}
                                                        helperText={errors.comment_2}
                                                        onChange={handleChange}
                                                        
                                                    />
                                            </div>
                                        </FormGroup>
                                    </Box>

                                </Grid>
                                </Grid>
  
                            <Box  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <>
                                        <BluePillButton variant="contained" type="submit">
                                            Send Judgement Granted Detail
                                        </BluePillButton>
                                    </>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendJudgementGrantedDetail;
