import React from 'react';
import { Grid, Paper, Typography } from "@mui/material";
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { format } from "date-fns";
import { useFormik } from "formik";
import * as Yup from "yup";
import BluePillButton from "../../../../components/buttons/BluePillButton";
import styles from "./styles.module.css";
import ExportService from "api/services/exportService";

const schema = {
  year: Yup.date().required("Payment date is required."),
};

const Base = ({ children }) => {
  return (
    <Paper className={styles.container}>
      {children}
    </Paper>
  );
};

const Download = ({ dispatch, client_id, title, type }) => {
  const { values, errors, touched, handleSubmit, setFieldValue } = useFormik({
    onSubmit: async (values) => {
      try {
        dispatch({ type: "preparing_download" });
        const response = await ExportService.export({
          client_id: client_id,
          year: format(values.year, "yyyy"),
          type: type,
        });
        if (response.status === "success" && response.data?.file_path != null) {
          const link = document.createElement("a");
          link.href = response.data.file_path;
          link.setAttribute("download", `FileName.pdf`);
          link.setAttribute("target", `_blank`);

          document.body.appendChild(link);
          link.click();
          link.parentNode.removeChild(link);
        } else {
          dispatch({ type: "download_error" });
        }
      } catch (e) {
        dispatch({ type: "download_error" });
        return e;
      }
    },
    validationSchema: Yup.object().shape({
      year: schema.year,
    }),
    initialValues: {
      year: new Date(),
    },
  });

  return (
    <Base dispatch={dispatch}>
      <form noValidate onSubmit={handleSubmit}>
        <Grid container spacing={2} direction="column" alignItems="center">
          <Grid item xs={12}>
            <Typography variant="h3">{title}</Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container justifyContent="center">
              <Grid item>
                <DatePicker
                  label="Select Year"
                  variant="inline"
                  inputVariant="outlined"
                  value={values?.year}
                  onChange={(date) => setFieldValue("year", date ?? new Date())}
                  error={touched.year && Boolean(errors.year)}
                  helperText={touched.year && errors.year}
                  views={["year"]}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <BluePillButton variant="contained" type="submit" fullWidth>
              Download
            </BluePillButton>
          </Grid>
        </Grid>
      </form>
    </Base>
  );
};


const ReportsDownload = {
  KeptPtp: ({ dispatch, client_id }) => (
    <Download title="Kept Ptp Report" dispatch={dispatch} client_id={client_id} type="kept_ptp" />
  ),
  Matrix: ({ dispatch, client_id }) => (
    <Download title="Matrix Report" dispatch={dispatch} client_id={client_id} type="matrix" />
  ),
  CovidCampaign: ({ dispatch, client_id }) => (
    <Download title="Covid Campaign Report" dispatch={dispatch} client_id={client_id} type="covid_campaign" />
  ),
  Weekly: ({ dispatch, client_id }) => (
    <Download title="Weekly Report" dispatch={dispatch} client_id={client_id} type="weekly" />
  ),
  Monthly: ({ dispatch, client_id }) => (
    <Download title="Monthly Report" dispatch={dispatch} client_id={client_id} type="monthly" />
  ),
};

export default ReportsDownload;
