import { Box, CircularProgress, FormGroup, Grid, TextField, FormControlLabel, Switch, Tooltip, IconButton } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import TextArea from "components/inputs/TextArea";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import style from "../../../components/inputs/styles.module.css";
import { ArrowDown } from "../../../common/Layouts";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@mui/icons-material";


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    party_two_name: Yup.string().required("Party two name is required"),
    party_two_address_line1: Yup.string().required("Party two address line 1 is required"),
    party_two_address_line2: Yup.string().required("Party two address line 2 is required"),
    party_two_address_line3: Yup.string().required("Party two address line 3 is required"),
    party_two_address_city: Yup.string().required("Party two address city is required"),
    party_two_address_code: Yup.string().required("Party two address code is required"),
    loe_received_date: Yup.date().required("Letter of executorship received date is required"),
    death_cert_date: Yup.date().required("Death certificate date is required"),
    dec_attorney: Yup.string().required("Decedent's attorney is required"),
    dec_attorney_tel: Yup.string().required("Decedent's attorney telephone number is required"),
    exec_firm: Yup.string().required("Executor's firm is required"),
    date_exec_appointed: Yup.date().required("Date executor appointed is required"),
    exec_email: Yup.string().email("Invalid email format").required("Executor's email is required"),
    prev_exec_name: Yup.string().required("Previous executor name is required"),
    prev_exec_firm_name: Yup.string().required("Previous executor firm name is required"),
    prev_date_exec_appointed: Yup.date().required("Previous date executor appointed is required"),
    high_court: Yup.string().required("High court is required"),
    master_ref: Yup.string().required("Master reference is required"),
    master: Yup.string().required("Master name is required"),
});


const SendDebtorDeceased = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loeReceivedChecked, setLoeReceivedChecked] = useState(false);
    const [decSpoaChecked, setDecSpoaChecked] = useState(false);
    const [suretyExistsChecked, setSuretyExistsChecked] = useState(false);

    const [loading, setLoading] = useState(false);
    const [display, setDisplay] = useState({
        party_one: true,
        party_two: false,
        deceased_information: false
    });

    const [data, setData] = useState({
        account_no,
        party_one_type: "",
        party_one_id_no: "",
        party_one_name: "",
        party_one_address_type: "",
        party_one_address_line1: "",
        party_one_address_line2: "",
        party_one_address_line3: "",
        party_one_address_city: "",
        party_one_address_code: "",
        party_one_marital_status: "",
        party_one_how_married: "I",
        party_two_name: "",
        party_two_type: "",
        party_two_address_type: "",
        party_two_address_line1: "",
        party_two_address_line2: "",
        party_two_address_line3: "",
        party_two_address_city: "",
        party_two_address_code: "",
        party_two_telephone_type: "U",
        party_two_telephone_no: "",
        loe_received: "N",
        loe_received_date: "",
        cause_of_death: "",
        death_cert_date: "",
        dec_spoa: "N",
        dec_attorney: "",
        dec_attorney_tel: "",
        surety_exists: "N",
        exec_firm: "",
        date_exec_appointed: "",
        exec_email: "",
        prev_exec_name: "",
        prev_exec_firm_name: "",
        prev_date_exec_appointed: "",
        high_court: "",
        master_ref: "",
        master: ""
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleLoeReceivedChecked = (e) => {
        setLoeReceivedChecked(e.target.checked);
        !loeReceivedChecked
          ? setData({ ...data, loe_received: "Y"})
          : setData({ ...data, loe_received: "N" });
    };

    const handleDecSpoaChecked= (e) => {
        setDecSpoaChecked(e.target.checked);
        !decSpoaChecked
          ? setData({ ...data, dec_spoa: "Y"})
          : setData({ ...data, dec_spoa: "N" });
    };

    const handleSuretyExistsChecked= (e) => {
        setSuretyExistsChecked(e.target.checked);
        !suretyExistsChecked
          ? setData({ ...data, surety_exists: "Y"})
          : setData({ ...data, surety_exists: "N" });
    };

    

    const handleSendDebtorDeceased = () => {
        setLoading(true);
        LssmService.sendDebtorDeceased({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    const isFormValid = () => {
        for (let key in data) {
            if(data[key] === 'loe_received' || data[key] === 'dec_spoa' || data[key] === 'surety_exists' || data[key] === 'party_one_how_married') continue;
            if (!data[key]) {
                return true; 
            }
        }
        
        return false;
    };

    const isFormPartyOneValid = () => {

        const { 
            party_one_type,
            party_one_id_no,
            party_one_name,
            party_one_address_city,
            party_one_address_code,
            party_one_address_type,
            party_one_address_line1,
            party_one_address_line2,
            party_one_address_line3,
            party_one_marital_status 
        } = data;
    
        const extractedData = {
            party_one_type,
            party_one_id_no,
            party_one_name,
            party_one_address_city,
            party_one_address_code,
            party_one_address_type,
            party_one_address_line1,
            party_one_address_line2,
            party_one_address_line3,
            party_one_marital_status
        };

        for (let key in extractedData) {
            if (!data[key]) {
                return true; 
            }
        }        
        return false;
    };

    const isFormPartyTwoValid = () => {
        const { 
            party_two_name,
            party_two_type,
            party_two_address_type,
            party_two_address_line1,
            party_two_address_line2,
            party_two_address_line3,
            party_two_address_city,
            party_two_address_code,
            party_two_telephone_type,
            party_two_telephone_no 
        } = data;
    
        const extractedData = {
            party_two_name,
            party_two_type,
            party_two_address_type,
            party_two_address_line1,
            party_two_address_line2,
            party_two_address_line3,
            party_two_address_city,
            party_two_address_code,
            party_two_telephone_type,
            party_two_telephone_no 
        };

        for (let key in extractedData) {
            if (!data[key]) {
                return true; 
            }
        }        
        return false;
    };

    const handleAddPartyOne = () => {
        setDisplay({ ...display, party_two: true, party_one: false});
    };

    const handleAddPartyTwo = () => {
        setDisplay({ ...display, party_two: false, deceased_information: true});
        console.log(data);
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Customer is deceased">
                    <Title>
                        Debtor Deceased
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendDebtorDeceased}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            {display.party_one && (
                            
                            <Grid container spacing={2}>

                            <Grid item xs={12} sm={12}>

                            <Box mb={2}  display="flex" justifyContent="center">
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Marital Status</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.party_one_marital_status ? true : false}
                                                id="party_one_marital_status"
                                                name="party_one_marital_status"
                                                helperText={errors.party_one_marital_status}
                                                className={"select-input-e4"}
                                                value={data.party_one_marital_status}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Marital Status</option>
                                                <option value="1">ANC</option>
                                                <option value="2">COP</option>
                                                <option value="3">Divorced</option>
                                                <option value="4">Foreign marriage</option>
                                                <option value="5">Customary</option>
                                                <option value="6">Civil union</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Name</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_one_name"
                                        name="party_one_name"
                                        value={data.party_one_name}
                                        error={errors.party_one_name ? true : false}
                                        helperText={errors.party_one_name}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2} display={false}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Relationship of party to deceased</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.party_one_type ? true : false}
                                                id="party_one_type"
                                                name="party_one_type"
                                                helperText={errors.party_one_type}
                                                className={"select-input-e4"}
                                                value={data.party_one_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Relationship of Party to Deceased</option>
                                                <option value="A">Primary</option>
                                                <option value="B">Secondary</option>
                                                <option value="C">Co-maker</option>
                                                <option value="D">Surety</option>
                                                <option value="E">Executor</option>
                                                <option value="F">Tenant</option>
                                                <option value="G">Trustee</option>
                                                <option value="H">Liquidator</option>
                                                <option value="I">Other-5</option>
                                                <option value="J">Other-6</option>
                                                <option value="K">Other-7</option>
                                                <option value="L">Other-8</option>
                                                <option value="M">recomOther-9</option>
                                                <option value="N">Consumer Credit Counseling</option>
                                                <option value="O">Guarantor</option>
                                                <option value="Q">Administrator</option>
                                                <option value="R">Request</option>
                                                <option value="S">Spouse</option>
                                                <option value="T">Trace</option>
                                                <option value="U">Lien Holder</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>
                       
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                <div className={styles.title}>ID Number</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_one_id_no"
                                        name="party_one_id_no"
                                        value={data.party_one_id_no}
                                        error={errors.party_one_id_no ? true : false}
                                        helperText={errors.party_one_id_no}
                                        onChange={handleChange}
                                        
                                    />
                                </div>
                            </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Line 1</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_one_address_line1"
                                        name="party_one_address_line1"
                                        value={data.party_one_address_line1}
                                        error={errors.party_one_address_line1 ? true : false}
                                        helperText={errors.party_one_address_line1}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Line 2</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_one_address_line2"
                                        name="party_one_address_line2"
                                        value={data.party_one_address_line2}
                                        error={errors.party_one_address_line2 ? true : false}
                                        helperText={errors.party_one_address_line2}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Line 3</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_one_address_line3"
                                        name="party_one_address_line3"
                                        value={data.party_one_address_line3}
                                        error={errors.party_one_address_line3 ? true : false}
                                        helperText={errors.party_one_address_line3}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Party Address Type</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.party_one_address_type ? true : false}
                                                id="party_one_address_type"
                                                name="party_one_address_type"
                                                helperText={errors.party_one_address_type}
                                                className={"select-input-e4"}
                                                value={data.party_one_address_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Address Type</option>
                                                <option value="B">Business</option>
                                                <option value="D">Domicilium</option>
                                                <option value="H">Home</option>
                                                <option value="O">Other</option>
                                                <option value="P">Postal</option>
                                                <option value="S">Street</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address City</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_one_address_city"
                                        name="party_one_address_city"
                                        value={data.party_one_address_city}
                                        error={errors.party_one_address_city ? true : false}
                                        helperText={errors.party_one_address_city}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Code</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_one_address_code"
                                        name="party_one_address_code"
                                        value={data.party_one_address_code}
                                        error={errors.party_one_address_code ? true : false}
                                        helperText={errors.party_one_address_code}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            </Grid>

                            )}
                            {display.party_two && (
                            
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Name</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_two_name"
                                        name="party_two_name"
                                        value={data.party_two_name}
                                        error={errors.party_two_name ? true : false}
                                        helperText={errors.party_two_name}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2} display={false}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Relationship of party to deceased</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.party_two_type ? true : false}
                                                id="party_two_type"
                                                name="party_two_type"
                                                helperText={errors.party_two_type}
                                                className={"select-input-e4"}
                                                value={data.party_two_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Relationship of Party to Deceased</option>
                                                <option value="A">Primary</option>
                                                <option value="B">Secondary</option>
                                                <option value="C">Co-maker</option>
                                                <option value="D">Surety</option>
                                                <option value="E">Executor</option>
                                                <option value="F">Tenant</option>
                                                <option value="G">Trustee</option>
                                                <option value="H">Liquidator</option>
                                                <option value="I">Other-5</option>
                                                <option value="J">Other-6</option>
                                                <option value="K">Other-7</option>
                                                <option value="L">Other-8</option>
                                                <option value="M">recomOther-9</option>
                                                <option value="N">Consumer Credit Counseling</option>
                                                <option value="O">Guarantor</option>
                                                <option value="Q">Administrator</option>
                                                <option value="R">Request</option>
                                                <option value="S">Spouse</option>
                                                <option value="T">Trace</option>
                                                <option value="U">Lien Holder</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>
                       
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                <div className={styles.title}>Party Telephone Number</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_two_telephone_no"
                                        name="party_two_telephone_no"
                                        value={data.party_two_telephone_no}
                                        error={errors.party_two_telephone_no ? true : false}
                                        helperText={errors.party_two_telephone_no}
                                        onChange={handleChange}
                                        
                                    />
                                </div>
                            </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Line 1</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_two_address_line1"
                                        name="party_two_address_line1"
                                        value={data.party_two_address_line1}
                                        error={errors.party_two_address_line1 ? true : false}
                                        helperText={errors.party_two_address_line1}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Line 2</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_two_address_line2"
                                        name="party_two_address_line2"
                                        value={data.party_two_address_line2}
                                        error={errors.party_two_address_line2 ? true : false}
                                        helperText={errors.party_two_address_line2}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Line 3</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_two_address_line3"
                                        name="party_two_address_line3"
                                        value={data.party_two_address_line3}
                                        error={errors.party_two_address_line3 ? true : false}
                                        helperText={errors.party_two_address_line3}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Party Address Type</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.party_two_address_type ? true : false}
                                                id="party_two_address_type"
                                                name="party_two_address_type"
                                                helperText={errors.party_two_address_type}
                                                className={"select-input-e4"}
                                                value={data.party_two_address_type}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Address Type</option>
                                                <option value="B">Business</option>
                                                <option value="D">Domicilium</option>
                                                <option value="H">Home</option>
                                                <option value="O">Other</option>
                                                <option value="P">Postal</option>
                                                <option value="S">Street</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address City</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_two_address_city"
                                        name="party_two_address_city"
                                        value={data.party_two_address_city}
                                        error={errors.party_two_address_city ? true : false}
                                        helperText={errors.party_two_address_city}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={6}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Party Address Code</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="party_two_address_code"
                                        name="party_two_address_code"
                                        value={data.party_two_address_code}
                                        error={errors.party_two_address_code ? true : false}
                                        helperText={errors.party_two_address_code}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            </Grid>

                            )}
                            {display.deceased_information && (
                            <>
                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>


                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={loeReceivedChecked} color="primary" onChange={handleLoeReceivedChecked} />}
                                        label="Letter of Executorship Received?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>Cause of Death</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.cause_of_death ? true : false}
                                                id="cause_of_death"
                                                name="cause_of_death"
                                                helperText={errors.cause_of_death}
                                                className={"select-input-e4"}
                                                value={data.cause_of_death}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Cause of Death</option>
                                                <option value="1">Unknown</option>
                                                <option value="2">Natural Causes</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Letter of Executorship Received Date</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="loe_received_date"
                                        name="loe_received_date"
                                        value={data.loe_received_date}
                                        error={errors.loe_received_date ? true : false}
                                        helperText={errors.loe_received_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={decSpoaChecked} color="primary" onChange={handleDecSpoaChecked} />}
                                        label="Special Power Of Attorney?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Date Death Certificate received</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="death_cert_date"
                                        name="death_cert_date"
                                        value={data.death_cert_date}
                                        error={errors.death_cert_date ? true : false}
                                        helperText={errors.death_cert_date}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Previous Executor Name</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="prev_exec_name"
                                        name="prev_exec_name"
                                        value={data.prev_exec_name}
                                        error={errors.prev_exec_name ? true : false}
                                        helperText={errors.prev_exec_name}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                   
                            <Grid item xs={12} sm={4}>

                            <Box mb={2} display="flex" justifyContent="center">
                                <FormGroup>
                                    <FormControlLabel
                                        control={<Switch checked={suretyExistsChecked} color="primary" onChange={handleSuretyExistsChecked} />}
                                        label="Does a surety exist?"
                                    />
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                                <FormGroup>
                                    <div className={style.wrapper_input}>
                                        <div className={styles.title}>High Court</div>
                                        <div style={{ position: "relative", paddingTop: 'auto'}}>
                                            <select
                                                fullWidth
                                                error={errors.high_court ? true : false}
                                                id="high_court"
                                                name="high_court"
                                                helperText={errors.high_court}
                                                className={"select-input-e4"}
                                                value={data.high_court}
                                                onChange={handleChange}
                                            >
                                                <option value="">Select Court Name</option>
                                                <option value="ECBHC">ECBHC (Bhisho)</option>
                                                <option value="FSHC">FSHC (Bloemfontein)</option>
                                                <option value="WCH">WCH (Cape Town)</option>
                                                <option value="KZDHC">KZDHC (Durban)</option>
                                                <option value="ECGHC">ECGHC (Grahamstown)</option>
                                                <option value="GPJHC">GPJHC (Johannesburg)</option>
                                                <option value="NCHC">NCHC (Kimberley)</option>
                                                <option value="KZPHC">KZPHC (Pietermaritzburg)</option>
                                                <option value="ECHC">ECHC (Port Elizabeth)</option>
                                                <option value="NGHC">NGHC (Pretoria)</option>
                                                <option value="LMPHC">LMPHC (Thohoyandou)</option>
                                                <option value="ECMHC">ECMHC (Mthatha)</option>
                                                <option value="NWHC">NWHC (Mmabatho/Mafikeng)</option>
                                            </select>
                                            <span className={style.select_field}>
                                                <ArrowDown base={13} side={9} color={"#959595"} />
                                            </span>
                                        </div>
                                    </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Date Executor Appointed</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="date_exec_appointed"
                                        name="date_exec_appointed"
                                        value={data.date_exec_appointed}
                                        error={errors.date_exec_appointed ? true : false}
                                        helperText={errors.date_exec_appointed}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>


                            </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                            <Grid item xs={12} sm={4}>


                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Deceased Attorney Telephone Number</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="dec_attorney_tel"
                                        name="dec_attorney_tel"
                                        value={data.dec_attorney_tel}
                                        error={errors.dec_attorney_tel ? true : false}
                                        helperText={errors.dec_attorney_tel}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>
                         
                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Deceased Attorney</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="dec_attorney"
                                        name="dec_attorney"
                                        value={data.dec_attorney}
                                        error={errors.dec_attorney ? true : false}
                                        helperText={errors.dec_attorney}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Previous Executor Firm Name</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="prev_exec_firm_name"
                                        name="prev_exec_firm_name"
                                        value={data.prev_exec_firm_name}
                                        error={errors.prev_exec_firm_name ? true : false}
                                        helperText={errors.prev_exec_firm_name}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Previous Date Executor Appointed</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        type="date"
                                        id="prev_date_exec_appointed"
                                        name="prev_date_exec_appointed"
                                        value={data.prev_date_exec_appointed}
                                        error={errors.prev_date_exec_appointed ? true : false}
                                        helperText={errors.prev_date_exec_appointed}
                                        onChange={handleChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Executor Firm</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="exec_firm"
                                        name="exec_firm"
                                        value={data.exec_firm}
                                        error={errors.exec_firm ? true : false}
                                        helperText={errors.exec_firm}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Executor Email</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="exec_email"
                                        name="exec_email"
                                        value={data.exec_email}
                                        error={errors.exec_email ? true : false}
                                        helperText={errors.exec_email}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>

                            <Box mb={2}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Master Reference</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="master_ref"
                                        name="master_ref"
                                        value={data.master_ref}
                                        error={errors.master_ref ? true : false}
                                        helperText={errors.master_ref}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>

                            </Grid>
                            <Grid item xs={12} sm={4}>
                            
                            <Box mb={4}>
                            <FormGroup>
                            <div className={style.wrapper_input}>
                                    <div className={styles.title}>Master</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="master"
                                        name="master"
                                        value={data.master}
                                        error={errors.master ? true : false}
                                        helperText={errors.master}
                                        onChange={handleChange}
                                        
                                    />
                               </div>
                                </FormGroup>
                            </Box>
                            </Grid>
                            </Grid>
                            </>
                            )}
                         

                            <Box  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <>

                                    {display.party_one && (
                                    <BluePillButton variant="contained" onClick={handleAddPartyOne} disabled={isFormPartyOneValid()}>
                                        Add First Party
                                     </BluePillButton>
                                    )}

                                    {display.party_two && (
                                     <BluePillButton variant="contained" onClick={handleAddPartyTwo} disabled={isFormPartyTwoValid()}>
                                        Add Second Party
                                     </BluePillButton>
                                    )}
                                    {display.deceased_information && (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Debtor Deceased
                                    </BluePillButton>
                                     )}
                                    </>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendDebtorDeceased;
