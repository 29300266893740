import React from "react";
import { Snackbar, Alert } from "@mui/material";
import { useSelector } from "react-redux";
import { useModal } from "../../providers/hooks/useModal";

const BasicToast = () => {
    const message = useSelector((state) => state.nav.toast.message);
    const type = useSelector((state) => state.nav.toast.type);

    const { unsetToast } = useModal();

    return message ? (
        <Snackbar
            open={!!message}
            autoHideDuration={6000}
            onClose={unsetToast}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
            <Alert onClose={unsetToast} severity={type} variant="filled">
                {message}
            </Alert>
        </Snackbar>
    ) : null;
};

export default BasicToast;
