import { useVoip } from 'providers/hooks/useVoip';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AgentService, AuthService } from '../../../api/services';
import { useActivityTracker } from '../../../providers/hooks/useActivityTracker';
import { useModal } from '../../../providers/hooks/useModal';
import ButtonComponent from '../../ButtonComponent';

const ActiveBreak = ({ breakType }) => {
    const { updateWorkerAvailability } = useVoip();
    const { unsetModal } = useModal();
    let t = { id: 8, name: 'Idle User' };
    const { seconds, minutes, hours } = useActivityTracker();
    const [timeLeft, setTimeLeft] = useState(300); 
    const user_type_id = useSelector((state) => state.auth.user_type_id);
    let interval = null;

    const startBreak = async () => {
        if(user_type_id === 4){
            const resp = await AgentService.timeLogStart({ type: breakType.id });
            if (resp.status === 'success') {

            }
        }
    };

    const endBreak = async () => {
        if(user_type_id === 4){
            const resp = await AgentService.timeLogStop({ type: breakType.id });
            if (resp.status === 'success') {
                updateWorkerAvailability("Online");
                unsetModal();
            }
        }else{
            unsetModal();
        }
    };

    const onLogout = async () => {
        const resp = await AuthService.logout();
        if (resp.status === "success") {
            updateWorkerAvailability("Offline");
            sessionStorage.clear();
            // Redirect to login or handle login state
            window.location.href = "/login"; 
            unsetModal();
        }
    };

    useEffect(() => {
        startBreak();

        interval = setInterval(() => {
            setTimeLeft((prev) => {
                if (prev <= 1) {
                    clearInterval(interval);
                    onLogout(); // Trigger the onLogout when time is up
                    return 0;
                }
                return prev - 1;
            });
        }, 1000);

        return () => {
            clearInterval(interval);
            if (minutes > 0 || seconds > 0) {
                endBreak();
            }
        };
    }, []);

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = time % 60;
        return `${minutes < 10 ? '0' : ''}${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
    };

    return (
        <div className={'p-3 flex col ai-c jc-sb'} style={{ minWidth: 300 }}>
            <h1>{breakType ? breakType.name : t.name}</h1>
            <p style={{ fontSize: 18, color: 'red' }}>
                Your session will expire in ... 
            </p>
            <p style={{ fontSize: 36 }}>{formatTime(timeLeft)}</p>
            <p style={{ fontSize: 18, color: 'red' }}>
                Please take action to stay logged in.
            </p>

            <ButtonComponent width={280} variant={'outlined'} title={'End Break'} handler={endBreak} />
        </div>
    );
};

export default ActiveBreak;

