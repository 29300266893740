import React from 'react';
import Modal from '@mui/material/Modal';
import Fade from '@mui/material/Fade';
import { styled } from '@mui/material/styles';

// Function to generate random position offsets
function rand() {
  return Math.round(Math.random() * 20) - 10;
}

// Function to get modal style
function getModalStyle() {
  const top = 50 + rand();
  const left = 50 + rand();

  return {
    top: '50%',
    left: '50%',
    transform: `translate(-${top}%, -${left}%)`,
    position: 'absolute',
  };
}

// Styled component for the modal paper
const ModalPaper = styled('div')(({ theme, width }) => ({
  position: 'absolute',
  width: width || '100%',
  backgroundColor: '#fff',
  borderRadius: '12px',
  outline: 'none',
  border: 'none',
  fontFamily: 'Avenir Next Bold',
}));

export default function CommonModal({
  children,
  open,
  handleClose,
  width = '100%',
}) {
  const [modalStyle] = React.useState(getModalStyle);

  return (
    <Modal
      open={open}
      onClose={handleClose}
      BackdropProps={{
        style: {
          backdropFilter: "blur(3px)",
          backgroundColor: 'rgba(0, 0, 0, 0.6)',
        },
      }}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
    >
      <Fade in={open}>
        <ModalPaper style={modalStyle} width={width}>
          {children}
        </ModalPaper>
      </Fade>
    </Modal>
  );
}
