import { Box, CircularProgress, TextField, FormGroup, Tooltip, IconButton } from "@mui/material";
import BluePillButton from "components/buttons/BluePillButton";
import React, { useState } from "react";
import styled from "styled-components";
import { useModal } from "providers/hooks/useModal";
import LssmService from "api/services/lssmService";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import styles from "./CollectionReport/styles.module.css";
import { HelpOutlineOutlined } from "@mui/icons-material";


const Title = styled.div`
    font-weight: bold;
    font-size: 16px;
    font-family: "Avenir Next Bold";
    span {
        font-weight: normal;
        font-family: "Avenir Next Medium";
    }
    @media (min-width: 1920px) {
        font-size: 20px;
    }
`;

const validationSchema = Yup.object().shape({
    account_no: Yup.string(),
    non_pmt_reason: Yup.string().required("Non payment reason is a required field"),
    non_pmt_comment: Yup.string().required("Non payment comment is a required field"),
});

const SendReasonForNonPayment = ({ account_no, unsetModal }) => {
    const { setToast } = useModal();
    const [loading, setLoading] = useState(false);

    const [data, setData] = useState({
        account_no,
        non_pmt_reason: "",
        non_pmt_comment: "",
    });

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value });
    };

    const handleSendReasonForNonPayment = () => {
        setLoading(true);
        LssmService.sendReasonForNonPayment({
            ...data
        }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.message, "success");
                    setLoading(false);
                    unsetModal();
                    break;
                case "error":
                    setToast(response.message, "error");
                    break;
                default:
                    break;
            }
        });
    };

    return (
        <Box display={"flex"} justifyContent={"center"} flexDirection="column" padding={"2em"}>
            <Box mb={6} display={"flex"} justifyContent={"center"} >
                <Tooltip title="Send other reason for debtor’s non-payment">
                    <Title>
                        Reason For Non-Payment
                        <IconButton size="small">
                            <HelpOutlineOutlined fontSize="small" />
                        </IconButton>
                    </Title>
                </Tooltip>
            </Box>

            <Box>
                <Formik
                    initialValues={data}
                    enableReinitialize
                    validationSchema={validationSchema}
                    onSubmit={handleSendReasonForNonPayment}
                >
                    {({ errors }) => (
                        <Form fullWidth>
                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Reason</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="non_pmt_reason"
                                        name="non_pmt_reason"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.non_pmt_reason}
                                        error={errors.non_pmt_reason ? true : false}
                                        helperText={errors.non_pmt_reason}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>    

                            <Box mb={2} paddingX={20}>
                                <FormGroup>
                                    <div className={styles.title}>Comment</div>
                                    <TextField
                                        variant="outlined"
                                        fullWidth
                                        id="non_pmt_comment"
                                        name="non_pmt_comment"
                                        customStyle={{
                                            justifyContent: "flex-start",
                                        }}
                                        value={data.non_pmt_comment}
                                        error={errors.non_pmt_comment ? true : false}
                                        helperText={errors.non_pmt_comment}
                                        onChange={handleChange}
                                        isSearch={false}
                                    />
                                </FormGroup>
                            </Box>  
                          
                            <Box mb={2}  display="flex" justifyContent="center">       
                                {loading ? (
                                    <div style={{padding: "3px 30px"}}><CircularProgress /></div>
                                ) : (
                                    <BluePillButton variant="contained" type="submit">
                                        Send Reason For Non-Payment
                                    </BluePillButton>
                                )}
                            </Box>
                        </Form>
                    )}
                </Formik>
            </Box>
        </Box>
    );
};

export default SendReasonForNonPayment;
