import { Box } from "@mui/material";
import { UserService } from "api/services";
import BluePillButton from "components/buttons/BluePillButton";
import { useModal } from "providers/hooks/useModal";
import React, { useState } from "react";

const BulkDebtorPortal = ({ accounts, client_id }) => {
    const [showMore, setShowMore] = useState(false);
    const { setToast, unsetModal } = useModal();

    const style = {
        margin: "1em",
        color: "midnightblue",
    };

    const createAccount = () => {
        const debtor_ids = accounts.map((attr) => {
            return attr.id;
        });

        UserService.bulkCreateUserDebtor({ debtor_ids }).then((response) => {
            switch (response.status) {
                case "success":
                    setToast(response.data.message, "success");
                    unsetModal();
                    break;
                case "error":
                    if (response.data.error) {
                        setToast(
                            response.data.error?.email +
                                " " +
                                (response.data.error?.debtor_id ? response.data.error.debtor_id : "") +
                                " For " +
                                response.data.Debtor,
                            "error",
                        );
                    } else {
                        setToast(response.message, "error");
                    }
                    unsetModal();
                default:
                    break;
            }
        });
    };

    return (
        <Box m={8} maxHeight={"60vh"} overflow={"scroll"}>
            <h1 style={style}>Create Debtor Portal</h1>

            <Box>
                {accounts.slice(0, 5).map((account) => (
                    <Box mb={1} borderBottom={"1px solid lightgray"}>
                        <p>
                            <span style={{ fontWeight: "bold" }}>Client:</span> {account.debtor_name}
                        </p>
                        {/* <p>
                            <span style={{ fontWeight: "bold" }}>Account No:</span> {account.account_number}
                        </p> */}
                    </Box>
                ))}
                {showMore &&
                    accounts.slice(5).map((account) => (
                        <Box mb={1} borderBottom={"1px solid lightgray"}>
                            <p>
                                <span style={{ fontWeight: "bold" }}>Client:</span> {account.debtor_name}
                            </p>
                            {/* <p>
                                <span style={{ fontWeight: "bold" }}>Account No:</span> {account.account_number}
                            </p> */}
                        </Box>
                    ))}
                {accounts.length > 5 &&
                    (!showMore ? (
                        <p
                            onClick={() => setShowMore(true)}
                            style={{ textAlign: "center", color: "steelblue", cursor: "pointer" }}
                        >
                            show {accounts.slice(5).length} more
                        </p>
                    ) : (
                        <p
                            onClick={() => setShowMore(false)}
                            style={{ textAlign: "center", color: "steelblue", cursor: "pointer" }}
                        >
                            show less
                        </p>
                    ))}
            </Box>
            <Box display={"flex"} alignItems={"center"} justifyContent={"center"}>
                <BluePillButton onClick={createAccount}>Create Account</BluePillButton>
            </Box>
        </Box>
    );
};

export default BulkDebtorPortal;
