import React from "react";
import { Button, Menu, MenuItem, Toolbar } from "@mui/material";
import file_icon from "../assets/icons/FontAwsome (link).png";
import { useModal } from "providers/hooks/useModal";
import ReportsDownload from "./modal/content/ReportsDownload";

function ReportsDownloadMenu({ client_id }) {
    const { setModal, unsetModal, setToast } = useModal();
    const [menuAnchor, setMenuAnchor] = React.useState();

    const handleModalEvent = ({ type, payload = undefined }) => {
        console.log("handleModalEvent", type);
        switch (type) {
            case "preparing_download":
                unsetModal();
                setToast("Preparing download...", "info");
                break;
            case "download_error":
                unsetModal();
                setToast("We ran into an error fetching this report.", "error");
                break;
            case "download":
                setToast("Downloading...", "info");
                break;
            default:
                unsetModal();
        }
    };
    const handleNewPtp = (e) => {
        console.log(e);
        setMenuAnchor(e.target);
    };
    const handleReportTypeSelect = (e) => {
        setMenuAnchor(false);
        switch (e.target.id) {
            case "kept-ptp":
                setModal({
                    Component: <ReportsDownload.KeptPtp client_id={client_id} dispatch={handleModalEvent} />,
                    size: "sml",
                });
                break;
            case "matrix":
                setModal({
                    Component: <ReportsDownload.Matrix client_id={client_id} dispatch={handleModalEvent} />,
                    size: "sml",
                });
                break;
            case "covid-campaign":
                setModal({
                    Component: <ReportsDownload.CovidCampaign client_id={client_id} dispatch={handleModalEvent} />,
                    size: "sml",
                });
                break;
            case "weekly":
                setModal({
                    Component: <ReportsDownload.Weekly client_id={client_id} dispatch={handleModalEvent} />,
                    size: "sml",
                });
                break;
            case "monthly":
                setModal({
                    Component: <ReportsDownload.Monthly client_id={client_id} dispatch={handleModalEvent} />,
                    size: "sml",
                });
                break;
            case "other":
                break;
            default:
                break;
        }
    };

    return (
        <div>
            <Toolbar>
                <Button
                    disabled={client_id == null}
                    onClick={handleNewPtp}
                    endIcon={<img src={file_icon} alt="download" />}
                >
                    Reports
                </Button>
                <Menu
                    anchorEl={menuAnchor}
                    open={Boolean(menuAnchor)}
                    keepMounted
                    onClose={() => setMenuAnchor(undefined)}
                >
                    <MenuItem id="matrix" onClick={handleReportTypeSelect}>
                        Matrix Report
                    </MenuItem>
                    <MenuItem id="kept-ptp" onClick={handleReportTypeSelect}>
                        Kept PTP Report
                    </MenuItem>
                    <MenuItem id="covid-campaign" onClick={handleReportTypeSelect}>
                        Covid Campaign Report
                    </MenuItem>
                    <MenuItem id="weekly" onClick={handleReportTypeSelect}>
                        Weekly Report
                    </MenuItem>
                    <MenuItem id="monthly" onClick={handleReportTypeSelect}>
                        Monthly Report
                    </MenuItem>
                </Menu>
            </Toolbar>
        </div>
    );
}

export default ReportsDownloadMenu;
