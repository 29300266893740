import React, { useEffect, useState } from "react";
import { useMaterialTable } from "../../providers/hooks/useMaterialTable";
import CustomMaterialTable from "../../components/table/CustomMaterialTable";
import { useModal } from "../../providers/hooks/useModal";
import { AGENT_PTP_OVERRIDE_FIELDS } from "../../constants/tableColumns";
import DebtorCasePtpService from "api/services/debtorCasePtpService";
import { Typography } from "@mui/material";
import { useHistory } from "react-router-dom";
import { DEBTOR, DASHBOARD } from "../../constants/pathNames";

function PtpOverridesList() {
  const { unsetModal } = useModal();
  const history = useHistory();

  const { remoteDataSource, setParams, tableRef } = useMaterialTable({
    endpoint: (params) =>
      new Promise((resolve) => {
        DebtorCasePtpService.agentPtpOverrides(params).then((response) => {
          resolve({
            status: response.status,
            data: response.data?.overrides,
            count: response.data?.count,
            pagination: response.pagination,
          });
        });
      }),
    initParams: {},
  });

  useEffect(() => {
    setParams({});
  },[]);

  const handleRowClick = (event, row) => {
    const location = {
      pathname: `${DASHBOARD}${DEBTOR}/${row.debtor_name}`,
      state: {
        debtor_name: row.debtor_name,
        account_number: row.debtor_case_id,
      },
    };
    history.push(location);
    unsetModal();
  };

  return (
    <div className={'p-2 flex col ai-c jc-sb'} style={{minWidth: 300}}>
      <Typography variant="h4"> PTP Override Status List</Typography>
      <CustomMaterialTable
        title={null}
        tableRef={tableRef}
        columns={AGENT_PTP_OVERRIDE_FIELDS}
        data={remoteDataSource}
        onRowClick={handleRowClick}
        options={{
          actionsColumnIndex: -1,
          pageSize: 10,
          search: false,
        }}
      />
    </div>
  );
}

export default PtpOverridesList;
