import * as api from "..";
import * as validation from "../../utils/validation";
import { AUTH } from "../config/endpoints";

// const AuthService = {
//     _url: process.env.REACT_APP_API_BASE_URL,

//     _validateStringField(field, value) {
//         if (typeof value !== 'string' || !value.trim().length)
//             throw Error(`${field} is not valid`);
//     },

//     _validateEmail(email) {
//         if (!validateEmail(email)) throw Error(`${email} is not a valid email`);
//     },

//     _userId(userId) {
//         if (typeof userId !== 'undefined') {
//             sessionStorage.setItem('userId', userId);

//             return;
//         }

//         return sessionStorage.getItem('userId');
//     },

//     _token(token) {
//         if (typeof token !== 'undefined') {
//             sessionStorage.setItem('token', token);

//             return;
//         }

//         return sessionStorage.getItem('token');
//     },

//     isLoggedIn() {
//
//     },

//
// }

const AuthService = {
  register: async ({name, email, password}) => {
    const resp = await api.post(AUTH.REGISTER, { name, email, password });
    return resp;
  },
  async login({email, password}) {
    const resp = await api.post(AUTH.LOGIN, ...arguments);
    console.log(...arguments);
    return resp;
  },
  getUserFromToken: async () => {
    const resp = await api.get(AUTH.GET_USER_FROM_TOKEN);
    return resp;
  },
  logout: async () => {
    const resp = await api.post(AUTH.LOGOUT);
    return resp;
  },
  sendResetPasswordLink: async (email) => {
    const resp = await api.post(AUTH.RESET_PASS_INIT, { email });
    return resp;
  },
  resetPassword: async (email, password, password_confirmation, token) => {
    const resp = await api.post(AUTH.RESET_PASS, { email, password, password_confirmation, token });
    return resp;
  },
  isLoggedIn: () => {
    const resp = !!(validation.validateForSessionStorage('userId') && validation.validateForSessionStorage('token'));
    return resp;
  },
};

export default AuthService;
