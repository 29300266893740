const AGENT = {
    SUMMARY: "agents/summary",
    BULK_ASSIGN_CLIENTS: "agents/bulk_assign_clients",
    UNASSIGN_CLIENTS: "agents/unassign_clients_from_agent",
    LOGOUT_BREAKS: "agents/logout_breaks",
    AGENT_REGISTER: "managers/agent_register",
    TIME_LOG_START: "agents/time_logs/start",
    TIME_LOG_STOP: "agents/time_logs/stop",
    GET_TIME_LOG_TYPES: "agents/time_logs/types",
    CALLS: "agents/calls",
    MESSAGE: "sms/send",
    BULK_MESSAGE: "sms/send_bulk",
    CAMPAIGN_SUMMARY: "campaigns/summary",
    CHECK_AGENTS_CAMPAIGN_SUMMARY: "campaigns/next",
    ASSIGN_CAMPAIGN_TO_AGENT: "campaigns/assign",
    GET_AVAILABLE_CLIENTS_FROM_ACTIVE_CAMPAIGNS: "campaigns/avl-clients",
    GET_GRAPH_SUMMARY: "agents/graph-summary",
    GET_CAMPAIGN_STATISTICS: "campaigns/campaign-summary-stats",
};

const AUTH = {
    GET_USER_FROM_TOKEN: "users/get_user",
    REGISTER: "register",
    LOGIN: "login",
    LOGOUT: "logout",
    RESET_PASS_INIT: "forgot-password",
    RESET_PASS: "reset-password",
};

const CALL = {};

const CASE = {
    FEES: "debtor_cases/case/fees",
    NOTES: "debtor_cases/case/fees/CRUD",
    PTPS: "debtor_cases/case/fees/CRUD",
    SUMMARY: "debtor_cases/case/fees/debtor_summary",
    CALLS: "debtor_cases/case/calls",
    MESSAGES: "debtor_cases/case/messages",
    EMAILS: "debtor_cases/case/emails",
    EDIT_DEBTOR: "debtor_cases/case/fees/details/edit",
};

const DEBTOR_CASE_PTPS = {
    LIST: "debtor_cases/case/ptps",
    CREATE: "debtor_cases/case/ptps/create",
    MANGER_OVERRIDES_LIST: "debtor_cases/case/ptps/override/index",
    AGENT_OVERRIDES_STATUS_LIST: "debtor_cases/case/ptps/override/agent_status_list",
    CREATE_OVERRIDE: "debtor_cases/case/ptps/override/create",
    UPDATE_OVERRIDE: "debtor_cases/case/ptps/override/update",
    CHECK_OVERRIDES: "debtor_cases/case/ptps/override/available",
};

const CLIENT = {
    CREATE: "clients/create",
    UPDATE: "clients/update",
    CLIENT_REGISTER: "clients/client_register",
    DEBTOR_REGISTER: "clients/debtor_register",
    DELETE: "clients/delete",
    GET_CLIENT: "clients/get",
};

const CLIENT_FEE = {
    LIST: "clients/fees/crud_list",
    CREATE: "clients/fees/create",
    UPDATE: "clients/fees/update",
    DELETE: "clients/fees/delete",
};

const COMMS = {
    GET_AGENT_EMAILS: "agents/emails",
    AGENT_SEND_EMAIL_TO_DEBTOR: "agents/send_email_to_debtor",
    DEBTOR_SEND_EMAIL_TO_AGENT: "debtors/send_email_to_agent",
    AGENT_SEND_REPLY_EMAIL: "agents/send_reply_email",
    MANAGER_EMAIL_DELETE: "emails/delete",
    MANAGER_EMAIL_SHARE: "emails/share",
    GET_DEBTOR_CASE_EMAILS: "debtor_cases/case/emails",
    GET_POSSIBLE_RECIPIENTS: "emails/recipients",
    GET_DEBTOR_RECIPIENTS: "emails/debtor_recipients",
    GET_MESSAGE_TEMPLATES: "messages/templates",
    FLAG_EMAIL: "emails/flag",
    SET_READ_EMAIL: "emails/read",
    GET_EMAIL_THREAD: "emails/thread",
    AGENT_SEND_BULK_EMAILS_TO_DEBTORS: "agents/send_bulk_emails_to_debtors",
    EMAIL_STATEMENT: "debtors/statement/email",
    DOWNLOAD_STATEMENT: "debtors/statement/download",
};

const EVENT = {
    CREATE: "events/create",
    UPDATE: "events/update",
    DELETE: "events/delete",
    LIST: "events",
};

const TIME_LOG = {
    START_TIME: "",
    STOP_TIME: "",
};

const USER = {
    CREATE_USER: "users/create",
    GET_USER_BY_ID: "users/get_user_admin",
    GET_USER_LIST: "users/user_manager",
    UPDATE_USER: "users/update",
    DELETE_USER: "users/delete",
    NEW_USER_DROPDOWNS: "users/new_user",
    BULK_CREATE_USER_DEBTOR: "users/bulkcreateuserdebtor",
};

const LSSM = {
    SEND_MARITAL_TYPE: "lssm/send_marital_type",
    SEND_SUMMONS_REQUESTED: "lssm/send_summons_requested",
    SEND_WRIT_EXECUTION_REQUEST_DATE: "lssm/send_writ_execution_request_date",
    SEND_DEBTOR_SHORT_TERM_EMPLOYED: "lssm/send_debtor_short_term_employed",
    SEND_WRIT_EXECUTION_ISSUED_DATE: "lssm/send_writ_execution_issued_date",
    SEND_WRIT_EXECUTION_SERVED: "lssm/send_writ_execution_served",
    SEND_SUMMONS_SERVED: "lssm/send_summons_served",
    FRAUD_MATTER: "lssm/fraud_matter",
    ADMIN_SUPPORT: "lssm/admin_support",
    RECALL_CONFIRMATION: "lssm/recall_confirmation",
    RETURN_MATTER_TO_SBSA: "/lssm/return_matter_to_sbsa",
    RECALL_REASONS: "/lssm/recall_reasons",
    ACKNOWLEDGEMNT_OF_MATTER: "lssm/new_matter_acknowledgement",
    ATTACHMENT_INFORMATION: "lssm/get_attachment_information",
    SEND_ATTACHMENT_INFORMATION: "lssm/send_attachment_information",
    SEND_CUSTOMER_INFORMATION: "lssm/send_customer_information",
    SAVE_CUSTOMER_COLLECTION_REPORT: "lssm/save_customer_collection_report",
    GET_CUSTOMER_COLLECTION_REPORT: "lssm/customer_collection_report",
    GET_ACCOUNT_FINANCIAL_TRANSACTIONS: "lssm/account_financial_transactions",
    SEND_SUMMONS_DOCUMENTS_RECEIVED: "lssm/send_summons_documents_received",
    SEND_RECOMMENDED_LEGAL_ACTION: "lssm/send_recommended_legal_action",
    SEND_JUDGEMENT_APPLICATION: "lssm/send_judgement_application",
    SEND_DEBTOR_DECEASED: "lssm/send_debtor_deceased",
    SEND_DEFENDED_MATTER: "lssm/send_defended_matter",
    SEND_DEBTOR_UNEMPLOYED: "lssm/send_debtor_unemployed",
    SEND_DEBTOR_RETRENCHED: "lssm/send_debtor_retrenched",
    SEND_DEBTOR_EMIGRATED: "lssm/send_debtor_emigrated",
    SEND_ADMIN_ORDER_FAXED: "lssm/send_admin_order_faxed",
    SEND_RETURN_OF_SERVICE: "lssm/send_return_of_service",
    SEND_SUMMONS_NOT_SERVED: "lssm/send_summons_not_served",   
    SEND_WRIT_OF_EXECUTION_NON_SERVICE_DATE: "lssm/send_writ_of_execution_non_service_date",
    SEND_JUDGEMENT_GRANTED_DETAIL: "lssm/send_judgement_granted_detail",
    SEND_DEBTOR_ON_TRACE: "lssm/send_debtor_on_trace",   
    SEND_ACCOUNT_SELECTION_CHOICE: "lssm/send_account_selection_choice",   
    SEND_FREE_FORMAT_REVIEW: "lssm/send_free_format_review",
    SEND_REASON_FOR_NON_PAYMENT: "lssm/send_reason_for_non_payment",
};

const DEBTOR_PORTAL = {
    PASSWORD_OTP: "users/password_otp",
    PASSWORD_UPDATE: "users/password_update",
    DEBTOR_CASE_REGISTER: "debtor_cases/case/debtor_case_registry",
};

export {
    AGENT,
    AUTH,
    CALL,
    CASE,
    DEBTOR_CASE_PTPS,
    CLIENT,
    CLIENT_FEE,
    COMMS,
    EVENT,
    TIME_LOG,
    USER,
    LSSM,
    DEBTOR_PORTAL,
};
