import { ClickAwayListener, IconButton } from "@mui/material";
import { Close } from "@mui/icons-material";
import React from "react";
import { connect } from "react-redux";
import { unsetModal } from "../../../redux/actions/navActions";
import styles from "./styles.module.css";

export const ModalContainer = ({ modal, unsetModal }) => {
  const clickAwayHandler = () => {
    if (modal.canClickAway) {
      unsetModal();
    }
  };

  return modal.Component ? (
    <div className={styles.modalContainer}>
      <ClickAwayListener onClickAway={clickAwayHandler}>
        <div
          className={
            modal.size === "full"
              ? styles.modalBodyFull
              : modal.size === "lrg"
              ? styles.modalBodyLrg
              : styles.modalBodySml
          }
          style={{ borderRadius: 20, position: 'relative' }}
        >
          {modal.Component}

          {modal.closeBtn === true ? (
          
          <IconButton
            style={{ position: "absolute", top: 10, right: 10 }}
            onClick={unsetModal}
            size="large">
            <Close />
          </IconButton> ) : null
          }
        </div>
      </ClickAwayListener>
    </div>
  ) : null;
};

const mapStateToProps = (state) => {
  return {
    modal: state.nav.modal,
  };
};

const mapDispatchToProps = {
  unsetModal,
};

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer);
